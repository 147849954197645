import React from "react";
import { Input } from "@nextui-org/react"
import { SearchIcon } from "@assets/TableIcons/SearchIcon";
import DropDownEnrolment from "../DropDownEnrolment";

const HeaderDashboard = ({ enrolmentSelected, onCleanEnrolmentSelected, onSearchInput, onSelectTab }) => {
    return (
        <div className="flex justify-between items-center w-full bg-teal-600 mb-10">
            <div className="w-full pl-4">
                <Input
                    className="w-1/2"
                    color="default"
                    placeholder="Buscar estudiante"
                    variant="flat"
                    startContent={<SearchIcon stroke-width="1.5" stroke="black" />}
                    onChange={({ target }) => {
                        onSearchInput(target.value);
                    }}
                />
            </div>
            <div className="p-5">
                {/* <div className="text-white text-xl font-semibold"> {enrolmentSelected} </div> */}
                <DropDownEnrolment
                    enrolmentName={enrolmentSelected}
                    onCleanEnrolmentSelected={onCleanEnrolmentSelected}
                    onSelectTab={onSelectTab}
                />
            </div>
        </div>
    );
};

export default HeaderDashboard;
