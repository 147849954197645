import styled from "styled-components";
import { devices } from "@utils/devices";

const CenterRecoveryContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only screen and ${devices.md} {
    flex-direction: row;
  }

  background-color: white;
  width: 100%;
  @media only screen and ${devices.md} {
    width: 60%;
  }
  margin: 20px;
  height: 70%;
`;

export default CenterRecoveryContent;
