import axios from "../axios";
import {getEnrolmentsActivesURL} from "./URLs";

const getEnrolmentsActives = async () => {
    try {
        const response = await axios().get(getEnrolmentsActivesURL);
        return response.data || {};
    } catch (error) {
        return error.response;
    }
};

export default getEnrolmentsActives;
