import styled from "styled-components";

const ListQuestionContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 22px;
    & span {
       color: white;
    }
    & span {
       font-size: 14px;
    }
`;

export default ListQuestionContainer;