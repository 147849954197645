import styled from "styled-components";

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default InputsContainer;
