import React, {useState} from "react";
import {Input, Modal, Button, ModalContent, ModalHeader, ModalBody, ModalFooter} from "@nextui-org/react";
import TitleModal from "./styled-components/TitleModal";
import InputFile from "./styled-components/InputFile";
import {enqueueSnackbar} from "notistack";


const NewFileModal = ({visible, onCloseModal, sendFile, isLoading, subjectModule}) => {
    const [titleFile, setTitleFile] = React.useState();
    const [file, setFile] = useState();


    const handleChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        const formData = new FormData();

        if (!!titleFile) {
            formData.append('doc', file);
            sendFile({subjectModule, titleFile, formData});
        } else {
            enqueueSnackbar('Ingresar titulo', {
                variant: "error",
                persist: false,
            });
        }

    };

    return (
        <Modal backdrop="blur" isOpen={visible} onClose={onCloseModal} size="1xl">
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <TitleModal>Agregar Archivo</TitleModal>
                        </ModalHeader>
                        <ModalBody>
                            <Input
                                label="Titulo"
                                type="text"
                                value={titleFile}
                                onChange={({target}) => {
                                    setTitleFile(target.value);
                                }}
                                css={{width: "400px", paddingTop: "0px"}}
                                isDisabled={isLoading}
                            />
                            <InputFile onChange={handleChange} type="file"></InputFile>
                            <Button css={{width: "30px", marginTop: "20px"}}
                                    onClick={handleSubmit} isDisabled={!file} isLoading={isLoading} color="secondary">
                                Subir
                            </Button>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default NewFileModal;
