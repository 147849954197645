import styled from "styled-components";
import {devices} from "@utils/devices";

const TitleModule = styled.div`
  padding: 10px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  max-width: 144px;
  @media only screen and ${devices.md} {
    max-width: 100%;
  }
  margin: 0px 15px;
`;

export default TitleModule;
