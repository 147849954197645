import {CONTENT_TYPES} from "./constants";
import TypePdfIcon from "@assets/type_pdf.png";
import ImageType from "@assets/image-type.png";
import AnyFileType from "@assets/any-file-type.png";


export default function getIconFile(contentType) {
    const imageFile = contentType === CONTENT_TYPES.PNG
        || contentType === CONTENT_TYPES.JPG
        || contentType === CONTENT_TYPES.JPEG
    const pdfType = contentType === CONTENT_TYPES.PDF
    if (pdfType) return TypePdfIcon
    if (imageFile) return ImageType
    if (!pdfType && !imageFile) return AnyFileType
}
