import styled from "styled-components";

const CardStyledQuestion = styled.div`
  display: flex;
  align-content: space-between;
  flex-direction: column;
  width: ${({width}) => (width || '90%')};
  padding: ${({padding}) => (padding || '30px')};
  margin: ${({margin}) => (margin || '20px')};
  border-radius: 15px;
  margin-bottom: 1rem;
  background: linear-gradient(161.91deg, #74c4b891 4.96%, #184b41 83.92%);
  align-items: ${({alignCenter}) => (alignCenter && 'center')};
`;

export default CardStyledQuestion;
