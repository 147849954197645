import styled from "styled-components";

const Title = styled.div`
  font-weight: 600;
  font-size: ${({size}) => (size ? size : "1.5em")}; 
  width: 100%;
  color: ${({secondaryColor}) => (secondaryColor && "black")}; 
`;

export default Title;
