import React, {useEffect, useState} from "react";
import {Button} from "@nextui-org/react";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import EvaluationIcon from '@assets/evaluation-icon.png';
import StartEvaluationContainer from "./styled-components/StartEvaluationContainer";
import HeaderStartEvaluation from "./styled-components/HeaderStartEvaluation";
import MainTitleContent from "./styled-components/MainTitleContent";
import ActionEvaluationStartContainer from "./styled-components/ActionEvaluationStartContainer";
import Title from "@components/Title/Title";
import ListQuestions from "../ListQuestions";
import doGetMakeEvaluation from "@services/evaluations/doGetMakeEvaluation";
import {useMutation} from "react-query";
import {enqueueSnackbar} from "notistack";
import queryString from "query-string";
import ScrollContent from "./styled-components/ScrollContent";
import doSendEvaluationResult from "@services/evaluations/doSendEvaluationResult";
import {EVALUATION_TYPE} from "@utils/constants";
import ROUTES from "@components/Router/routes";
import Icon from "@components/Icon/Icon";
import {Chip} from "@nextui-org/chip";
import SubTitle from "@components/SubTitle/SubTitle";
import useDevice from "@utils/useDevice";
import {DeleteModule} from "../../../Content/components/ItemCollapse/components/DeleteModule/DeleteModule";
import ButtonConfirmation from "../../../../components/ButtonConfirmation";

const StartEvaluation = () => {
    const {isMobile} = useDevice();
    const navigate = useNavigate();
    const location = useLocation();
    const evaluationData = queryString.parse(location.search)
    const {evaluation, e} = evaluationData;

    const [evaluationContent, setEvaluationContent] = useState();

    const {mutate: getEvaluation, isLoading: isLoadingCreateEvaluation} =
        useMutation(doGetMakeEvaluation, {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    setEvaluationContent(data.data);
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
            },
            onError: (error) => {
                console.log('error')
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });

    const {mutate: sendEvaluation, isLoading: loadingSendEvaluation} =
        useMutation(doSendEvaluationResult, {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    enqueueSnackbar('Evaluacion enviada', {
                        variant: "success",
                        persist: false,
                    });
                    navigate({
                        pathname: ROUTES.CONTENT,
                        search: `?e=${e}`,
                    });
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
            },
            onError: (error) => {
                console.log('error')
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });

    const onSendEvaluation = () => {
        const dataAnsers = [];

        evaluationContent?.question?.map((response) => {
            if (response.type === EVALUATION_TYPE.SINGLE_RESPONSE)
                dataAnsers.push({question: response._id, answer: {data: response.response}})
            if (response.type === EVALUATION_TYPE.MULTIPLE_CHOIS)
                dataAnsers.push({question: response._id, answer: {data: response.description.response}})
        });

        if (dataAnsers?.length > 0) {
            sendEvaluation({
                evaluationId: evaluationContent?._id,
                enrolmentId: e,
                answers: dataAnsers
            });
        }
    }

    useEffect(() => {
        getEvaluation(evaluation);
    }, [])

    useEffect(() => {
        console.log('eva', evaluationContent)
    }, [evaluationContent])


    return (
        <StartEvaluationContainer>
            <HeaderStartEvaluation isMobile={isMobile}>
                <MainTitleContent>
                    <div className="flex flex-wrap items-center p-2">
                        <div className="flex items-center">
                            <Icon src={EvaluationIcon} alt='evaluation' width="40px" height="40px"/>
                            <Title> {evaluationContent?.title} </Title>
                        </div>
                        <div className="w-full p-2">
                            <Chip color="warning" variant="flat"
                                  style={{width: "100%", margin: "0px 30px"}}>
                                Total: {evaluationContent?.score} puntos.
                            </Chip>
                        </div>
                    </div>
                    <div className="p-2">
                        <SubTitle> {evaluationContent?.description} </SubTitle>
                    </div>
                </MainTitleContent>
                <ActionEvaluationStartContainer>
                    <div className="px-2">
                        <Button
                            onClick={() => navigate({
                                pathname: ROUTES.CONTENT,
                                search: `?e=${e}`,
                            })}
                        >
                            Cancelar
                        </Button>
                    </div>

                    <ButtonConfirmation
                        actionLoading={loadingSendEvaluation}
                        textButton="Finalizar y Enviar"
                        colorButton="secondary"
                        contentPopover={
                            <Button color="success"
                                    onClick={onSendEvaluation}>
                                Si, enviar
                            </Button>
                        }
                    />
                </ActionEvaluationStartContainer>
            </HeaderStartEvaluation>
            <ScrollContent>
                {
                    evaluationContent?.question?.map((question, index) => (
                        <ListQuestions
                            id={question?._id}
                            question={question}
                            evaluationContent={evaluationContent}
                            setEvaluationContent={setEvaluationContent}
                            count={index + 1}
                        >
                        </ListQuestions>
                    ))
                }
            </ScrollContent>
        </StartEvaluationContainer>
    );
};

export default StartEvaluation;
