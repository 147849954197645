import styled from "styled-components";

const EvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 70%;
`;

export default EvaluationContainer;
