import styled from "styled-components";
import { devices } from "@utils/devices";

const DescriptionContent = styled.div`
  font-weight: 600;
  font-size: 0.8em;
  @media only screen and ${devices.md} {
    font-size: 1.3em;
  }
  line-height: 28px;
  padding: 20px 0px;
  width: 100%;
`;

export default DescriptionContent;
