import ROUTES from "@components/Router/routes";
import UsersIcon from "@assets/navIcons/users.svg";
import HomeIcone from "@assets/navIcons/home.svg";
import CoursesIcon from "@assets/navIcons/course.svg";
import GestionSubject from "@assets/navIcons/gestion-subject.svg";
import MyAccount from "@assets/navIcons/profile.svg";
import Payment from "@assets/navIcons/payment.svg";
import CouponIcon from "@assets/navIcons/coupon.svg";
import IconCertificate from "@assets/navIcons/certificate.png";

const getPrivateLinks = ({
  userCanUpdateUsers,
  userIsProfessor,
  isUserLogged,
}) => {
  const links = [];
  // const items = [];

  const generalLinks = [
    {
      label: "Mis materias",
      path: ROUTES.HOME,
      image: HomeIcone,
    },
    {
      label: "Cursos disponibles",
      path: ROUTES.COURSES,
      image: CoursesIcon,
    },
  ];

  generalLinks.forEach((link) => {
    links.push(link);
  });

  if (userCanUpdateUsers) {
    links.push({
      label: "Usuarios",
      path: ROUTES.USERS,
      image: UsersIcon,
    });
    links.push({
      label: "Gestion Cupones",
      path: ROUTES.GESTION_COUPONS,
      image: CouponIcon,
    });
  }

  if (userCanUpdateUsers || userIsProfessor) {
    links.push({
      label: "Gestion Materias",
      path: ROUTES.GESTION_PROFESSOR,
      image: GestionSubject,
    });
  }

  if (isUserLogged) {
    links.push({
      label: "Cuotas",
      path: ROUTES.PAYMENT_PLAN,
      image: Payment,
    });
    links.push({
      label: "Perfil",
      path: ROUTES.PROFILE,
      image: MyAccount,
    });
    links.push({
      label: "Escolaridad",
      path: ROUTES.SCHOLARSHIP,
      image: IconCertificate,
    });
  }

  return links;
};

export default getPrivateLinks;
