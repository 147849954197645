import axios from "../axios";
import { sendInscriptionFreeURL } from "./URLs";

const doInscriptionFree = async ({ idCourse, cuponCode }) => {
    try {
        const response = await axios().post(sendInscriptionFreeURL, {
            course: idCourse
        });
        return response || {};
    } catch (error) {
        return error.response;
    }
};

export default doInscriptionFree;
