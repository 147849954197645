import styled from "styled-components";

const ButtonEditContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({noContent}) => (noContent ? '40px' : '0px')}; 
  
`;

export default ButtonEditContainer;
