import styled from "styled-components";

const ButtonsViewContainer = styled.div`
  width: ${({isMobile}) => (isMobile ? "100%" : "50%")};
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export default ButtonsViewContainer;
