import styled from "styled-components";

const FooterNav = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${({ isOpen }) => (isOpen ? "50px" : "48px")};
  align-items: center;
  padding: ${({ isOpen }) => (isOpen ? "0px 12px" : "0px 11px")};
  background: rgba(255, 255, 255, 0.22);
  border-radius: 41px;
  margin: ${({ isOpen }) => (isOpen ? "10px" : "14px")};
  cursor: pointer;
  overflow: hidden;
`;

export default FooterNav;
