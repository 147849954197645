import React, { useEffect, useState } from "react";
import ROUTES from "@components/Router/routes";
import { useNavigate } from "react-router-dom";

const Alert = ({ paymentStatus }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    setDescription(
      <div class="ms-3 text-sm font-medium">
        Consulta <a onClick={() => navigate(ROUTES.PAYMENT_PLAN)} class="font-semibold underline hover:cursor-pointer">aquí</a> tu plan de pagos para ponerte al día.
      </div>
    )
    if (paymentStatus.status === 'warning') {
      setTitle('Cuota impaga!')
    }
    if (paymentStatus.status === 'danger') {
      setTitle('Cuotas impagas!')
    }
  }, [paymentStatus]);

  const renderAlert = () => {
    return (
      <div class="flex items-center p-4 mb-4 ml-4 mt-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
        <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <div className="flex items-center content-center">
          <span className="font-semibold ml-1">{title}</span> {description}
        </div>
      </div>
    )
  }

  const renderDanger = () => {
    return (
      <div id="alert-2" class="flex items-center p-4 mb-4 ml-4 mt-4 text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
        <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <div className="flex items-center content-center">
          <span className="font-semibold ml-1">{title}</span> {description}
        </div>
      </div>
    )
  }

  if (paymentStatus.status === 'done') return

  return (
    <div>
      {paymentStatus.status === 'danger' && renderDanger()}
      {paymentStatus.status === 'warning' && renderAlert()}
    </div>
  )

};

export default Alert;
