import React from "react";

const CardDataStats = ({
  title,
  total,
  rate,
  levelUp,
  levelDown,
  icon,
}) => {
  return (
    <div className="rounded-sm border border-stroke bg-white px-7 py-6 shadow-default text-black w-60">
      <div className="flex items-center">
        <img src={icon} alt="users" />
      </div>


      <div className="mt-4 flex items-end justify-between">
        <div>
          <h4 className="text-title-md font-bold text-black">
            {total}
          </h4>
          <span className="text-sm font-medium text-gray-500">{title}</span>
        </div>
      </div>
    </div>
  );
};

export default CardDataStats;
