import styled from "styled-components";

const SectionContent = styled.div`
  margin: 25px;
  font-weight: 600;
  font-size: 1.3em;
  line-height: 10px;
  width: 80%;
`;

export default SectionContent;
