import React from "react";
import {Progress, Tooltip} from "@nextui-org/react";
import QuestionIcon from '@assets/question.png'
import HeaderContainer from "./styled-components/HeaderContainer";
import SectionContent from "./styled-components/SectionContent";
import HeaderTitleContent from "./styled-components/HeaderTitleContent";
import DescriptionContent from "./styled-components/DescriptionContent";
import CardStyledBody from "./styled-components/CardStyledBody";
import ProgressTitleContainer from "./styled-components/ProgressTitleContainer";
import QuestionTooltip from "./styled-components/QuestionTooltip";

const HeaderContent = ({data}) => {
    return (
        <HeaderContainer>
            <CardStyledBody>
                <SectionContent>
                    <HeaderTitleContent> Materia:</HeaderTitleContent>
                    <DescriptionContent>{data?.title}</DescriptionContent>
                </SectionContent>
                <SectionContent>
                    <HeaderTitleContent> Profesor:</HeaderTitleContent>
                    <DescriptionContent>{data?.professor}</DescriptionContent>
                </SectionContent>
                <SectionContent>
                    <ProgressTitleContainer>
                        <HeaderTitleContent> Progreso:</HeaderTitleContent>
                        <Tooltip
                            content={"Se mide en base a las clases vistas."}
                            trigger="click"
                            color="success"
                        >

                            <QuestionTooltip src={QuestionIcon} alt='question'/>
                        </Tooltip>
                    </ProgressTitleContainer>
                    <DescriptionContent>{data?.progress}%</DescriptionContent>
                    <Progress
                        size="sm"
                        value={data?.progress}
                        striped
                        color="success"
                        max={100}
                    />
                </SectionContent>
                <SectionContent>
                    <HeaderTitleContent> Calificación:</HeaderTitleContent>
                    <DescriptionContent>{`${data?.total}/100` || '-'}</DescriptionContent>
                    {data?.total > 0 && <Progress size="sm" value={data?.total} color="success" max={100}/>}
                </SectionContent>
            </CardStyledBody>
        </HeaderContainer>
    );
};

export default HeaderContent;
