import styled from "styled-components";
import {devices} from "@utils/devices";
import {CardBody} from "@nextui-org/react";

const CardStyledBody = styled(CardBody)`
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  @media only screen and ${devices.md} {
    flex-direction: row;
  }
`;

export default CardStyledBody;
