import axios from "../axios";
import { editCouponURL } from "./URLs";

const editCoupon = async (updatedCoupon) => {
  try {
    const response = await axios().put(editCouponURL, updatedCoupon);
    return response.data || {};
  } catch (error) {
    return error.response;
  }
};

export default editCoupon;
