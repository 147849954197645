import styled from "styled-components";

const HomeContainer = styled.div`
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  width: 100%;
  background-color: #202830;
`;

export default HomeContainer;
