const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;

export const createEvaluationURL = `${BASE_URL}/evaluations`;
export const deleteEvaluationURL = `/evaluations/{0}`;
export const getMakeEvaluationURL = `/evaluations/content/{0}`;
export const sendEvaluationResultURL = `${BASE_URL}/evaluations/submit`;
export const getResultsByResultURL = `/evaluations/results?result={0}`;
export const deleteResultByResultURL = `/evaluations/results/{0}`;
export const getResultsByEnrolmentURL = `/evaluations/results?enrolment={0}`;
export const sendCalificationURL = `${BASE_URL}/evaluations/qualify-result`;
