import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NavContainer from "./styled-components/NavContainer";
import FooterNav from "./styled-components/FooterNav";
import UserName from "./styled-components/UserName";
import UserEmail from "./styled-components/UserEmail";
import Logo from "@assets/logo-berea.png";
import IconClose from "@assets/close-icon.svg";
import HeaderFooter from "./styled-components/HeaderFooter";
import LogoIcon from "./styled-components/LogoIcon";
import NamePlatformStyled from "./styled-components/NamePlatformStyled";
import NavigationItems from "./components/NavigationItems";
import ItemsContainer from "./styled-components/ItemsContainer";
import LogoutIcon from "@assets/logout-icon.svg";
import DefaultProfile from "@assets/default-profile.png";
import CloseIcon from "./styled-components/CloseIcon";
import { useNavigate } from "react-router-dom";
import LogoBrandContainer from "./styled-components/LogoBrandContainer";
import { useUser } from "@context/UserProvider";
import ROUTES from "../Router/routes";
import UserProfileContainer from "./styled-components/UserProfileContainer";
import useRole from "@hooks/useRole";
import getPrivateLinks from "./privateLinks";
import MobileNavBarV3 from "../MobileNavBarV3/MobileNavBarV3";

function NavBar({ userLogged, isMobile, pathActive, setPathActive }) {
  const navigateTo = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [menu, setMenu] = useState();

  const { userCanUpdateUsers, userIsProfessor } = useRole();

  useEffect(() => {
    const links = getPrivateLinks({
      userCanUpdateUsers,
      userIsProfessor,
      isUserLogged: userLogged,
    });
    setMenu(links);
  }, [userCanUpdateUsers]);

  const handleNavigation = (navigation) => {
    setPathActive(navigation);
    navigateTo(navigation);
  };

  const { doLogOut } = useUser();

  return (
    <>
      {isMobile && (
        <MobileNavBarV3
          menu={menu}
          onClickItem={handleNavigation}
        ></MobileNavBarV3>
      )}
      {!isMobile && (
        <NavContainer isOpen={isOpen} onMouseOver={() => setIsOpen(true)}>
          <div>
            <HeaderFooter>
              <LogoBrandContainer>
                <LogoIcon src={Logo} onClick={() => setIsOpen(true)} />
                {isOpen && <NamePlatformStyled>BEREA</NamePlatformStyled>}
              </LogoBrandContainer>
              <div>
                {isOpen && (
                  <CloseIcon
                    src={IconClose}
                    onClick={() => setIsOpen(!isOpen)}
                  />
                )}
              </div>
            </HeaderFooter>
            <ItemsContainer>
              <NavigationItems
                onClickItem={handleNavigation}
                navIsOpen={isOpen}
                menu={menu}
                pathActive={pathActive}
              />
            </ItemsContainer>
          </div>

          <FooterNav isOpen={isOpen}>
            {isOpen && (
              <div
                className="flex items-center w-3/4"
                onClick={() => handleNavigation(ROUTES.PROFILE)}
              >
                <LogoIcon src={userLogged?.profile || DefaultProfile} />
                <UserProfileContainer style={{ overflow: "hidden" }}>
                  <UserName>{userLogged?.name}</UserName>
                  <UserEmail>{userLogged?.email}</UserEmail>
                </UserProfileContainer>
              </div>
            )}
            <LogoIcon src={LogoutIcon} onClick={doLogOut} />
          </FooterNav>
        </NavContainer>
      )}
    </>
  );
}

NavBar.propTypes = {
  navigation: PropTypes.string,
};

export default NavBar;
