import React from "react";

const columns = [
    {name: "ID", uid: "id", sortable: true},
    {name: "NOMBRE", uid: "name", sortable: true},
    {name: "EDAD", uid: "age", sortable: true},
    {name: "ROL", uid: "role", sortable: true},
    {name: "EMAIL", uid: "email"},
    {name: "ULTIMO INGRESO", uid: "lastLogin", sortable: true},
    {name: "CIUDAD", uid: "locality", sortable: true},
    {name: "DEPARTAMENTO", uid: "department", sortable: true},
    {name: "TELEFONO", uid: "phone", sortable: true},
    {name: "IGLESIA", uid: "church", sortable: true},
    {name: "AUTH", uid: "authProvider", sortable: true},
    {name: "ACCIONES", uid: "actions"},
];

const statusOptions = [
    {name: "Active", uid: "active"},
    {name: "Paused", uid: "paused"},
    {name: "Vacation", uid: "vacation"},
];
export {columns, statusOptions};
