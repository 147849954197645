import React, { useEffect, useState } from "react";
import { Button, Chip, Switch } from "@nextui-org/react";
import Bg from "@assets/subject-bg02.png";
import arrowContent from "@assets/icons/arrowContent.svg";
import Title from "@components/Title/Title";
import SubTitleCard from "./styled-components/SubtitleCard";
import Alert from "@components/Alert";
import useRole from "@hooks/useRole";
import updateSubject from "@services/subjects/updateSubject";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";

const NewCardsEnrollments = ({ activeSubjects, goToContent }) => {
  const [arraySortAsc, setArraySortAsc] = useState([])
  const { userIsAdmin } = useRole();

  useEffect(() => {
    if (activeSubjects) {
      const sortedSubjects = activeSubjects.sort((a, b) => {
        const dateA = new Date(a.dateStart);
        const dateB = new Date(b.dateStart);
        return dateA - dateB;
      });
      setArraySortAsc(sortedSubjects);
    }
  }, [activeSubjects]);

  const { mutate: fetchUpdateSubject, isLoading: isLoadingSubject } =
    useMutation(updateSubject, {
      onSuccess: (data) => {
        if (data.status !== 200) {
          enqueueSnackbar(data.data.message, {
            variant: "error",
            persist: false,
          });
        }
        else {
          updateArrayList(data.data.data.subjectId, data.data.data.visible)
          enqueueSnackbar("Materia actualizada", {
            variant: "success",
            persist: false,
          });
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    });

  if (!activeSubjects) return

  const contentBlocked = activeSubjects[0]?.paymentStatus?.status === 'danger';

  const onVisibleChange = (target, subjectId) => {
    fetchUpdateSubject({ subjectId: subjectId, newStatus: target.checked })
  }

  const updateArrayList = (id, newValue) => {
    setArraySortAsc(prevArray => {
      return prevArray.map(item => {
        if (item.id === id) {
          return { ...item, visible: newValue };
        } else {
          return item;
        }
      });
    });
  };

  return (
    <>
      {
        <Alert paymentStatus={activeSubjects[0]?.paymentStatus} />
      }
      {arraySortAsc?.map((subject, index) => (
        <div className="bg-sky-800 w-full md:w-3/4 md:p-8 p-4 rounded-2xl flex justify-between md:m-5 my-5">
          <div className="flex w-full">
            <div className="w-1/4 hidden md:flex">
              <img width={500} src={Bg} />
            </div>
            <div className="px-3 py-2">
              {/*<div>TITULO</div>*/}
              <Title size="1.5em">{subject?.name}</Title>
              <SubTitleCard>Profesor: {subject?.professor}</SubTitleCard>
            </div>
          </div>

          <div className="flex flex-wrap content-between h-full">
            <div>
              <div className="w-full">
                {!subject.visible && !userIsAdmin && (
                  <div>
                    <SubTitleCard>Comienzo tentativo:</SubTitleCard>
                    <SubTitleCard primaryColor>
                      {new Date(subject.dateStart).toLocaleDateString()}
                    </SubTitleCard>
                  </div>
                )}
              </div>

              {(userIsAdmin || (subject.visible && subject.videosQuantity)) && (
                <div className="flex flex-col text-white">
                  <Chip variant="shadow" color="secondary" className="mb-2">
                    Total clases: {subject.videosQuantity}
                  </Chip>
                  {userIsAdmin && (
                    <Switch isDisabled={isLoadingSubject} isSelected={subject.visible} color="success" onChange={({ target }) => onVisibleChange(target, subject.id)}>
                      <span className="text-white">Visible</span>
                    </Switch>
                  )}
                </div>
              )}
            </div>
            {(userIsAdmin || subject.visible) && (
              <Button
                color="default"
                endContent={<img width={20} src={arrowContent} />}
                isDisabled={contentBlocked}
                onClick={() => goToContent(subject)}
              >
                {contentBlocked ? 'Contenido bloqueado' : 'Ver contenido'}
              </Button>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default NewCardsEnrollments;
