import axios from "../axios";
import {getCouponInfoURL} from "./URLs";

const getCouponInfo = async ({couponId, course}) => {
    try {
        const response = await axios().post(getCouponInfoURL, {code: couponId, course: course});
        return response || {};
    } catch (error) {
        return error.response;
    }
};

export default getCouponInfo;
