import {createGlobalStyle} from "styled-components";

// language=LESS
const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0 !important;
    margin: 0 !important;

    & input {
      color: black !important;
    }

    & textarea {
      color: black !important;
    }

    & a,
    a:hover {
      text-decoration: none;
    }

    background-color: rgba(29, 29, 29, 1);
    color: white;
    font-family: Inter;
    font-style: normal;

    /* custom scrollbar */

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(176, 176, 176);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgb(176, 176, 176);
    }
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
`;

export default GlobalStyle;
