import axios from "../axios";
import {updateUserURL} from "./URLs";

const updateUser = async (user) => {
    try {
        const response = await axios().put(updateUserURL, user);
        return response.data || {};
    } catch (error) {
        return error.response;
    }
};

export default updateUser;
