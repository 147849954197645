import styled from "styled-components";

const ContainerCard = styled.div`
  display: flex;
  background-color: ${({ secondaryColor }) => secondaryColor ? '#0f4b55' : '#14314a'};
  border-radius: 5px;
  box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
  color: #B3B8CD;
  margin: 20px;
  position: relative;
  width: 350px;
  min-height: 600px;
  max-width: 100%;
  text-align: center;
`;

export default ContainerCard;
