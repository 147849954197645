import {deleteEvaluationURL, deleteFileURL} from "./URLs";
import format from "../format";
import axios from "../axios";

const doDeleteEvaluation = async ({evaluation}) => {
    try {
        const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;
        const URL = format(BASE_URL + deleteEvaluationURL, evaluation);
        const response = await axios().delete(URL);
        return response.data || {};
    } catch (error) {
        return error.response;
    }
};

export default doDeleteEvaluation;
