import React from "react";
import doDeleteFile from "@services/uploads/doDeleteFile";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { Spinner } from "@nextui-org/react";
import DeleteLink from "../../styled-components/DeleteLink";

export const DeleteFile = ({ file, module, onUpdateModule }) => {
  const onDeleteFile = (file) => {
    deleteFile({ item: file._id, subjectModule: module.id, key: file.fileKey });
  };

  const { mutate: deleteFile, isLoading: isDeletingFile } = useMutation(
    doDeleteFile,
    {
      onSuccess: ({ data }) => {
        if (data) {
          const moduleUpdated = {
            ...module,
            files: module.files.filter((item) => item._id !== data.id),
          };
          onUpdateModule(moduleUpdated);
          enqueueSnackbar("Archivo borrado", {
            variant: "success",
            persist: false,
          });
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );

  return (
    <>
      <DeleteLink color="error" onClick={() => onDeleteFile(file)}>
        {isDeletingFile ? (
          <Spinner type="points-opacity" color="currentColor" size="sm" />
        ) : (
          "Eliminar"
        )}
      </DeleteLink>
    </>
  );
};
