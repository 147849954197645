import axios from "../axios";
import { doLoginURL } from "./URLs";

const doLogin = async ({ password, email }) => {
  try {
    const response = await axios().post(doLoginURL, {
      username: email,
      password,
    });
    return response.data || {};
  } catch (error) {
    return error.response;
  }
};

export default doLogin;
