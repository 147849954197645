import React from "react";
import { Button } from "@nextui-org/react";
import { Link, Input } from "@nextui-org/react";
import { VIEWS_LOGIN } from "@utils/constants";
import ButtonLoginContainer from "../../styled-components/ButtonLoginContainer";
import { EyeSlashFilledIcon } from "@assets/EyeSlashFilledIcon/EyeSlashFilledIcon";
import { EyeFilledIcon } from "@assets/EyeFilledIcon/EyeFilledIcon";

function ViewLogin({ email, password, setStateUser, setModeView, loading }) {
  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);
  return (
    <div className="flex flex-col items-center pt-8">
      <div className="p-2 w-full">
        <Input
          label="Email"
          type="text"
          onChange={({ target }) => {
            setStateUser({ password, email: target.value });
          }}
          css={{ width: "250px" }}
        />
      </div>
      <div className="p-2 w-full">
        <Input
          label="Contraseña"
          type={isVisible ? "text" : "password"}
          onChange={({ target }) => {
            setStateUser({ email, password: target.value });
          }}
          css={{ width: "250px" }}
          endContent={
            <button
              className="focus:outline-none"
              type="button"
              onClick={toggleVisibility}
            >
              {isVisible ? (
                <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
              ) : (
                <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
              )}
            </button>
          }
        />
      </div>
      <Link
        style={{ fontSize: "12px" }}
        block
        color="primary"
        href="#"
        onClick={() => setModeView(VIEWS_LOGIN.RENEW_PASSWORD)}
      >
        Olvide mi contraseña
      </Link>
      <ButtonLoginContainer>
        <Button type="submit" id="login" isDisabled={loading} isLoading={loading}>
          Ingresar
        </Button>
      </ButtonLoginContainer>
    </div>
  );
}

export default ViewLogin;
