import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import { Input } from "@nextui-org/react";

const EditModal = ({
  visible,
  closeHandler,
  loading,
  couponToEdit,
  onUpdateConfirm,
}) => {
  const [discountType, setDiscountType] = useState("");
  const [discount, setDiscount] = useState(0);
  const [code, setCode] = useState("");

  useEffect(() => {
    if (couponToEdit) {
      setDiscountType(couponToEdit.discountType);
      setDiscount(couponToEdit.discount);
      setCode(couponToEdit.code);
    }
  }, [couponToEdit]);

  const handleUpdateCoupon = () => {
    const updatedCoupon = {
      _id: couponToEdit.id,
      discountType: discountType,
      discount: discount,
      code: code,
    };
    onUpdateConfirm(updatedCoupon);
  };

  return (
    <Modal backdrop="blur" isOpen={visible} onClose={closeHandler}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader
              className="flex flex-col gap-1"
              style={{ color: "black" }}
            >
              Editar cupón
            </ModalHeader>
            <ModalBody>
              <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                <Input
                  label="Código"
                  placeholder="Ingrese el código de descuento"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <Input
                label="Descuento"
                placeholder="Ingrese el descuento"
                value={discount}
                onChange={(e) => setDiscount(parseFloat(e.target.value))}
                endContent={
                  <div className="flex items-center">
                    <label className="sr-only" htmlFor="currency">
                      Currency
                    </label>
                    <select
                      className="outline-none border-0 bg-transparent text-default-400 text-medium"
                      value={discountType}
                      onChange={(e) => setDiscountType(e.target.value)}
                    >
                      <option value="PCT">% Porcentaje</option>
                      <option value="Fixed">$ Monto Fijo</option>
                    </select>
                  </div>
                }
                type="number"
              />
            </ModalBody>
            <ModalFooter>
              <Button auto flat onClick={closeHandler}>
                Cancelar
              </Button>
              <Button
                isDisabled={loading}
                color="primary"
                auto
                onClick={handleUpdateCoupon}
                isLoading={loading}
              >
                Guardar Cambios
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
