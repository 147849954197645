import React from "react";
import styled from "styled-components";
import { Chip } from "@nextui-org/react";
import Info from "@assets/icons/info.svg";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const TitleNoResults = styled.div`
  font-weight: 600;
  font-size: 1.3em;
  width: 100%;
  padding: 40px 0px;
  text-align: center;
`;

const NoResults = ({ message, icon, description }) => (
  <Container>
    <img width="70px" src={icon} alt="no-content" />
    <TitleNoResults>{message}</TitleNoResults>
    {description && (
      <div className="flex items-center w-full justify-center">
        <Chip
          style={{ color: "white", padding: 0 }}
          startContent={<img width={15} src={Info} />}
          variant="light"
        >
          {description}
        </Chip>
      </div>
    )}
  </Container>
);

export default NoResults;
