import axios from "../axios";
import {updateProgressURL} from "./URLs";

const updateProgress = async ({userId, idVideo, enrolmentId}) => {
    try {
        const response = await axios().post(updateProgressURL, {
            idStudent: userId,
            idEnrolment: enrolmentId,
            idVideo: idVideo,
        });
        return response.data || {};
    } catch {
        return {};
    }
};

export default updateProgress;
