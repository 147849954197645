import styled from "styled-components";
import {devices} from "../../../../../utils/devices";

const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({detailsIsOpen}) =>
    detailsIsOpen ? "20px 20px 0px 20px;" : "20px"};
  @media only screen and ${devices.md} {
    flex-wrap: wrap;
  }
`;

export default CardBody;
