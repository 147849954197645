import {ROLES} from "@utils/constants";
import {useUser} from "@context/UserProvider";

function useRole() {
    const {user} = useUser();

    return {
        userCanUpdateUsers: user?.role === ROLES.ADMIN,
        userIsAdmin: user?.role === ROLES.ADMIN,
        userCanUpdateContent: user?.role === ROLES.ADMIN || user?.role === ROLES.PROFESSOR,
        userIsProfessor: user?.role === ROLES.ADMIN || user?.role === ROLES.PROFESSOR,
    };
}

export default useRole;
