import React from "react";
import RecoveryContainer from "./styled-components/RecoveryContainer";
import CenterRecoveryContent from "./styled-components/CenterRecoveryContent";
import TitleLogin from "./styled-components/TitleLogin";
import FormSection from "./styled-components/FormSection";
import Logo from "@assets/logo-berea.png";
import LeftRecoveryContent from "./styled-components/LeftRecoveryContent";
import SubtitleLogin from "./styled-components/SubtitleLogin";
import ROUTES from "@components/Router/routes";
import { Button } from "@nextui-org/react";
import LogoIcon from "./styled-components/LogoIcon";
import { useNavigate } from "react-router-dom";

function TokenExpired() {
  const navigate = useNavigate();

  return (
    <RecoveryContainer>
      <CenterRecoveryContent>
        <LeftRecoveryContent>
          <LogoIcon src={Logo} />
          <TitleLogin>BEREA</TitleLogin>
          <SubtitleLogin>Instituto Bíblico Online</SubtitleLogin>
        </LeftRecoveryContent>
        <FormSection>
          <div className="text-red-700 text-lg text-center pb-6">El link ya no es válido, vuelva a generarlo.</div>
          <Button
            color="default"
            onClick={() => navigate(ROUTES.LOGIN)}
          >
            Entiendo
          </Button>
        </FormSection>
      </CenterRecoveryContent>
    </RecoveryContainer>
  );
}

export default TokenExpired;
