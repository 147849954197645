import styled from "styled-components";
import {devices} from "@utils/devices";

const CertificateContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  background: linear-gradient(161.91deg, #1c1f30 4.96%, #242d2b 83.92%);
  padding: 20px;
  @media only screen and ${devices.md} {
    width: 700px;
    height: 500px;
  }
`;

export default CertificateContainer;
