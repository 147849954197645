import React, { useState } from "react";
import { Button, Chip, Link } from "@nextui-org/react";
import CardContainer from "./styled-components/CardContainer";
import DownArrow from "@assets/arrow-down.png";
import UpArrow from "@assets/arrow-up.png";
import VideoIcon from "@assets/video-chat.png";
import FileIcon from "@assets/file.png";
import EvaluationIcon from "@assets/examen.png";
import ModuleIcon from "@assets/icons/module-icon.svg";
import PresentationIcon from "@assets/icons/ppt-icon.png";
import LiveIcon from "@assets/icons/live-icon.svg";
import PlayIcon from "@assets/icons/play.png";
import CardBody from "./styled-components/CardBody";
import ActionsContainer from "./styled-components/ActionsContainer";
import { animation } from "@utils/animations";
import Arrow from "./styled-components/Arrow";
import TitleModule from "./styled-components/TitleModule";
import { motion } from "framer-motion";
import DetailItem from "./styled-components/DetailItem";
import DetailItemContainer from "./styled-components/DetailItemContainer";
import ActionDetailButton from "./styled-components/ActionDetailButton";
import Icon from "@components/Icon/Icon";
import TextStyled from "./styled-components/TextStyled";
import LeftContentModule from "./styled-components/LeftContentModule";
import { DeleteModule } from "./components/DeleteModule/DeleteModule";
import MenuAddModuleContainer from "./styled-components/MenuAddModuleContainer";
import NewVideoModal from "../NewVideoModal";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import doCreateVideo from "@services/uploads/doCreateVideo";
import NewFileModal from "../NewFileModal";
import doCreateFile from "@services/uploads/doCreateFile";
import { DeleteFile } from "./components/DeleteFile/DeleteFile";
import ArrowContainer from "./styled-components/ArrowContainer";
import deleteModule from "@services/subjects/deleteModule";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@assets/check-icon.png";
import ROUTES from "@components/Router/routes";
import { DeleteEvaluation } from "./components/DeleteEvaluation/DeleteEvaluation";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/dropdown";
import isEmptyObject from "@helpers/isEmptyObject";
import getIconFile from "@utils/getTypeFile";
import ButtonConfirmation from "../../../../components/ButtonConfirmation";
import NewPresentationModal from "../NewPresentationModal";
import doCreatePresentation from "@services/uploads/doCreatePresentation";
import { DeletePresentation } from "./components/DeletePresentation/DeletePresentation";
import { DeleteVideo } from "./components/DeleteVideo/DeleteVideo";
import LiveLink from "./components/LiveLink";
import NewLiveEventModal from "../NewLiveEventModal";
import doCreateLiveEvent from "@services/uploads/doCreateLiveEvent";

const ItemCollapse = ({
  item,
  onShowVideo,
  modeEdit,
  onUpdateModule,
  subjectId,
  onUpdateModuleDeleted,
  enrolment,
  isOpenModal,
}) => {
  const navigate = useNavigate();
  const hasPresentations = item?.presentations?.length > 0;
  const hasFile = item?.files?.length > 0;
  const hasEvaluation = item?.evaluations?.length > 0;
  const hasVideos = item?.videos?.length > 0;
  const hasViewVideos = item?.videos?.filter(v => v.see)
  const hasLiveEvent = !!item?.liveEvent;
  const [showDetail, setShowDetail] = useState((hasViewVideos.length !== item?.videos?.length) || (hasPresentations || hasFile || hasEvaluation));
  const [viewNewVideo, setViewNewVideo] = useState(false);
  const [viewNewLiveEvent, setViewNewLiveEvent] = useState(false);
  const [viewNewFile, setViewNewFile] = useState(false);
  const [viewNewPresentation, setViewNewPresentation] = useState(false);

  const closeNewVideoModal = () => {
    setViewNewVideo(false);
  };

  const closeNewLiveModal = () => {
    setViewNewLiveEvent(false);
  };

  const closeNewFileModal = () => {
    setViewNewFile(false);
  };

  const closeNewPresentationModal = () => {
    setViewNewPresentation(false);
  };

  const { mutate: onNewVideo, isLoading: isLoadingNewVideo } = useMutation(
    doCreateVideo,
    {
      onSuccess: ({ data }) => {
        if (data) {
          enqueueSnackbar("Video agregado", {
            variant: "success",
            persist: false,
          });
          onUpdateModule(data.module);
          closeNewVideoModal();
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );

  const { mutate: onNewLiveEvent, isLoading: isLoadingNewLiveEvent } = useMutation(
    doCreateLiveEvent,
    {
      onSuccess: ({ data }) => {
        if (data) {
          enqueueSnackbar("Evento agregado", {
            variant: "success",
            persist: false,
          });
          onUpdateModule(data.module);
          closeNewLiveModal();
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );

  const { mutate: onNewPresentation, isLoading: isLoadingNewPresentation } =
    useMutation(doCreatePresentation, {
      onSuccess: ({ data }) => {
        if (data) {
          enqueueSnackbar("PPT agregada", {
            variant: "success",
            persist: false,
          });
          onUpdateModule(data.module);
          closeNewPresentationModal();
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    });

  const { mutate: onNewfile, isLoading: isLoadingNewFile } = useMutation(
    doCreateFile,
    {
      onSuccess: (data) => {
        if (data?.status !== 200) {
          enqueueSnackbar(data?.data?.message, {
            variant: "error",
            persist: false,
          });
          closeNewFileModal();
        } else {
          enqueueSnackbar("Archivo agregado con exito", {
            variant: "success",
            persist: false,
          });
          onUpdateModule(data.data.data);
          closeNewFileModal();
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );

  const { mutate: doDeleteModule, isLoading: isLoadingDeleteModule } =
    useMutation(deleteModule, {
      onSuccess: ({ data }) => {
        if (data) {
          enqueueSnackbar("Modulo borrado", {
            variant: "success",
            persist: false,
          });
          onUpdateModuleDeleted(data);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    });

  const onShowDetail = () => {
    if (hasFile || hasEvaluation || hasVideos || hasPresentations) setShowDetail(!showDetail);
  };

  const onActionClick = (key) => {
    if (key === "video") {
      setViewNewVideo(true);
    }
    if (key === "file") {
      setViewNewFile(true);
    }
    if (key === "evaluation") {
      navigate({
        pathname: ROUTES.ADD_EVALUATION,
        search: `?subject=${subjectId}&module=${item.id}&e=${enrolment}`,
      });
    }
    if (key === "presentation") {
      setViewNewPresentation(true);
    }

    if (key === "liveEvent") {
      setViewNewLiveEvent(true);
    }
  };

  const onDeleteModule = () => {
    doDeleteModule({ subject: subjectId, submodule: item.id });
  };

  const onAddNewLiveEvent = (videoUrlData) => {
    onNewLiveEvent({
      idSubjectModule: item.id,
      eventUrl: videoUrlData.url,
    });
  };

  const onAddNewVideo = (videoData) => {
    onNewVideo({
      idSubjectModule: item.id,
      name: videoData.name,
      url: videoData.url,
    });
  };
  const onAddNewFile = (subjectModule, titleFile, file) => {
    onNewfile({ subjectModule, titleFile, file });
  };

  const onAddNewPresentation = (videoData) => {
    onNewPresentation({
      idSubjectModule: item.id,
      name: videoData.name,
      presentationUrl: videoData.presentationUrl,
    });
  };

  const onDeleteFile = (module) => {
    onUpdateModule(module);
  };

  const onDeleteEvaluation = (module) => {
    onUpdateModule(module);
  };

  const onDownloadFile = (fileUrl) => {
    window.open(fileUrl, "_blank").focus();
  };

  const getIconImage = (file) => {
    const contentType = file.contentType.split("/")[1];
    return getIconFile(contentType);
  };

  const onViewEvaluation = (evaluation) => {
    if (isEmptyObject(evaluation?.details)) {
      navigate({
        pathname: ROUTES.TEST,
        search: `?evaluation=${evaluation?.id}&e=${enrolment}`,
      });
    } else {
      navigate({
        pathname: ROUTES.VIEW_EVALUATION,
        search: `?result=${evaluation?.details?.id}&e=${enrolment}`,
      });
    }
  };

  const getEvaluationText = (evaluation) => {
    if (isEmptyObject(evaluation?.details)) {
      return "Realizar Evaluación";
    } else {
      return evaluation?.details?.state === "Pending"
        ? "Consultar"
        : "Ver calificación";
    }
  };

  const getWatchedAllVideosModuleText = (videos) => {
    if (videos.length === 0) return "";

    let allVideosSee = true;

    videos?.forEach((video) => {
      if (!video?.see && allVideosSee) {
        allVideosSee = false;
      }
    });

    if (allVideosSee) return "¡Clases vistas!";
    else return "";
  };

  return (
    <CardContainer detailIsVisible={showDetail}>
      <motion.header />
      <CardBody detailsIsOpen={showDetail}>
        <LeftContentModule>
          <img src={ModuleIcon} width={20} alt="moduleicon"></img>
          <TitleModule onClick={onShowDetail}> {item?.title}</TitleModule>
          <Link
            color="success"
            style={{ cursor: "pointer", fontSize: "12px" }}
          >
            {getWatchedAllVideosModuleText(item?.videos)}
          </Link>
        </LeftContentModule>
        <ActionsContainer>
          {modeEdit && (
            <>
              <MenuAddModuleContainer>
                <NewVideoModal
                  visible={viewNewVideo}
                  onCloseModal={closeNewVideoModal}
                  sendVideo={onAddNewVideo}
                  isLoading={isLoadingNewVideo}
                />
                <NewLiveEventModal
                  visible={viewNewLiveEvent}
                  onCloseModal={closeNewLiveModal}
                  sendLiveEvent={onAddNewLiveEvent}
                  isLoading={isLoadingNewLiveEvent}
                />
                <NewFileModal
                  visible={viewNewFile}
                  onCloseModal={closeNewFileModal}
                  sendFile={onAddNewFile}
                  isLoading={isLoadingNewFile}
                  subjectModule={item.id}
                />
                <NewPresentationModal
                  visible={viewNewPresentation}
                  onCloseModal={closeNewPresentationModal}
                  sendPresentation={onAddNewPresentation}
                  isLoading={isLoadingNewFile}
                  subjectModule={item.id}
                />
                <Dropdown>
                  <DropdownTrigger>
                    <Button variant="faded" color="primary">
                      Agregar
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Static Actions"
                    variant="faded"
                    onAction={onActionClick}
                  >
                    <DropdownItem
                      startContent={
                        <img src={VideoIcon} alt="videoicon" width="20px" />
                      }
                      key="video"
                      style={{ color: "black" }}
                    >
                      Video
                    </DropdownItem>
                    <DropdownItem
                      startContent={
                        <img src={FileIcon} alt="fileicon" width="20px" />
                      }
                      key="file"
                      style={{ color: "black" }}
                    >
                      Archivo
                    </DropdownItem>
                    <DropdownItem
                      startContent={
                        <img src={EvaluationIcon} alt="fileicon" width="20px" />
                      }
                      key="evaluation"
                      style={{ color: "black" }}
                    >
                      Evaluacion
                    </DropdownItem>
                    <DropdownItem
                      startContent={
                        <img
                          src={PresentationIcon}
                          alt="fileicon"
                          width="20px"
                        />
                      }
                      key="presentation"
                      style={{ color: "black" }}
                    >
                      Presentación PPT
                    </DropdownItem>
                    <DropdownItem
                      startContent={<img
                        src={LiveIcon}
                        alt="fileicon"
                        width="20px"
                      />}
                      key="liveEvent"
                      style={{ color: "black" }}
                    >
                      Clase en vivo
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </MenuAddModuleContainer>

              <ButtonConfirmation
                actionLoading={isLoadingDeleteModule}
                textButton="Borrar módulo"
                colorButton="danger"
                contentPopover={<DeleteModule deleteModule={onDeleteModule} />}
              />
            </>
          )}
          <>
            {!modeEdit && hasLiveEvent && (
              <div className="flex flex-col md:flex-row items-center">
                < LiveLink link={item?.liveEvent} />
                <Chip className="mt-2 md:mt-0 ml-2" color="warning">20:00hs</Chip>
              </div>
            )}
            < ArrowContainer >
              {(hasFile || hasEvaluation || hasVideos || hasPresentations) && (
                <Arrow
                  src={showDetail ? UpArrow : DownArrow}
                  onClick={onShowDetail}
                />
              )}
            </ArrowContainer>
          </>
        </ActionsContainer>
      </CardBody>
      {
        showDetail && (
          <motion.section {...animation}>
            <DetailItem>
              {item?.videos?.map((video, index) => (
                <DetailItemContainer className="md:py-3 md:px-7 py-2 px-2">
                  <div className="flex items-center">
                    <Icon
                      src={PlayIcon}
                      width="15px"
                      height="15px"
                      rigth="8px"
                    />
                    <TextStyled>{video?.name}</TextStyled>
                    {video?.see && (
                      <div className="flex w-full">
                        <Icon
                          src={CheckIcon}
                          alt="viewImg"
                          width="25px"
                          rigth="5px"
                          left="5px"
                        />
                        <Link color="success" style={{ fontSize: "12px" }} className="md:min-w-min">
                          Visto
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="flex ">
                    <ActionDetailButton
                      color="success"
                      isModeEdit={modeEdit}
                      onClick={() => onShowVideo(video)}
                      className="md:w-48"
                    >
                      Ver clase
                    </ActionDetailButton>
                    {modeEdit && (
                      <DeleteVideo
                        video={video}
                        onUpdateModule={onUpdateModule}
                        module={item}
                      />
                    )}
                  </div>
                </DetailItemContainer>
              ))}
              {item?.presentations?.map((file, index) => (
                <DetailItemContainer className="md:py-3 md:px-7 py-2 px-2">
                  <Icon
                    src={PresentationIcon}
                    width="15px"
                    height="15px"
                    rigth="5px"
                  />
                  <TextStyled>{file?.name}</TextStyled>
                  <ActionDetailButton
                    color="success"
                    isModeEdit={modeEdit}
                    onClick={() => window.open(file?.presentationUrl, "_blank")}
                    className="md:w-48"
                  >
                    Ver presentación
                  </ActionDetailButton>
                  {modeEdit && (
                    <DeletePresentation
                      file={file}
                      module={item}
                      onUpdateModule={onDeleteFile}
                    />
                  )}
                </DetailItemContainer>
              ))}
              {item?.files?.map((file, index) => (
                <DetailItemContainer className="md:py-3 md:px-7 py-2 px-2">
                  <Icon
                    src={getIconImage(file)}
                    width="15px"
                    height="15px"
                    rigth="5px"
                  />
                  <TextStyled>{file?.name}</TextStyled>
                  <ActionDetailButton
                    color="success"
                    isModeEdit={modeEdit}
                    onClick={() => onDownloadFile(file?.pathUrl)}
                    className="md:w-48"
                  >
                    Descargar
                  </ActionDetailButton>
                  {modeEdit && (
                    <DeleteFile
                      file={file}
                      module={item}
                      onUpdateModule={onDeleteFile}
                    />
                  )}
                </DetailItemContainer>
              ))}
              {item?.evaluations?.map((evaluation, index) => (
                <DetailItemContainer className="md:py-3 md:px-7 py-2 px-2">
                  <Icon
                    src={EvaluationIcon}
                    width="15px"
                    height="15px"
                    rigth="5px"
                  />
                  <TextStyled>{evaluation?.title}</TextStyled>
                  {!isEmptyObject(evaluation?.details) && (
                    <Chip
                      color={
                        evaluation?.details?.state === "Pending"
                          ? "warning"
                          : "success"
                      }
                      size="sm"
                      variant="flat"
                      style={{ width: "100%" }}
                    >
                      {evaluation?.details?.state === "Pending"
                        ? "Sin corregir"
                        : "Corregido"}
                    </Chip>
                  )}
                  {isEmptyObject(evaluation?.details) && (
                    <Chip
                      color="warning"
                      size="sm"
                      variant="flat"
                      style={{ width: "100%" }}
                    >
                      Pendiente
                    </Chip>
                  )}

                  <ActionDetailButton
                    color="success"
                    isModeEdit={modeEdit}
                    onClick={() => onViewEvaluation(evaluation)}
                    className="md:w-72"
                    isDisabled={evaluation?.do}
                  >
                    {getEvaluationText(evaluation)}
                  </ActionDetailButton>
                  {modeEdit && (
                    <DeleteEvaluation
                      evaluation={evaluation}
                      module={item}
                      onUpdateModule={onDeleteEvaluation}
                    />
                  )}
                </DetailItemContainer>
              ))}
            </DetailItem>
          </motion.section>
        )
      }
    </CardContainer >
  );
};

export default ItemCollapse;
