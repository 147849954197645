import styled from "styled-components";

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(161.91deg, #1c1f30 4.96%, #242d2b 83.92%);
  width: ${({isOpen}) => (isOpen ? "278px" : "80px")};
  min-width: ${({isOpen}) => (isOpen ? "278px" : "80px")};
  box-shadow: rgb(37 72 103 / 37%) 0px 7px 29px 0px;
  transition: ${({isOpen}) => (isOpen ? "0.3s" : "0s")};
  overflow: hidden;
  white-space: nowrap;
`;

export default NavContainer;
