import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Checkbox, CheckboxGroup, Input, Textarea} from "@nextui-org/react";
import CardStyledQuestion from "../../styled-components/CardStyledQuestion";
import OptionsContainer from "./styled-components/OptionsContainer";
import ActionInputOptions from "./styled-components/ActionInputOptions";
import ScoreTitle from "../../styled-components/ScoreTitle";
import ScoreCountContainer from "../../styled-components/ScoreCountContainer";

const MultipleChoiceContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 20px;
    & textarea {
        font-family: Inter !important;
    }
`

const MultipleChoice = ({id, onDeleteQuestion, question, questions, setQuestions, count}) => {
    const [titleChoiceQuestion, setTitleChoiceQuestion] = useState();
    const [optionsResponse, setOptionsResponse] = useState([]);
    const [optionInput, setOptionInput] = useState();
    const [correctOptions, setCorrectOptions] = useState([]);
    const [scoreMultipleChois, setScoreMultipleChois] = useState();

    const onAddOption = () => {
        const index = optionsResponse?.findIndex((obj) => obj?.text === optionInput?.text);
        if (index === -1)
            setOptionsResponse([...optionsResponse, optionInput?.text]);
    }

    //Actualizo las respuestas multiple opcion
    useEffect(() => {

        const newOptions = questions.map((option) => {
            if (option.mock_id === id) {
                option.description.title = titleChoiceQuestion?.name;
            }
            return option;
        });
        setQuestions(newOptions);
    }, [titleChoiceQuestion])

    useEffect(() => {
        const newOptions = questions.map((option) => {
            if (option.mock_id === id) {
                option.score = parseInt(scoreMultipleChois?.value, 10);
            }
            return option;
        });
        setQuestions(newOptions);
    }, [scoreMultipleChois])

    //Actualizo las respuestas multiple opcion
    useEffect(() => {

        const newOptions = questions.map((option) => {
            if (option.mock_id === id) {
                option.description.title = titleChoiceQuestion?.name;
                option.description.options = optionsResponse;
            }
            return option;
        });
        setQuestions(newOptions);
    }, [optionsResponse])

    //Actualizo las opciones correctas
    useEffect(() => {
        const correctOptionsResult = [];

        const questionFilter = questions.find(item => item?.mock_id === id);

        for (let i = 0; i < correctOptions?.length; i++) {
            correctOptionsResult.push(questionFilter?.description?.options.indexOf(correctOptions[i]))
        }

        const questionCopy = [...questions];
        const questionToUpdate = questionCopy.find(item => item.mock_id === id);
        questionToUpdate.response = correctOptionsResult;
        setQuestions([...questionCopy]);

    }, [correctOptions])


    return (
        <MultipleChoiceContainer>
            <ScoreCountContainer>
                <ScoreTitle> {count} </ScoreTitle>
                <Input
                    label="Puntaje"
                    type="number"
                    onChange={({target}) => {
                        setScoreMultipleChois({
                            value: target.value,
                        });
                    }}
                    css={{width: "250px"}}
                />
            </ScoreCountContainer>


            <CardStyledQuestion>
                <Input
                    label="Titulo"
                    type="text"
                    defaultValue={titleChoiceQuestion}
                    onChange={({target}) => {
                        setTitleChoiceQuestion({
                            name: target.value,
                        });
                    }}
                    id="options1"
                />
                <OptionsContainer>

                    <CheckboxGroup
                        label="Seleccionar las opciones correctas"
                        onChange={(target) => setCorrectOptions(target)}
                    >
                        {
                            optionsResponse?.map((option, index) => (
                                <Checkbox
                                    value={option}
                                >
                                    {option}
                                </Checkbox>
                            ))
                        }
                    </CheckboxGroup>


                    <ActionInputOptions>
                        <Input
                            clearable
                            underlined
                            labelPlaceholder="Agregar opción"
                            // defaultValue={optionInput?.text}
                            value={optionInput?.text}
                            onChange={({target}) => {
                                setOptionInput({
                                    text: target.value,
                                });
                            }}
                        />
                        <Button shadow color="success" auto onClick={onAddOption}
                                css={{marginLeft: "10px"}}>
                            Agregar
                        </Button>
                    </ActionInputOptions>

                </OptionsContainer>
            </CardStyledQuestion>
            <Button color="error" auto onClick={() => onDeleteQuestion(id)}>
                Borrar
            </Button>

        </MultipleChoiceContainer>
    );
};

export default MultipleChoice;
