import React, { useEffect } from "react";
import HomeContainer from "./styled-components/HomeContainer";
import { Button } from "@nextui-org/react";
import CardsContainer from "./styled-components/CardsContainer";
import TabStatusSubject from "./styled-components/TabStatusSubject";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import getEnrolments from "@services/users/getEnrolments";
import { useNavigate } from "react-router-dom";
import NoResults from "@components/NoResults";
import ROUTES from "@components/Router/routes";
import noContent from "@assets/no-content.png";
import isEmptyObject from "@helpers/isEmptyObject";
import Title from "@components/Title/Title";
import NewCardsEnrollments from "./components/NewCardsEnrollments";
import SelectCourseModal from "@pages/Global/components/SelectCourseModal";
import SkeletonSubjects from "./components/SkeletonSubjects";
import Alert from "@components/Alert";

function Home({ userLogged }) {
  const navigate = useNavigate();
  const [enrolments, setEnrolments] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState(0);
  const [showSelectCourse, setShowSelectCourse] = React.useState(false);

  const { mutate: fetchEnrolments, isLoading: isLoadingEnrolments } =
    useMutation(getEnrolments, {
      onSuccess: ({ data }) => {
        if (data?.activeCourses?.length > 1) {
          setShowSelectCourse(true);
        }
        setEnrolments(data);
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    });


  const onFilterCourseSelected = ({ courseId }) => {
    setEnrolments({ ...enrolments, activeSubjects: enrolments.activeSubjects.filter(({ course }) => course === courseId) });
    setShowSelectCourse(false)
  }

  useEffect(() => {
    fetchEnrolments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToContent = (subject) => {
    navigate({
      pathname: "/content",
      search: `?e=${subject.idEnrolment}`,
    });
  };

  const goToPaymentPlan = (subject) => {
    navigate({
      pathname: "/content",
      search: `?e=${subject.idEnrolment}`,
    });
  };

  return (
    <HomeContainer>
      <SelectCourseModal
        visible={showSelectCourse}
        activeCourses={enrolments?.activeCourses}
        fetchCourse={onFilterCourseSelected}
        onCloseModal={() => navigate(ROUTES.COURSES)}
      />
      <TabStatusSubject>Hola, {userLogged?.name} &#128075;</TabStatusSubject>
      <div className="px-4 pt-10 pb-5">
        <Title size="2em">Materias</Title>
      </div>
      <CardsContainer>
        <Button
          onClick={() => setActiveTab(0)}
          flat
          auto
          isDisabled={isLoadingEnrolments}
          color={activeTab === 0 && "success"}
          style={{
            margin: "0px 10px",
            color: "white",
          }}
        >
          Activas
        </Button>
        <Button
          onClick={() => setActiveTab(1)}
          flat
          auto
          isDisabled={isLoadingEnrolments}
          color={activeTab === 1 && "success"}
          style={{ margin: "0px 10px", color: "white" }}
        >
          Finalizadas
        </Button>
      </CardsContainer>
      <CardsContainer>
        {isEmptyObject(enrolments) && (
          <div className="flex flex-col items-center w-full">
            <NoResults
              message="Aun no te inscribiste a un curso"
              icon={noContent}
            />
            <Button
              style={{
                background:
                  "linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%)",
                color: "white",
                padding: "15px",
              }}
              onClick={() => navigate(ROUTES.COURSES)}
              size="lg"
            >
              Ver cursos disponibles
            </Button>
          </div>
        )}
        {isLoadingEnrolments && <SkeletonSubjects />}
        {enrolments?.activeSubjects?.length === 0 && activeTab === 0 && !isLoadingEnrolments && (
          <NoResults message="No hay materias activas" icon={noContent} />
        )}
        {enrolments?.activeSubjects?.length > 0 && activeTab === 0 && !isLoadingEnrolments && (
          <NewCardsEnrollments
            activeSubjects={enrolments?.activeSubjects}
            goToContent={goToContent}
          />
        )}
        {enrolments?.finishSubjects?.length === 0 && activeTab === 1 && !isLoadingEnrolments && (
          <NoResults message="No hay materias finalizadas" icon={noContent} />
        )}
        {enrolments?.finishSubjects?.length > 0 && activeTab === 1 && !isLoadingEnrolments && (
          <NewCardsEnrollments
            activeSubjects={enrolments?.finishSubjects}
            goToContent={goToContent}
          />
        )}

        <NewCardsEnrollments />
      </CardsContainer>
    </HomeContainer>
  );
}

export default Home;
