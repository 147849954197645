import styled from "styled-components";

const EnrolmentGestionContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  background-color: #202830;
`;

export default EnrolmentGestionContainer;
