import styled from "styled-components";
import { devices } from "@utils/devices";

const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and ${devices.md} {
    flex-direction: row;
  }
  width: 100%;
  @media only screen and ${devices.md} {
    width: 60%;
  }
  margin: 20px;
  height: 700px;
  overflow-x: hidden;
`;

export default CenterContent;
