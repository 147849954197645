import axios from "../axios";
import { doResetPasswordURL } from "./URLs";

const doResetPassword = async ({ newPassword }) => {
  try {
    const response = await axios().put(doResetPasswordURL, {
      newPassword: newPassword,
    });
    return response || {};
  } catch (error) {
    return error.response;
  }
};

export default doResetPassword;
