import axios from "../axios";
import { deleteUserURL } from "./URLs";

const deleteUser = async (idUser) => {
  try {
    const response = await axios().delete(deleteUserURL, {
      data: {
        idUser: idUser,
      },
    });
    return response.data || {};
  } catch {
    return {};
  }
};

export default deleteUser;
