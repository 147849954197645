import styled from "styled-components";

const InputFile = styled.input` 
  margin-right: 20px;
  border: none;
  background: linear-gradient(161.91deg, #87a49f 4.96%, #a1cfc6 83.92%);
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
  margin: 10px;
  font-size: 14px;
`;

export default InputFile;
