import React from "react";
import { RadioGroup, Radio, cn } from "@nextui-org/react";

export const RadioSelect = (props) => {
    const { children, ...otherProps } = props;

    return (
        <Radio
            {...otherProps}
            classNames={{
                base: cn(
                    "inline-flex m-0 bg-red hover:bg-content2 items-center justify-between w-full",
                    "flex-row-reverse max-w-[full] items-center cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent",
                    "data-[selected=true]:border-primary"
                ),
            }}
        >
            {children}
        </Radio>
    );
};

export default function SelectCourseRadio({ activeCourses, onSelectCourse }) {
    return (
        <RadioGroup label="Seleccionar un curso activo" onChange={({ target }) => onSelectCourse(target.value)}>
            {activeCourses?.map((course) =>
                <RadioSelect value={course.id}>
                    {course?.name}
                </RadioSelect>
            )}
        </RadioGroup>
    );
}