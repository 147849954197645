import React, { useEffect } from "react";
import TitleHeader from "@components/TitleHeader/TitleHeader";
import HomeContainer from "./styled-components/HomeContainer";
import getCourses from "@services/courses/getCourses";
import CardsContainer from "./styled-components/CardsContainer";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import doInscription from "@services/courses/doInscription";
import doInscriptionFree from "@services/courses/doInscriptionFree";
import ROUTES from "@components/Router/routes";
import { SiteLoader } from "@components/SiteLoader/SiteLoader";
import HeaderHomeCourses from "./styled-components/HeaderHomeCourses";
import CardCourses from "./CardCourses";
import getCouponInfo from "@services/coupons/getCouponInfo";
import noContent from "@assets/no-content.png";
import NoResults from "../../components/NoResults";
import Checkout from "../PaymentPlan/components/Checkout";
import { useNavigate } from "react-router-dom";
import InscriptionDuplicateModal from "./components/InscriptionDuplicateModal";

function Courses({ userLogged }) {
  const navigateTo = useNavigate();
  const [courses, setCourses] = React.useState();
  const [preferenceCourse, setPreferenceCourse] = React.useState();
  const [courseSelected, setCourseSelected] = React.useState();
  const [showPaymentCourse, setShowPaymentCourse] = React.useState(false);
  const [isTotalDiscount, setIsTotalDiscount] = React.useState(false);
  const [couponApplied, setCouponApplied] = React.useState(false);
  const [validCodeDiscount, setValidCodeDiscount] = React.useState("");
  const [registrationAlreadyExists, setRegistrationAlreadyExists] = React.useState(false);

  const closeHandler = () => {
    setShowPaymentCourse(false);
  };

  const { mutate: fetchCourses, isLoading: isLoadingCourses } = useMutation(
    getCourses,
    {
      onSuccess: ({ data }) => {
        if (data) {
          setCourses(data);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );

  const {
    mutate: sendInscriptionWithTotalDiscount,
    isLoading: isLoadingTotalDiscount,
  } = useMutation(doInscription, {
    onSuccess: (data) => {
      if (data?.status !== 200) {
        enqueueSnackbar(data?.data?.message, {
          variant: "error",
          persist: false,
        });
      } else {
        closeHandler();
        enqueueSnackbar("Inscripción exitosa", {
          variant: "success",
          persist: false,
        });
        navigateTo(ROUTES.HOME);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.message, {
        variant: "error",
        persist: false,
      });
    },
  });

  const { mutate: sendInscription, isLoading: isLoadingInscription } =
    useMutation(doInscription, {
      onSuccess: (data) => {
        if (data?.status === 406) {
          setRegistrationAlreadyExists(true);
          closeHandler();
        }
        if (data?.status !== 200 && !data?.status === 406) {
          enqueueSnackbar("Error, intente de nuevo mas tarde", {
            variant: "error",
            persist: false,
          });
        }
        if (data?.status === 200) {
          setPreferenceCourse(data?.data?.data);
          setShowPaymentCourse(true);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    });

  const { mutate: sendInscriptionFree, isLoading: isLoadingInscriptionFree } =
    useMutation(doInscriptionFree, {
      onSuccess: (data) => {
        if (data?.status === 406) {
          enqueueSnackbar("Ya inscripto", {
            variant: "error",
            persist: false,
          });
        }
        if (data?.status !== 200 && !data?.status === 406) {
          enqueueSnackbar("Error, intente de nuevo mas tarde", {
            variant: "error",
            persist: false,
          });
        }
        if (data?.status === 200) {
          closeHandler();
          enqueueSnackbar("Inscripción exitosa", {
            variant: "success",
            persist: false,
          });
          navigateTo(ROUTES.HOME);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    });

  const { mutate: doGetCouponInfo, isLoading: isValidatingCoupon } =
    useMutation(getCouponInfo, {
      onSuccess: (data) => {
        if (data?.status !== 200) {
          enqueueSnackbar(data?.data?.errors, {
            variant: "error",
            persist: false,
          });
        } else {
          const code = data?.data?.data?.code;
          const totalDiscount = data?.data?.data?.isTotalDiscount;
          const preferenceData = data?.data?.data;

          setPreferenceCourse({
            ...preferenceCourse,
            ...preferenceData,
            preference: totalDiscount ? undefined : preferenceData?.preference,
            totalCost: totalDiscount ? 0 : preferenceData.totalCost,
          });

          if (totalDiscount) {
            setIsTotalDiscount(true);
          }

          setCouponApplied(true);
          setValidCodeDiscount(code);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    });

  useEffect(() => {
    fetchCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInjectDiscount = (cupon) => {
    doGetCouponInfo({ couponId: cupon, course: courseSelected?.id });
  };

  const getPaymentInscription = (params) => {
    sendInscription(params);
    setIsTotalDiscount(false);
    setCouponApplied(false);
    setValidCodeDiscount("");
  };

  const onSendInscriptionTotalDiscount = () => {
    sendInscriptionWithTotalDiscount({
      idCourse: courseSelected?.id,
      cuponCode: validCodeDiscount,
    });
  };

  const onFreeInscription = () => {
    sendInscriptionFree({
      idCourse: courseSelected?.id
    });
  };

  if (isLoadingCourses || isLoadingInscription) return <SiteLoader />;

  if (showPaymentCourse) return <Checkout
    backButtton={closeHandler}
    preference={preferenceCourse}
    descriptionItemTwo={"Matrícula:"}
    descriptionItemTwoPrice={preferenceCourse?.registrationCost}
    descriptionItemThree={"Cuota 1"}
    descriptionItemThreePrice={preferenceCourse?.installment}
    descriptionItemFourPrice={`${preferenceCourse?.totalCost > 0
      ? preferenceCourse?.totalCost
      : "Gratis"
      }`}
    course={courseSelected}
    onPrimaryActionModal={onSendInscriptionTotalDiscount}
    onSecondaryActionModal={onInjectDiscount}
    onTertiaryActionModal={onFreeInscription}
    isLoadingSecondaryActionModal={isValidatingCoupon}
    isLoadingPrimaryActionModal={isLoadingTotalDiscount}
    isLoadingTertiaryActionModal={isLoadingInscriptionFree}
    isTotalDiscount={isTotalDiscount}
    couponApplied={couponApplied}
    userInfo={userLogged}
  />

  return (
    <HomeContainer>
      <InscriptionDuplicateModal
        visible={registrationAlreadyExists}
        onCloseModal={() => setRegistrationAlreadyExists(false)}
      />
      <HeaderHomeCourses>
        <TitleHeader> Cursos disponibles </TitleHeader>
      </HeaderHomeCourses>
      <CardsContainer>
        {courses?.map((course, index) => {
          return (
            course.visible && (
              <CardCourses
                course={course}
                onSendInscription={getPaymentInscription}
                setCourseSelected={setCourseSelected}
                showPaymentCourse={showPaymentCourse}
                requestInscriptionLoading={isLoadingInscription}
                isLoading={isLoadingInscription}
                mainCourse={index === 0}
              ></CardCourses>
            )
          )
        })}
        {courses?.length === 0 && (
          <NoResults
            message="No hay cursos disponibles"
            icon={noContent}
            description="Aún no se encuentran cursos disponibles para inscribirse."
          />
        )}
      </CardsContainer>
    </HomeContainer>
  );
}

export default Courses;
