import React, {useEffect, useState} from "react";
import {Button, Input, Textarea} from "@nextui-org/react";
import CardStyledQuestion from "../../styled-components/CardStyledQuestion";
import ScoreTitle from "../../styled-components/ScoreTitle";
import ScoreCountContainer from "../../styled-components/ScoreCountContainer";
import SingleQuestionContainer from "./styled-components/SingleQuestionContainer";


const SingleQuestion = ({id, onDeleteQuestion, question, questions, setQuestions, count}) => {
    const [titleSingleQuestion, setTitleSingleQuestion] = useState();
    const [scoreSingle, setScoreSingle] = useState();

    //Actualizo las respuestas multiple opcion
    useEffect(() => {
        const newOptions = questions.map((option) => {
            if (option.mock_id === id) {
                option.description.title = titleSingleQuestion?.name;
            }
            return option;
        });
        setQuestions(newOptions);
    }, [titleSingleQuestion])

    useEffect(() => {
        const newOptions = questions.map((option) => {
            if (option.mock_id === id) {
                option.score = parseInt(scoreSingle?.value, 10);
            }
            return option;
        });
        setQuestions(newOptions);
    }, [scoreSingle])

    return (
        <SingleQuestionContainer>
            <ScoreCountContainer>
                <ScoreTitle> {count} </ScoreTitle>
                <Input
                    label="Puntaje"
                    type="number"
                    onChange={({target}) => {
                        setScoreSingle({
                            value: target.value,
                        });
                    }}
                    css={{width: "250px"}}
                />
            </ScoreCountContainer>
            <CardStyledQuestion>
                <div className="p-2">
                    <Input
                        label="Titulo"
                        type="text"
                        defaultValue={titleSingleQuestion}
                        onChange={({target}) => {
                            setTitleSingleQuestion({
                                name: target.value,
                            });
                        }}
                    />
                </div>
                <Textarea
                    label="Respuesta"
                    placeholder="Respuesta..."
                    isDisabled
                />
            </CardStyledQuestion>
            <Button color="error" auto onClick={() => onDeleteQuestion(id)}>
                Borrar
            </Button>

        </SingleQuestionContainer>
    );
};

export default SingleQuestion;
