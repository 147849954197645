import styled from "styled-components";

const UsersContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 100%;
  overflow-y: scroll;
  background-color: #202830;
`;

export default UsersContainer;
