import styled from "styled-components";

const WebProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #202830;
  align-items: center;
  text-align: center;
  padding-bottom: 20px;
  width: 100%;
`;

export default WebProfileContainer;
