import styled from "styled-components";

const TitleModal = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.1em;
  width: 100%;
  padding: 10px 0px;
  color: black;
`;

export default TitleModal;
