import axios from "../axios";
import { getUsersURL } from "./URLs";

const getUsers = async () => {
  try {
    const response = await axios().get(getUsersURL);
    return response.data || {};
  } catch {
    return {};
  }
};

export default getUsers;
