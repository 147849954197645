import React, { useEffect, useState } from "react";
import RecoveryContainer from "./styled-components/RecoveryContainer";
import { useMutation } from "react-query";
import postResetPassword from "@services/auth/doResetPassword";
import postRegister from "@services/auth/doRegister";
import CenterRecoveryContent from "./styled-components/CenterRecoveryContent";
import TitleLogin from "./styled-components/TitleLogin";
import FormSection from "./styled-components/FormSection";
import Logo from "@assets/logo-berea.png";
import LeftRecoveryContent from "./styled-components/LeftRecoveryContent";
import SubtitleLogin from "./styled-components/SubtitleLogin";
import ROUTES from "@components/Router/routes";
import LogoIcon from "./styled-components/LogoIcon";
import ViewRecoveryPassword from "./components/ViewRecoveryPassword";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function RecoveryPassword({ isUserLogged, userLogged }) {
  const [stateNewPassword, setStateNewPassword] = useState({});
  const navigateTo = useNavigate();

  const { mutate: doResetPassword, isLoading: isLoadingReset } = useMutation(
    postResetPassword,
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          enqueueSnackbar("Contraseña actualizada", {
            variant: "success",
            persist: false,
          });
          navigateTo(ROUTES.HOME);
          window.location.reload();
        } else {
          enqueueSnackbar(data?.data?.message || "No se pudo actualizar", {
            variant: "error",
            persist: false,
          });
        }
      },
      onError: () => {
        enqueueSnackbar("Problemas al querer ingresar", {
          variant: "error",
          persist: false,
        });
      },
    },
  );
  const { mutate: doRegister, isLoading: isLoadingRegister } = useMutation(
    postRegister,
    {
      onSuccess: (data) => { },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    const { newPassword } = stateNewPassword;
    doResetPassword({
      newPassword,
    });
  };

  if (!userLogged) return;

  return (
    <RecoveryContainer>
      <CenterRecoveryContent>
        <LeftRecoveryContent>
          <LogoIcon src={Logo} />
          <TitleLogin>BEREA</TitleLogin>
          <SubtitleLogin>Instituto Bíblico Online</SubtitleLogin>
        </LeftRecoveryContent>
        <FormSection onSubmit={onSubmit}>
          <ViewRecoveryPassword
            stateNewPassword={stateNewPassword}
            setStateNewPassword={setStateNewPassword}
            loading={isLoadingReset}
          />
        </FormSection>
      </CenterRecoveryContent>
    </RecoveryContainer>
  );
}

export default RecoveryPassword;
