import React from "react";
import {Wallet} from "@mercadopago/sdk-react";


function ButtonPayment({preference, setLoadingDetailModal}) {

    return (
        <div className="flex justify-center">
            <Wallet
                initialization={{
                    preferenceId: preference,
                    redirectMode: 'self'
                }}
                onReady={() => setLoadingDetailModal(false)}
                customization={{
                    visual: {
                        buttonBackground: "blue",
                        borderRadius: "16px",
                        buttonHeight: "40px",
                    },
                }}
            />
        </div>
    );
}

export default ButtonPayment;
