import axios from "../axios";
import { doFetchRequestRecoveryURL } from "./URLs";

const doFetchRequestRecovery = async ({ emailRecovery }) => {
  try {
    const response = await axios().post(doFetchRequestRecoveryURL, {
      email: emailRecovery,
    });
    return response || {};
  } catch (error) {
    return error.response;
  }
};

export default doFetchRequestRecovery;
