import styled from "styled-components";

const SingleQuestionContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 20px;
    & textarea {
        font-family: Inter !important;
    }
`;

export default SingleQuestionContainer;
