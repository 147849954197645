import {Link} from "@nextui-org/react";
import styled from "styled-components";

const ActionDetailButton = styled(Link)`
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 13px;
  padding: 10px;
  background-color: #4b4d3db3; 
  border-radius: 12px;
  margin: 0px 10px;
`;

export default ActionDetailButton;
