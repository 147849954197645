import axios from "../axios";
import { createEvaluationURL } from "./URLs";

const doCreateEvaluation = async ({ dataEvaluation }) => {
  try {
    const response = await axios().post(createEvaluationURL, {
      ...dataEvaluation,
    });
    return response.data || {};
  } catch (error) {
    return error.response;
  }
};

export default doCreateEvaluation;
