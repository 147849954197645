import styled from "styled-components";
import { devices } from "@utils/devices";

const LeftRecoveryContent = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and ${devices.md} {
    width: 60%;
  }
  height: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%);
  padding-bottom: 50px;
}
`;

export default LeftRecoveryContent;
