import React from "react";
import {
  Card,
  CardBody,
} from "@nextui-org/react";
import CashIcon from "@assets/icons/cash.svg";
import PaymentIcon from "@assets/icons/card.svg";
import MpIcon from "@assets/icons/mp.svg";
import AbitabIcon from "@assets/abitab.svg";
import RedPagosIcon from "@assets/redpagos.svg";
import InfoCircle from "@assets/icons/info-circle.svg";

const PaymentInformation = ({ inscriptionMode = false }) => {
  return (
    <div className="flex items-center justify-center pt-8" >
      <Card className="bg-slate-300">
        <CardBody>
          <div className="flex justify-center items-center">
            <img src={MpIcon} width={80} alt="mercadopago"></img>
          </div>
          <div className="mt-3">
            <div className="flex justify-center text-sm items-center mb-3">
              <p>Medios de pago:</p>
            </div>
            <div className="flex justify-center items-center">
              <img src={PaymentIcon} width={20} alt="mercadopago"></img>
              <p>Débito/Crédito</p>
            </div>
            <div className="flex justify-center items-center">
              <img src={CashIcon} width={20} alt="mercadopago"></img>
              <div className="flex">
                <p>Efectivo: </p>
                <img src={AbitabIcon} width={70} alt="abitab" className="mx-2"></img>
                <img src={RedPagosIcon} width={70} alt="redpagos"></img>
              </div>

            </div>
            {!!inscriptionMode &&
              <div className="flex justify-center text-sm items-center mt-3">
                <img src={InfoCircle} width={20} alt="mercadopago"></img>
                <p>La inscripción se completará al momento de realizar el pago.</p>
              </div>
            }
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default PaymentInformation;
