import styled from "styled-components";

const PdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #1b2425;
  align-content: center;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  gap: 20px
`;

export default PdfContainer;
