import React from "react";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { Spinner } from "@nextui-org/react";
import DeleteLink from "../../styled-components/DeleteLink";
import doDeletePresentation from "@services/uploads/doDeletePresentation";

export const DeletePresentation = ({ file, module, onUpdateModule }) => {
  const onDeletePresentation = (file) => {
    deletePresentation({
      item: file._id,
      subjectModule: module.id,
    });
  };

  const { mutate: deletePresentation, isLoading: isDeletingFile } = useMutation(
    doDeletePresentation,
    {
      onSuccess: ({ data }) => {
        if (data) {
          const moduleUpdated = {
            ...module,
            presentations: module.presentations.filter(
              (item) => item._id !== data.id,
            ),
          };
          onUpdateModule(moduleUpdated);
          enqueueSnackbar("Presentacion eliminada", {
            variant: "success",
            persist: false,
          });
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );

  return (
    <>
      <DeleteLink color="error" onClick={() => onDeletePresentation(file)}>
        {isDeletingFile ? (
          <Spinner type="points-opacity" color="currentColor" size="sm" />
        ) : (
          "Eliminar"
        )}
      </DeleteLink>
    </>
  );
};
