import styled from "styled-components";

const QuestionTooltip = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: -8px 6px;
`;

export default QuestionTooltip;
