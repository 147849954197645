import styled from "styled-components";

const SubTitleCard = styled.div`
  font-weight: 500;
  width: 100%;
  color: ${({ primaryColor }) => (primaryColor ? "white" : "lightgray")};
  font-size: 1em;
`;

export default SubTitleCard;
