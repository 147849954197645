const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;
// const BASE_URL = "https://clear-pink-boa.cyclic.app";
// const BASE_URL = "http://localhost:5000";
// console.log("BASE", BASE_URL);

export const getUserURL = `${BASE_URL}/users/session`;
export const doLoginURL = `${BASE_URL}/auth/local/login`;
export const doRegisterURL = `${BASE_URL}/auth/local/register`;
export const doLogoutURL = `${BASE_URL}/users/logout`;
export const doResetPasswordURL = `${BASE_URL}/users/update-password`;
export const doFetchRequestRecoveryURL = `${BASE_URL}/auth/local/reset-password`;
