import styled from "styled-components";

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #21292d;
  border-radius: 50px;
  align-items: center;
  width: 100%;
  margin: 15px;
`;

export default ProfileContainer;
