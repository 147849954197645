import styled from "styled-components";
import { devices } from "@utils/devices";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  padding: 20px 0px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default ContentContainer;
