import styled from "styled-components";

const HeaderEvaluation = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
`;

export default HeaderEvaluation;
