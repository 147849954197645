import styled from "styled-components";

const LoaderModal = styled.div`
  z-index: 1000;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-top: 80px;
`;

export default LoaderModal;
