import styled from "styled-components";

const TabCoupons = styled.div`
  font-weight: 600;
  font-size: 1em;
  line-height: 56px;
  text-decoration: ${({ isActive }) => (isActive ? "underline" : "none")};
  margin: 0px 10px;
`;

export default TabCoupons;
