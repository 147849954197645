import React from "react";
import {
  Input,
  Modal,
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spinner,
  ModalFooter,
} from "@nextui-org/react";
import TitleModal from "./styled-components/TitleModal";

const NewPresentationModal = ({
  visible,
  onCloseModal,
  sendPresentation,
  isLoading,
}) => {
  const [titlePresentation, setTitlePresentation] = React.useState();
  const [urlPresentation, setUrlPresentation] = React.useState();

  return (
    <Modal backdrop="blur" isOpen={visible} onClose={onCloseModal} size="3xl">
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              <TitleModal>Agregar presentación PPT</TitleModal>
            </ModalHeader>
            <ModalBody>
              <Input
                label="Titulo"
                type="text"
                value={titlePresentation}
                onChange={({ target }) => {
                  setTitlePresentation(target.value);
                }}
                isDisabled={isLoading}
              />
              <Input
                label="Url drive"
                type="text"
                value={urlPresentation}
                onChange={({ target }) => {
                  setUrlPresentation(target.value);
                }}
                isDisabled={isLoading}
              />
              <Button
                css={{ width: "30px", marginTop: "20px" }}
                onClick={() =>
                  sendPresentation({
                    name: titlePresentation,
                    presentationUrl: urlPresentation,
                  })
                }
                isLoading={isLoading}
                color="secondary"
              >
                Agregar
              </Button>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default NewPresentationModal;
