import React from "react";
import {
  Modal,
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import SubTitle from "@components/SubTitle/SubTitle";
import SelectCourseRadio from "./components/SelectCourseRadio/SelectCourseRadio";

const SelectCourseModal = ({
  visible,
  onCloseModal,
  isLoading,
  fetchCourse,
  activeCourses,
  titleVariant = false,
}) => {
  const [activeCourseSelected, setActiveCourseSelected] = React.useState();

  return (
    <Modal
      backdrop="blur"
      isOpen={visible}
      isDismissable={false}
      onClose={onCloseModal}
      size="3xl"
      style={{ color: "black" }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex text-center">
              {titleVariant && <SubTitle>Consultar plan de pagos.</SubTitle>}
              {!titleVariant && <SubTitle>Ver contenido</SubTitle>}
            </ModalHeader>
            <ModalBody>
              <SelectCourseRadio activeCourses={activeCourses} onSelectCourse={setActiveCourseSelected} />
              <Button
                css={{ width: "30px", marginTop: "20px" }}
                onClick={() => fetchCourse({ courseId: activeCourseSelected })}
                isLoading={isLoading}
                color="secondary"
              >
                Aceptar
              </Button>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SelectCourseModal;
