import axios from "../axios";
import {updateProfileURL} from "./URLs";

const updateProfile = async (profileData) => {
    try {
        const response = await axios().put(updateProfileURL, {
            name: profileData.name,
            lastName: profileData.lastName,
            phone: profileData.phone,
            church: profileData.church,
            email: profileData.email,
            address: profileData.address,
            department: profileData.department,
            locality: profileData.locality,
        });

        return response.data || {};
    } catch (error) {
        return error.response;
    }
};

export default updateProfile;
