import axios from "../axios";
import { newLiveEventURL } from "./URLs";

const doCreateLiveEvent = async ({ idSubjectModule, eventUrl }) => {
    try {
        const response = await axios().post(newLiveEventURL, {
            subjectModule: idSubjectModule,
            urls: [
                {
                    eventUrl: eventUrl
                }
            ]
        });
        return response.data || {};
    } catch {
        return {};
    }
};

export default doCreateLiveEvent;
