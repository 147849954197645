import React, { useState } from "react";
import LoginContainer from "./styled-components/LoginContainer";
import { useMutation } from "react-query";
import { useUser } from "@context/UserProvider";
import postLogin from "@services/auth/doLogin";
import postRegister from "@services/auth/doRegister";
import CenterContent from "./styled-components/CenterContent";
import TitleLogin from "./styled-components/TitleLogin";
import FormSection from "./styled-components/FormSection";
import Logo from "@assets/logo-berea.png";
import { Button, Link } from "@nextui-org/react";
import IconGoogle from "@assets/icon-google.svg";
import SeparatorIcon from "@assets/line-separator.svg";
import LeftContent from "./styled-components/LeftContent";
import SubtitleLogin from "./styled-components/SubtitleLogin";
import LogoIcon from "./styled-components/LogoIcon";
import Separator from "./styled-components/Separator";
import SeparatorContainer from "./styled-components/SeparatorContainer";
import GoogleIcon from "./styled-components/GoogleIcon";
import NewAccountContainer from "./styled-components/NewAccountContainer";
import { VIEWS_LOGIN } from "@utils/constants";
import ViewLogin from "./components/ViewLogin";
import ViewRegister from "./components/ViewRegister";
import ViewRenewPassword from "./components/ViewRenewPassword";
import { enqueueSnackbar } from "notistack";
import Text from "@components/Text/Text";
import ROUTES from "@components/Router/routes";
import ViewNotificacionEmail from "./components/ViewNotificacionEmail";
import { useNavigate } from "react-router-dom";

function Login() {
  const [{ email, password }, setStateUser] = useState({});
  const [stateRegisterUser, setStateRegisterUser] = useState({});
  const [modeView, setModeView] = useState(VIEWS_LOGIN.LOGIN);
  const { setUser } = useUser();
  const navigateTo = useNavigate();

  const isLogin = modeView === VIEWS_LOGIN.LOGIN;
  const isRegister = modeView === VIEWS_LOGIN.REGISTER;

  const { REACT_APP_API_URL } = process.env;

  const { mutate: doLogin, isLoading: isLoadingLogin } = useMutation(
    postLogin,
    {
      onSuccess: ({ data }) => {
        if (data && data.name) {
          setUser(data);
          navigateTo(ROUTES.HOME);
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
            persist: false,
          });
        }
      },
      onError: () => {
        enqueueSnackbar("Problemas al querer ingresar", {
          variant: "error",
          persist: false,
        });
      },
    },
  );
  const { mutate: doRegister, isLoading: isLoadingRegister } = useMutation(
    postRegister,
    {
      onSuccess: (data) => {
        if (data) {
          setModeView(VIEWS_LOGIN.NOTIFICATION_EMAIL);
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
            persist: false,
          });
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      lastName,
      church,
      phone,
      emailRegister,
      passwordRegister,
      department,
      city,
      address,
    } = stateRegisterUser;

    if (isLogin) doLogin({ email, password });
    if (isRegister)
      doRegister({
        name,
        lastName,
        church,
        phone,
        email: emailRegister,
        password: passwordRegister,
        locality: city,
        department,
        address,
      });
  };

  return (
    <LoginContainer>
      <CenterContent className="md:justify-center">
        <LeftContent className="md:h-full">
          <LogoIcon src={Logo} />
          <TitleLogin>BEREA</TitleLogin>
          <SubtitleLogin>Instituto Bíblico Online</SubtitleLogin>
        </LeftContent>
        <FormSection onSubmit={onSubmit}>
          {modeView === VIEWS_LOGIN.LOGIN && (
            <ViewLogin
              email={email}
              password={password}
              setStateUser={setStateUser}
              setModeView={setModeView}
              loading={isLoadingLogin}
            />
          )}

          {modeView === VIEWS_LOGIN.REGISTER && (
            <ViewRegister
              stateRegisterUser={stateRegisterUser}
              setStateRegisterUser={setStateRegisterUser}
              setModeView={setModeView}
              loading={isLoadingRegister}
            />
          )}

          {modeView === VIEWS_LOGIN.NOTIFICATION_EMAIL && (
            <ViewNotificacionEmail />
          )}

          {modeView === VIEWS_LOGIN.RENEW_PASSWORD && (
            <>
              <ViewRenewPassword
                setStateUser={setStateUser}
                loading={isLoadingRegister}
              />
            </>
          )}

          <SeparatorContainer>
            <Separator src={SeparatorIcon} />
          </SeparatorContainer>
          {modeView !== VIEWS_LOGIN.REGISTER &&
            modeView !== VIEWS_LOGIN.NOTIFICATION_EMAIL && (
              <Button
                startContent={<GoogleIcon src={IconGoogle} />}
                onClick={() =>
                  window.location.replace(
                    `${REACT_APP_API_URL}/auth/google/login`,
                  )
                }
              >
                Ingresar con Google
              </Button>
            )}

          <NewAccountContainer>
            {modeView === VIEWS_LOGIN.LOGIN && (
              <>
                <Text color="black" size="14px" padding="0px 5px">
                  ¿No tenés cuenta?
                </Text>
                <Link
                  style={{ fontSize: "14px" }}
                  block
                  color="primary"
                  href="#"
                  onClick={() => setModeView(VIEWS_LOGIN.REGISTER)}
                >
                  Registrate
                </Link>
              </>
            )}
            {(modeView === VIEWS_LOGIN.REGISTER ||
              modeView === VIEWS_LOGIN.RENEW_PASSWORD) && (
                <Link
                  style={{ fontSize: "12px", marginTop: "10px" }}
                  block
                  color="primary"
                  href="#"
                  onClick={() => setModeView(VIEWS_LOGIN.LOGIN)}
                >
                  Ya tengo cuenta
                </Link>
              )}

            {modeView === VIEWS_LOGIN.NOTIFICATION_EMAIL && (
              <Link
                style={{ fontSize: "12px", marginTop: "10px" }}
                block
                color="primary"
                href="#"
                onClick={() => setModeView(VIEWS_LOGIN.LOGIN)}
              >
                Ingresar
              </Link>
            )}
          </NewAccountContainer>
        </FormSection>
      </CenterContent>
    </LoginContainer>
  );
}

export default Login;
