import { CardUserProfile } from "./styled-components/CardUserProfile";
import { Button, Input } from "@nextui-org/react";
import React, { useState } from "react";
import ButtonsViewContainer from "./styled-components/ButtonViewContainer";
import ProfileContainer from "./styled-components/ProfileContainer";
import updateUser from "@services/users/updateUser";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";
import useDevice from "@utils/useDevice";
import InputsContainer from "./styled-components/InputsContainer";
import updateProfile from "@services/users/updateProfile";
import WebProfile from "./components/WebProfile";
import getUser from "@services/auth/getUser";
import { useUser } from "@context/UserProvider";

function Profile({ userLogged }) {
    const [updatedUser, setUpdatedUser] = useState(userLogged);
    const { fetchUserLogged, setUser } = useUser();
    const { isMobile } = useDevice();

    const { mutate: fetchUserProfile, isLoading: isLoadingUserProfile } =
        useMutation(updateProfile, {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    enqueueSnackbar("Perfil actualizado", {
                        variant: "success",
                        persist: false,
                    });
                    setUser(data.data);
                    fetchUserLogged();
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
            },
            onError: (error) => {
                console.log(error.request.response);
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });

    return (
        <>
            {!isMobile &&
                <WebProfile
                    userLogged={userLogged}
                    updatedUser={updatedUser}
                    setUpdatedUser={setUpdatedUser}
                    fetchUserProfile={fetchUserProfile}
                    isLoadingUserProfile={isLoadingUserProfile}
                />
            }
            {isMobile &&
                <div className="flex w-full h-full overflow-x-hidden">
                    <ProfileContainer>
                        <CardUserProfile
                            name={userLogged?.name}
                            lastName={userLogged?.lastName}
                            email={userLogged?.email}
                        ></CardUserProfile>
                        <InputsContainer isMobile={isMobile}>
                            <div className="py-2 w-full">
                                <Input
                                    label="Nombre"
                                    type="text"
                                    value={updatedUser.name}
                                    onChange={({ target }) => {
                                        setUpdatedUser({
                                            ...updatedUser,
                                            name: target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="py-2 w-full">
                                <Input
                                    label="Apellido"
                                    type="text"
                                    value={updatedUser.lastName}
                                    onChange={({ target }) => {
                                        setUpdatedUser({
                                            ...updatedUser,
                                            lastName: target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="py-2 w-full">
                                <Input
                                    label="Dirección"
                                    type="text"
                                    value={updatedUser.address}
                                    onChange={({ target }) => {
                                        setUpdatedUser({
                                            ...updatedUser,
                                            address: target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="py-2 w-full">
                                <Input
                                    label="Departamento"
                                    type="text"
                                    value={updatedUser.department}
                                    onChange={({ target }) => {
                                        setUpdatedUser({
                                            ...updatedUser,
                                            department: target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="py-2 w-full">
                                <Input
                                    label="Ciudad"
                                    type="text"
                                    value={updatedUser.locality}
                                    onChange={({ target }) => {
                                        setUpdatedUser({
                                            ...updatedUser,
                                            locality: target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="py-2 w-full">
                                <Input
                                    label="Teléfono"
                                    type="text"
                                    value={updatedUser.phone}
                                    onChange={({ target }) => {
                                        setUpdatedUser({
                                            ...updatedUser,
                                            phone: target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="py-2 w-full">
                                <Input
                                    label="Iglesia"
                                    type="text"
                                    value={updatedUser.church}
                                    onChange={({ target }) => {
                                        setUpdatedUser({
                                            ...updatedUser,
                                            church: target.value,
                                        });
                                    }}
                                />
                            </div>
                        </InputsContainer>
                        <ButtonsViewContainer isMobile={isMobile}>
                            <Button
                                onClick={() => fetchUserProfile(updatedUser)}
                                isLoading={isLoadingUserProfile}
                                style={{
                                    background: "linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%)",
                                    color: "white"
                                }}
                            >
                                Guardar
                            </Button>
                        </ButtonsViewContainer>
                    </ProfileContainer>
                </div>

            }

        </>

    );
}

export default Profile;
