import React, { useEffect, useState } from "react";
import ContentContainer from "./styled-components/ContentContainer";
import HeaderContent from "./components/HeaderContent/HeaderContent";
import TitleContent from "./styled-components/TitleContent";
import ItemCollapse from "./components/ItemCollapse";
import ItemsContainer from "./styled-components/ItemsContainer";
import VideoModal from "./components/VideoModal";
import { Button } from "@nextui-org/react";
import ContentHeader from "./styled-components/ContentHeader";
import getEnrolmentsContent from "@services/enrolments/getEnrolmentsContent";
import { useMutation } from "react-query";
import noContent from "@assets/no-content.png";
import queryString from "query-string";
import { useLocation } from "react-router";
import { enqueueSnackbar } from "notistack";
import NoResults from "@components/NoResults";
import { SiteLoader } from "@components/SiteLoader/SiteLoader";
import ButtonEditContainer from "./styled-components/ButtonEditContainer";
import NewModuleModal from "./components/NewModuleModal";
import BackButtonIcon from "@assets/back-button.png";
import BackButton from "./styled-components/BackButton";
import ROUTES from "../../components/Router/routes";
import { useNavigate } from "react-router-dom";
import isEmptyObject from "../../helpers/isEmptyObject";

const Content = ({ userCanUpdateContent }) => {
  const navigateTo = useNavigate();
  const location = useLocation();
  const [showVideo, setShowVideo] = useState(false);
  const [videoToShow, setVideoToShow] = useState(false);
  const [content, setContent] = useState();
  const [modeEdit, setModeEdit] = useState(false);
  const [viewNewModule, setViewNewModule] = useState(false);
  const [switchViewLoading, setSwitchViewLoading] = useState(false);
  const [enrolmentId, setEnrolmentId] = useState();

  const contentId = queryString.parse(location.search);
  const { e } = contentId;

  const { mutate: fetchContent, isLoading: isLoadingContent } = useMutation(
    getEnrolmentsContent,
    {
      onSuccess: (data) => {
        if (!isEmptyObject(data) && data.status === 200) {
          setContent(data.data.data);
        } else {
          navigateTo(ROUTES.HOME);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );

  useEffect(() => {
    if (e) {
      fetchContent(e);
      setEnrolmentId(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShowVideo = (video) => {
    setShowVideo(true);
    setVideoToShow(video);
  };
  const closeShowVideo = () => {
    setShowVideo(false);
  };
  const closeNewModuleModal = () => {
    setViewNewModule(false);
  };

  const goToEditContent = () => {
    setSwitchViewLoading(true);

    setModeEdit(!modeEdit);

    setTimeout(() => {
      setSwitchViewLoading(false);
    }, 300);
  };

  const onUpdateModules = (modulesUpdate) => {
    setContent({
      ...content,
      modules: modulesUpdate,
    });
  };

  const onUpdateModule = (module) => {
    const index = content.modules.findIndex((item) => item.id === module.id);
    if (index !== -1) {
      const modulesUpdated = [...content.modules];
      modulesUpdated[index] = module;

      setContent({
        ...content,
        modules: modulesUpdated,
      });
    }
  };

  const onUpdateModuleDeleted = (module) => {
    const modulesUpdated = content.modules.filter(
      (item) => item.id !== module.id,
    );
    setContent({
      ...content,
      modules: modulesUpdated,
    });
  };

  const handleActionClick = (actionId) => {
    if (actionId === "newModule") setViewNewModule(true);
  };

  useEffect(() => {
    console.log(content);
  }, [content]);

  if (isLoadingContent || switchViewLoading) return <SiteLoader />;

  return (
    <>
      <ContentContainer className="md:px-11 md:py-4">
        <NewModuleModal
          visible={viewNewModule}
          subject={content?.subjectId}
          updateModules={onUpdateModules}
          onCloseModal={closeNewModuleModal}
        />
        <VideoModal
          video={videoToShow}
          visible={showVideo}
          closeHandler={closeShowVideo}
          enrolmentId={enrolmentId}
        ></VideoModal>
        <ContentHeader>
          <BackButton>
            <Button
              color="default"
              startContent={<img width="20px" src={BackButtonIcon} />}
              onClick={() => navigateTo(ROUTES.HOME)}
            >
              Volver
            </Button>
          </BackButton>
          <HeaderContent data={content}></HeaderContent>
        </ContentHeader>
        <div className="md:p-11 p-2">
          {content?.modules.length > 0 && (
            <TitleContent> Contenido </TitleContent>
          )}
          {userCanUpdateContent && (
            <ButtonEditContainer noContent={content?.modules.length === 0}>
              <Button
                style={{
                  background:
                    "linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%)",
                  color: "white",
                }}
                onClick={() => goToEditContent()}
              >
                {!modeEdit ? "Editar contenido" : "Terminar Edición"}
              </Button>
            </ButtonEditContainer>
          )}
          {content?.modules.length === 0 && (
            <NoResults message="No hay contenido disponible" icon={noContent} />
          )}
          {content?.modules.length > 0 && (
            <>
              {content?.modules?.map((course, index) => (
                <ItemsContainer>
                  <ItemCollapse
                    item={course}
                    onShowVideo={onShowVideo}
                    modeEdit={modeEdit}
                    onUpdateModule={onUpdateModule}
                    userCanUpdateContent={userCanUpdateContent}
                    subjectId={content?.subjectId}
                    onUpdateModuleDeleted={onUpdateModuleDeleted}
                    enrolment={enrolmentId}
                    isOpenModal={showVideo}
                  ></ItemCollapse>
                </ItemsContainer>
              ))}
            </>
          )}
          {modeEdit && (
            <div>
              <Button
                onClick={() => handleActionClick("newModule")}
                bordered
                color="warning"
                fullWidth
              >
                Nuevo módulo
              </Button>
            </div>
          )}
        </div>
      </ContentContainer>
    </>
  );
};

export default Content;
