import styled from "styled-components";

const DescriptionCertificate = styled.div`
  font-weight: 500;
  font-size: 0.8em;
  text-align: center;
  padding-top: 50px;
`;

export default DescriptionCertificate;
