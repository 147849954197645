import React, { useEffect, useState } from "react";

import { Routes, Route, BrowserRouter as Router, Navigate, useNavigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ROUTES from "./routes";
import Home from "@pages/Home";
import Courses from "@pages/Courses";
import NavBar from "../NavBar";
import { useUser } from "@context/UserProvider";
import useRole from "@hooks/useRole";
import Login from "@pages/Login";
import Users from "@pages/Users";
import Profile from "@pages/Profile";
import useDevice from "@utils/useDevice";
import Content from "@pages/Content";
import Evaluation from "@pages/Evaluation";
import StartEvaluation from "@pages/Evaluation/components/StartEvaluation";
import EnrolmentGestion from "@pages/EnrolmentGestion";
import CouponsGestion from "@pages/CouponsGestion";
import SendCalification from "@pages/Evaluation/components/SendCalification";
import ViewEvaluation from "@pages/Evaluation/components/ViewEvaluation";
import PaymentPlan from "@pages/PaymentPlan";
import Scholarship from "@pages/Scholarship";
import styled from "styled-components";
import RecoveryPassword from "@pages/RecoveryPassword";
import ConfirmProfile from "../../pages/Users/components/ConfirmProfile";
import Checkout from "@pages/PaymentPlan/components/Checkout";
import ContactButton from "./ContactButton";
import TokenExpired from "@pages/TokenExpired";

const RouterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
`;

function AppRouter() {
  const { isUserLogged, isUserFetched, user, isRecoveryPath, needUpdateProfile, isLinkExpired } = useUser();
  const { pathname } = window.location;
  const [pathActive, setPathActive] = useState(pathname);
  const { userCanUpdateUsers, userCanUpdateContent, userIsProfessor } =
    useRole();
  const { isMobile } = useDevice();

  return (
    <Router>
      <RouterContainer isMobile={isMobile}>
        {isUserLogged && !isRecoveryPath && needUpdateProfile && !isLinkExpired && (
          <Navigate
            to={ROUTES.CONFIRM_PROFILE}
          />
        )}
        {isUserLogged && !isRecoveryPath && !needUpdateProfile && !isLinkExpired && (
          <NavBar
            userLogged={user}
            isMobile={isMobile}
            pathActive={pathActive}
            setPathActive={setPathActive}
          />
        )}
        {/* {isUserLogged && !isRecoveryPath && !needUpdateProfile && !isLinkExpired && (
          <ContactButton />
        )} */}
        <Routes>
          {isUserFetched && !isUserLogged && (
            <Route path={ROUTES.LOGIN} exact element={<Login />} />
          )}
          {!isUserLogged &&
            <Route
              path={ROUTES.EXPIRED}
              exact
              element={
                <TokenExpired />
              }
            />
          }
          <Route
            path={ROUTES.RECOVERY}
            exact
            element={
              <RecoveryPassword isUserLogged={isUserLogged} userLogged={user} />
            }
          />
          {needUpdateProfile && (
            <Route
              path={ROUTES.CONFIRM_PROFILE}
              exact
              element={
                <PrivateRoute>
                  <ConfirmProfile userLogged={user} />
                </PrivateRoute>
              }
            />
          )}
          <Route
            path={ROUTES.HOME}
            exact
            element={
              <PrivateRoute>
                <Home userLogged={user} />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CONTENT}
            exact
            element={
              <PrivateRoute>
                <Content userCanUpdateContent={userCanUpdateContent} />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.COURSES}
            exact
            element={
              <PrivateRoute>
                <Courses userLogged={user} />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CHECKOUT}
            exact
            element={
              <PrivateRoute>
                <Checkout />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SCHOLARSHIP}
            exact
            element={
              <PrivateRoute>
                <Scholarship userLogged={user} />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.PROFILE}
            exact
            element={
              <PrivateRoute>
                <Profile userLogged={user} />
              </PrivateRoute>
            }
          />
          {userCanUpdateUsers && (
            <Route
              path={ROUTES.USERS}
              exact
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
          )}

          <Route
            path={ROUTES.ADD_EVALUATION}
            exact
            element={
              <PrivateRoute>
                <Evaluation />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.TEST}
            exact
            element={
              <PrivateRoute>
                <StartEvaluation />
              </PrivateRoute>
            }
          />

          {(userIsProfessor || userCanUpdateContent) && (
            <Route
              path={ROUTES.GESTION_PROFESSOR}
              exact
              element={
                <PrivateRoute>
                  <EnrolmentGestion
                    userLogged={user}
                    setPathActive={setPathActive}
                  />
                </PrivateRoute>
              }
            />
          )}

          <Route
            path={ROUTES.GESTION_COUPONS}
            exact
            element={
              <PrivateRoute>
                <CouponsGestion
                  userLogged={user}
                  setPathActive={setPathActive}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.CALIFICATION}
            exact
            element={
              <PrivateRoute>
                <SendCalification />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.VIEW_EVALUATION}
            exact
            element={
              <PrivateRoute>
                <ViewEvaluation />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.PAYMENT_PLAN}
            exact
            element={
              <PrivateRoute>
                <PaymentPlan />
              </PrivateRoute>
            }
          />

          <Route
            path="*"
            element={
              <PrivateRoute defaultRoute>
                <Home userLogged={user} />
              </PrivateRoute>
            }
          />
        </Routes>
      </RouterContainer>
    </Router>
  );
}

export default AppRouter;
