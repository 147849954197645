import styled from "styled-components";

const Icon = styled.img`
  width: ${({width}) => width || "auto"};
  height: ${({height}) => height || "auto"};
  margin-right: ${({rigth}) => rigth || " 5px"};
  margin-left: ${({left}) => left || "0px"};
`;

export default Icon;
