const breakpoints = ['0px', '640px', '960px', '1280px', '1600px'];

const bkp = {
  xs: breakpoints[0],
  sm: breakpoints[1],
  md: breakpoints[2],
  lg: breakpoints[3],
  xl: breakpoints[4],
};

const theme = {
  colors: {
    brand: {
      green: 'rgba(38, 60, 58, 1)',
    },
  },
  bkp,
  spaces: {
    none: '0px',
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '20px',
  },
  sizes: {
    main: {
      xs: '12px',
      sm: '16px',
      md: '20px',
      lg: '24px',
      xl: '28px',
    },
    radius: {
      xs: '2px',
      sm: '4px',
      md: '6px',
      lg: '8px',
      xl: '10px',
    },
    component: {
      small: '24px',
      medium: '32px',
      large: '40px',
    },
  },
  shadows: {
    main: '0 2px 6px 0 rgba(39, 50, 57, 0.15)',
    card: '0px 2px 6px rgba(0, 0, 0, 0.2)',
  },
  typography: {
    fontFamilies: {
      inter: 'Inter',
    },
    fontSizes: {
      xs: '8px',
      sm: '10px',
      md: '12px',
      lg: '14px',
      xl: '16px',
      xxl: '18px',
    },
  },
};

export default theme;
