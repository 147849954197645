import { React, useState } from "react";
import { BereaContext } from "./context/bereaContext";
import { ThemeProvider } from "styled-components";
import GlobalStyleSheet from "./styles";
import { QueryClient, QueryClientProvider } from "react-query";
import theme from "./theme";
import AppRouter from "./components/Router";
import { UserProvider } from "./context/UserProvider";
import { SnackbarProvider } from "notistack";
import { NextUIProvider } from "@nextui-org/react";
import { initMercadoPago } from "@mercadopago/sdk-react";
import "./newStyles.css";

const { REACT_APP_MP_KEY } = process.env;

initMercadoPago(REACT_APP_MP_KEY, {
  locale: "es-UY",
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [contentData, setContentData] = useState({});

  return (
    <BereaContext.Provider value={contentData}>
      <ThemeProvider theme={theme}>
        <NextUIProvider>
          <SnackbarProvider
            maxSnack={2}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <QueryClientProvider client={queryClient}>
              <UserProvider>
                <GlobalStyleSheet />
                <AppRouter />
              </UserProvider>
            </QueryClientProvider>
          </SnackbarProvider>
        </NextUIProvider>
      </ThemeProvider>
    </BereaContext.Provider>
  );
}

export default App;
