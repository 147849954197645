import React from "react";
import {Button, Grid, Row} from "@nextui-org/react";
import styled from "styled-components";

export const DeleteModule = ({deleteModule}) => {
    return (
        <div className="flex w-full p-2">
            <div className="p-1">
                <Button size="sm" shadow color="danger" onPress={() => deleteModule()}>
                    Si, Eliminar
                </Button>
            </div>
        </div>
    );
};
