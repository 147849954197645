import styled from "styled-components";

const ScoreTitle = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 0.8em;
    padding: 5px;
    height: 100%;
    background: linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%);
    border-radius: 15px;
    width: 40px;
    justify-content: center;
    margin-bottom: 20px;
`;

export default ScoreTitle;
