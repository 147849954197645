import styled from "styled-components";

const TextStyled = styled.div`
  margin: 0px;
  padding: 10px 0px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  color: white;
  text-wrap: nowrap;
`;

export default TextStyled;
