import styled from "styled-components";

const DetailItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: rgba(255, 255, 255, 0.22);
    border-radius: 14.1454px;
  }
`;

export default DetailItemContainer;
