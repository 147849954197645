import axios from "../axios";
import {sendEvaluationResultURL} from "./URLs";

const doSendEvaluationResult = async ({evaluationId, enrolmentId, answers}) => {
    try {
        const response = await axios().post(sendEvaluationResultURL, {
            evaluation: evaluationId,
            enrolment: enrolmentId,
            answers: answers
        });
        return response.data || {};
    } catch (error) {
        return error.response;
    }
};

export default doSendEvaluationResult;
