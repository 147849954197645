import React, { useState } from "react";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { SiteLoader } from "@components/SiteLoader/SiteLoader";
import UsersIcon from "@assets/icons/users.svg"
import deleteResultByResult from "@services/evaluations/deleteResultByResult";
import TableStudentsEvaluations from "../TableStudentsEvaluations";
import TableUsersEnrolments from "../TableUsersEnrolments";
import CardDataStats from "../CardDataStats";
import TabStatusSubject from "./styled-components/TabStatusSubject";
import HeaderDashboard from "../CardDataStats/components/HeaderDashboard";
import { DASHBOARD_TABS } from "./tabs";

function EnrolmentDashboard({
    enrolmentSelected,
    evaluations,
    students,
    isLoadingStudents,
    isLoadingResultsEvaluations,
    onCleanEnrolmentSelected
}) {
    const navigate = useNavigate();
    const [showDeleteEvaluationModal, setShowDeleteEvaluationModal] = React.useState(false);
    const [studentsList, setStudentsList] = useState(students);
    const [activeTab, setActiveTab] = useState(DASHBOARD_TABS.STUDENTS_TAB);

    const { mutate: fetchDeleteEvaluation, isLoading: isLoadingDeleteResult } =
        useMutation(deleteResultByResult, {
            onSuccess: (data) => {
                if (data.status === 200) {
                    enqueueSnackbar("Resultado eliminado", {
                        variant: "success",
                        persist: false,
                    });
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
                setShowDeleteEvaluationModal(false);
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });


    const goToCalification = ({ result, enrolment }) => {
        // navigateTo("/content");
        navigate({
            pathname: "/calification",
            search: `?e=${enrolment}&result=${result}`,
        });
    };

    const onSelectTab = (tab) => {
        setActiveTab(tab);
    };

    const onFilterStudents = (target) => {
        setStudentsList(
            students.filter((st) =>
                st.name.includes(target) ||
                st.lastName.includes(target) ||
                st.email.includes(target)
            )
        );
    };

    if (isLoadingStudents || isLoadingResultsEvaluations) return <SiteLoader />;

    return (
        <div className="flex flex-col w-full">
            <HeaderDashboard
                enrolmentSelected={enrolmentSelected}
                onCleanEnrolmentSelected={onCleanEnrolmentSelected}
                onSearchInput={onFilterStudents}
                onSelectTab={onSelectTab}
            />

            {activeTab === DASHBOARD_TABS.STUDENTS_TAB &&
                <>
                    <div className="md:px-12 px-4 pb-10">
                        <div className="pb-8">
                            <CardDataStats
                                title="Total inscriptos"
                                total={students?.length || 0}
                                icon={UsersIcon}
                            />
                        </div>
                        <TableUsersEnrolments users={studentsList} />
                    </div>
                </>
            }
            {activeTab === DASHBOARD_TABS.EVALUATIONS_TAB &&
                <div className="mt-4 px-6">
                    <TabStatusSubject>
                        Evaluaciones realizadas por estudiantes:{" "}
                    </TabStatusSubject>
                    <div className="p-4 bg-white/95">
                        <TableStudentsEvaluations
                            evaluations={evaluations}
                            goToCalification={goToCalification}
                            handleDeleteEvaluation={fetchDeleteEvaluation}
                            loadingDeleteEvaluation={isLoadingDeleteResult}
                            showDeleteEvaluationModal={showDeleteEvaluationModal}
                            setShowDeleteEvaluationModal={setShowDeleteEvaluationModal}
                        />
                    </div>
                </div>
            }


        </div>
    );
}

export default EnrolmentDashboard;
