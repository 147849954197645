import styled from "styled-components";

const SubTitle = styled.div`
  font-weight: 500;
  width: 100%;
  color: ${({secondaryColor}) => (secondaryColor && "black")}; 
  font-size: ${({width}) => (width || "0.9em")}; 
`;

export default SubTitle;
