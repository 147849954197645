import styled from "styled-components";

const HeaderHomeCourses = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom-width: 2px;
  border-color: #394156;
  padding: 20px;
  background: #1d2e30;
`;

export default HeaderHomeCourses;
