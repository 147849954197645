import { deleteCouponURL } from "./URLs";
import axios from "../axios";
import format from "../format";

const deleteCoupon = async (idCoupon) => {
  try {
    const URL = format(deleteCouponURL, idCoupon);
    const response = await axios().delete(URL);
    return response.data || {};
  } catch (error) {
    return error.response;
  }
};

export default deleteCoupon;
