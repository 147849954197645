import axios from "../axios";
import { getCoursesURL } from "./URLs";

const getCourses = async () => {
  try {
    const response = await axios().get(getCoursesURL);
    return response.data || {};
  } catch {
    return {};
  }
};

export default getCourses;
