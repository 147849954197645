import { deletePresentationURL } from "./URLs";
import format from "../format";
import axios from "../axios";

const doDeletePresentation = async ({ item, subjectModule }) => {
  try {
    const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;
    const URL = format(BASE_URL + deletePresentationURL, item, subjectModule);
    const response = await axios().delete(URL);
    return response.data || {};
  } catch {
    return {};
  }
};

export default doDeletePresentation;
