import styled from "styled-components";
import { devices } from "@utils/devices";

const TitleContent = styled.div`
  font-weight: 600;
  font-size: 1.7em;
  width: 100%;
  padding: 40px 0px;
  text-align: center;
  @media only screen and ${devices.md} {
    text-align: left;
  }
`;

export default TitleContent;
