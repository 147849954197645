import React from "react";

const columns = [
  { name: "CODE", uid: "code", sortable: true },
  { name: "TIPO DE DESC.", uid: "discountType", sortable: true },
  { name: "DESCUENTO", uid: "discount", sortable: true },
  { name: "ACCIONES", uid: "actions" },
];

const statusOptions = [
  { name: "Active", uid: "active" },
  { name: "Paused", uid: "paused" },
  { name: "Vacation", uid: "vacation" },
];

export { columns, statusOptions };
