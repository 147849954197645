import React from "react";
import {
  Modal,
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  Dropdown,
  ModalFooter,
  DropdownItem,
} from "@nextui-org/react";
import { DropdownMenu, DropdownTrigger } from "@nextui-org/dropdown";

const InscriptionDuplicateModal = ({
  visible,
  onCloseModal,
  isLoading,
  fetchStudents,
  enrolmentFilter,
}) => {
  const [selectedKeys, setSelectedKeys] = React.useState(
    new Set(["Seleccionar materia"])
  );
  const [enrolmentIdSelected, setEnrolmentIdSelected] = React.useState(
    new Set(["Seleccionar materia"])
  );

  const selectedValue = React.useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );

  return (
    <Modal
      backdrop="blur"
      isOpen={visible}
      isDismissable={false}
      onClose={onCloseModal}
      size="3xl"
      style={{ color: "black" }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex text-center">
              <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </ModalHeader>
            :
            <ModalBody>
              <h3 class="mb-5 text-lg font-bold dark:text-gray-400 text-center">Ya estas inscripto a este curso</h3>
              <div
                className="flex justify-center">
                <Button
                  onClick={() => onClose()}
                  isLoading={isLoading}
                  color="secondary"
                >
                  Cerrar
                </Button>
              </div>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default InscriptionDuplicateModal;
