function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
}

const date = new Date('2022-05-15T00:00:00.000Z');

const newDate = addDays(date, 5);

// 2022-05-20T00:00:00.000Z
console.log(newDate);

export default function formatDateHelper(inputDate, daysToAdd) {

    let dateAux = inputDate;

    if (daysToAdd) {
        dateAux = addDays(inputDate, daysToAdd)
    }

    let date, month, year;
    date = dateAux.getDate();
    month = dateAux.getMonth() + 1;
    year = dateAux.getFullYear();

    if (date < 10) {
        date = '0' + date;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return `${date}/${month}/${year}`
}
