import React, { useEffect, useState } from "react";
import ArrorLeft from "@assets/icons/arrowLeft.svg"
import LocationIcon from "@assets/icons/locationIcon.svg"
import userCheck from "@assets/icons/personCheck.svg"
import { Button, Chip } from "@nextui-org/react";
import PaymentInformation from "../PaymentInformation/PaymentInformation";
import DiscountModal from "../DiscountModal";


const Checkout = ({
    preference,
    backButtton,
    descriptionItemTwo,
    descriptionItemTwoPrice,
    descriptionItemThree,
    descriptionItemThreePrice,
    descriptionItemFourPrice,
    course,
    onPrimaryActionModal,
    onSecondaryActionModal,
    onTertiaryActionModal,
    isLoadingPrimaryActionModal,
    isLoadingSecondaryActionModal,
    isLoadingTertiaryActionModal,
    isTotalDiscount,
    couponApplied,
    userInfo
}) => {
    const [showDiscountModal, setShowDiscountModal] = useState(false);

    const inscriptionFree = !preference?.url && descriptionItemTwoPrice === 0;

    useEffect(() => {
        if (couponApplied) {
            setShowDiscountModal(false)
        }
    }, [couponApplied])


    return (
        <div className="flex flex-col w-full h-full overflow-scroll" style={{ backgroundColor: "#202830" }}>
            <DiscountModal
                visible={showDiscountModal}
                closeHandler={() => setShowDiscountModal(false)}
                preference={preference}
                onSecondaryActionModal={onSecondaryActionModal}
                isLoadingSecondaryActionModal={isLoadingSecondaryActionModal}

            />
            <div className="flex items-center h-14 w-full pt-4 pl-3">
                <img onClick={backButtton} src={ArrorLeft} alt="arrowleft" width={40} className="cursor-pointer mr-2"></img>
                <div className="w-full">
                    <div className="text-3xl font-bold"> Inscripción </div>
                </div>
                <div className="flex">
                    {!!preference?.url &&
                        <div className="flex flex-col md:flex-row">
                            <div className="flex justify-center text-sm items-center mt-3">
                            </div>
                            {!isTotalDiscount &&
                                <div className="flex items-center w-full">
                                    {/* <div className="md:flex md:mt-4 md:mr-4 md:text-2xl md:font-bold">Paso 2: </div> */}
                                    <Button
                                        color="primary"
                                        auto
                                        onClick={() => window.location.href = preference?.url}
                                        className="mt-4 mr-4 flex items-center justify-center content-center"
                                    >
                                        Ir a Pagar
                                    </Button>
                                </div>
                            }
                            {isTotalDiscount &&
                                <Button
                                    color="success"
                                    auto
                                    className="mt-4 mr-4 flex text-white items-center justify-center content-center"
                                    onClick={onPrimaryActionModal}
                                    isDisabled={isLoadingPrimaryActionModal}
                                    isLoading={isLoadingPrimaryActionModal}
                                >
                                    Confirmar
                                </Button>}
                        </div>
                    }
                </div>
            </div>
            <div className="border-b-1 border-white w-full mt-8"></div>

            <div className="flex-none md:flex md:items-center md:justify-center text-2xl font-bold my-2 text-center pt-4">Resumen</div>

            <div className="flex flex-col h-full">
                <div className="w-full flex flex-col items-start justify-start content-center pt-6 md:px-10">
                    <div className="w-full text-black p-3 rounded-lg shadow-sm bg-sky-800" style={{ color: "white", backgroundColor: "#20272e", boxShadow: "rgba(0, 0, 0, 0.75) 2px -1px 20px -10px" }} >
                        <div className="flex justify-between">
                            <div className="text-1xl pb-4"> Detalle del pago </div>
                            {!couponApplied && !inscriptionFree &&
                                <div className="text-md text-blue-500 pb-4 cursor-pointer text-ellipsis" onClick={() => setShowDiscountModal(true)}>
                                    Tengo código de descuento
                                </div>
                            }
                            {!!couponApplied &&
                                <div className="text-md text-green-600 pb-4">
                                    Cupón aplicado
                                </div>
                            }
                        </div>
                        <div className="border-b-1 border-white opacity-25 w-full mb-4"></div>
                        <div className="flex items-center justify-between">
                            <div className="text-sm my-1">{descriptionItemTwo}</div>
                            <div className={isTotalDiscount ? "text-md line-through" : "text-md"}>
                                ${descriptionItemTwoPrice}
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="text-sm my-1">{descriptionItemThree}</div>
                            <div className={isTotalDiscount ? "text-md line-through" : "text-md"}>
                                ${descriptionItemThreePrice}
                            </div>
                        </div>
                        <div className="border-b-1 border-white opacity-25 w-full mt-6"></div>

                        <div className="flex items-center justify-between pt-4">
                            <div className="text-2xl font-bold my-2">Total</div>
                            {isTotalDiscount && <Chip color="success">Gratis</Chip>}
                            {!isTotalDiscount && <div className="text-2xl font-bold">${descriptionItemFourPrice}</div>}
                        </div>
                    </div>

                    <div className="flex-none pt-8 md:flex md:items-start md:flex-col md:justify-start">
                        <div className="flex">
                            <div className="text-1xl my-2 text-center md:pt-4 pr-2">
                                <div className="flex">
                                    <img src={userCheck} alt="location" className="mr-2" />
                                    <div>Nombre:</div>
                                </div>
                            </div>
                            <div className="text-1xl font-bold my-2 text-center md:pt-4">
                                <div className="flex">
                                    {userInfo?.name} {userInfo?.lastName}
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="text-1xl my-2 text-center md:pt-4 pr-2">
                                <div className="flex">
                                    <img src={LocationIcon} alt="location" className="mr-2" />
                                    <div >Entrega de manuales:</div>
                                </div>
                            </div>
                            <div className="text-1xl font-bold my-2 text-center md:pt-4">
                                <div className="flex">
                                    {userInfo?.address}
                                </div>
                            </div>
                        </div>


                    </div>

                    {!inscriptionFree &&
                        <div className="w-full flex flex-col justify-center">
                            <PaymentInformation inscriptionMode />
                        </div>
                    }

                    <div className="flex items-center justify-center content-center w-full">
                        {inscriptionFree &&
                            <Button
                                color="success"
                                auto
                                className="mt-4 mr-4 flex text-white items-center justify-center content-center"
                                onClick={onTertiaryActionModal}
                                isDisabled={isLoadingTertiaryActionModal}
                                isLoading={isLoadingTertiaryActionModal}
                            >
                                Confirmar
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Checkout;
