import React, { useEffect } from "react";
import EnrolmentGestionContainer from "./styled-components/EnrolmentGestionContainer";
import EnrolmentsActiveContainer from "./styled-components/EnrolmentsActiveContainer";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { SiteLoader } from "@components/SiteLoader/SiteLoader";
import getProgressStudentsByEnrolment from "@services/enrolments/getProgressStudentsByEnrolment";
import getEnrolmentsActives from "@services/enrolments/getEnrolmentsActives";
import getResultsByEnrolment from "../../services/evaluations/getResultsByEnrolment";
import EnrolmentDashboard from "./components/EnrolmentDashboard";
import SelectEnrolmentsList from "./components/SelectEnrolmentsList";

function EnrolmentGestion({ userLogged, setPathActive }) {
    const [enrolmentIsSelected, setEnrolmentIsSelected] = React.useState();
    const [students, setStudents] = React.useState([]);
    const [evaluations, setEvaluations] = React.useState([]);
    const [enrolmentFilter, setEnrolmentFilter] = React.useState(0);
    const [subjectName, setSubjectName] = React.useState();


    const { mutate: doGetEnrolmentsActives, isLoading: isLoadingEnrolments } =
        useMutation(getEnrolmentsActives, {
            onSuccess: ({ data }) => {
                setEnrolmentFilter(data);
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });

    const { mutate: fetchResults, isLoading: isLoadingResultsEvaluations } =
        useMutation(getResultsByEnrolment, {
            onSuccess: ({ data }) => {
                setEvaluations(data);
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });

    const {
        mutate: fetchProgressStudentsEnrolment,
        isLoading: isLoadingStudents,
    } = useMutation(getProgressStudentsByEnrolment, {
        onSuccess: (data) => {
            setStudents(data.data);
            setSubjectName(data.subject);
            setEnrolmentIsSelected(true);
        },
        onError: (error) => {
            enqueueSnackbar(error.response.data.message, {
                variant: "error",
                persist: false,
            });
        },
    });

    const fetchEnrolment = (enrolment) => {
        if (!!enrolment) {
            fetchProgressStudentsEnrolment({ enrolment: enrolment });
            fetchResults({ enrolment: enrolment });
        }
    };

    useEffect(() => {
        doGetEnrolmentsActives();
    }, []);

    // if (isLoadingEnrolments) return <SiteLoader />;

    return (
        <EnrolmentGestionContainer>
            <EnrolmentsActiveContainer>
                {!enrolmentIsSelected && (
                    <SelectEnrolmentsList
                        enrolments={enrolmentFilter}
                        fetchEnrolment={fetchEnrolment}
                        loading={isLoadingStudents || isLoadingResultsEvaluations}
                        loadingEnrolments={isLoadingEnrolments}
                    />
                )}
                {!!enrolmentIsSelected && (
                    <EnrolmentDashboard
                        enrolmentSelected={subjectName}
                        evaluations={evaluations}
                        isLoadingResultsEvaluations={isLoadingResultsEvaluations}
                        isLoadingStudents={isLoadingStudents}
                        students={students}
                        onCleanEnrolmentSelected={() => setEnrolmentIsSelected('')}
                    />
                )}
            </EnrolmentsActiveContainer>
        </EnrolmentGestionContainer >
    );
}

export default EnrolmentGestion;
