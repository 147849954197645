import styled from "styled-components";
import { Card } from "@nextui-org/react";
import { devices } from "@utils/devices";

const HeaderContainer = styled(Card)`
  max-width: 1000px;
  background-color: #27282d;
  color: white;
  justify-content: center;
  @media only screen and ${devices.md} {
    width: 100%;
  }
`;

export default HeaderContainer;
