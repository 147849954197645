import styled from "styled-components";

const CardStyledEvaluation = styled.div`
  display: flex;
  align-content: space-between;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 30px;
  margin: 20px;
  background-color: #292929;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: rgb(208 208 214 / 12%) 0px 7px 29px 0px;
`;

export default CardStyledEvaluation;
