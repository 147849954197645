import React from "react";
import { Button, Input } from "@nextui-org/react";
import WebProfileContainer from "./styled-components/WebProfileContainer";
import { CardUserProfile } from "../../styled-components/CardUserProfile";
import ButtonsViewContainer from "../../styled-components/ButtonViewContainer";
import InputsContainer from "../../styled-components/InputsContainer";
import useDevice from "@utils/useDevice";
import Title from "@components/Title/Title";

function WebProfile({
  userLogged,
  updatedUser,
  setUpdatedUser,
  fetchUserProfile,
  isLoadingUserProfile,
}) {
  const { isMobile } = useDevice();

  return (
    <WebProfileContainer>
      <div className="flex w-full">
        <div className="w-1/3 pt-32">
          <CardUserProfile
            name={userLogged?.name}
            lastName={userLogged?.lastName}
            email={userLogged?.email}
            role={userLogged?.role}
          ></CardUserProfile>
        </div>

        <div
          className="flex flex-col justify-center items-center w-full h-screen pt-4 overflow-x-hidden overflow-y-scroll"
          style={{ backgroundColor: "#252d33" }}
        >
          <Title style={{ width: "50%", minWidth: "100px" }}>
            {" "}
            Datos del perfil:{" "}
          </Title>

          <div className="pt-10 pb-2 w-1/2">
            <Input
              label="Nombre"
              type="text"
              value={updatedUser.name}
              onChange={({ target }) => {
                setUpdatedUser({
                  ...updatedUser,
                  name: target.value,
                });
              }}
            />
          </div>
          <div className="py-2 w-1/2">
            <Input
              label="Apellido"
              type="text"
              value={updatedUser.lastName}
              onChange={({ target }) => {
                setUpdatedUser({
                  ...updatedUser,
                  lastName: target.value,
                });
              }}
            />
          </div>
          <div className="py-2 w-1/2">
            <Input
              label="Dirección"
              type="text"
              value={updatedUser.address}
              onChange={({ target }) => {
                setUpdatedUser({
                  ...updatedUser,
                  address: target.value,
                });
              }}
            />
          </div>
          <div className="py-2 w-1/2">
            <Input
              label="Departamento"
              type="text"
              value={updatedUser.department}
              onChange={({ target }) => {
                setUpdatedUser({
                  ...updatedUser,
                  department: target.value,
                });
              }}
            />
          </div>
          <div className="py-2 w-1/2">
            <Input
              label="Ciudad"
              type="text"
              value={updatedUser.locality}
              onChange={({ target }) => {
                setUpdatedUser({
                  ...updatedUser,
                  locality: target.value,
                });
              }}
            />
          </div>
          <div className="py-2 w-1/2">
            <Input
              label="Teléfono"
              type="text"
              value={updatedUser.phone}
              onChange={({ target }) => {
                setUpdatedUser({
                  ...updatedUser,
                  phone: target.value,
                });
              }}
            />
          </div>
          <div className="py-2 w-1/2">
            <Input
              label="Iglesia"
              type="text"
              value={updatedUser.church}
              onChange={({ target }) => {
                setUpdatedUser({
                  ...updatedUser,
                  church: target.value,
                });
              }}
            />
          </div>

          <ButtonsViewContainer>
            <Button
              onClick={() => fetchUserProfile(updatedUser)}
              isLoading={isLoadingUserProfile}
              style={{
                background:
                  "linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%)",
                color: "white",
                width: "200px",
              }}
            >
              Guardar
            </Button>
          </ButtonsViewContainer>
        </div>
      </div>
    </WebProfileContainer>
  );
}

export default WebProfile;
