import axios from "../axios";
import {sendInscriptionURL} from "./URLs";

const doInscription = async ({idCourse, cuponCode}) => {
    try {
        const response = await axios().post(sendInscriptionURL, {
            course: idCourse,
            discountCode: cuponCode
        });
        return response || {};
    } catch (error) {
        return error.response;
    }
};

export default doInscription;
