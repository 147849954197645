import React from "react";
import { Input, Modal, Button, ModalContent, ModalHeader, ModalBody, Spinner, ModalFooter } from "@nextui-org/react";
import TitleModal from "./styled-components/TitleModal";


const NewLiveEventModal = ({ visible, onCloseModal, sendLiveEvent, isLoading }) => {
    const [urlLive, setUrlLive] = React.useState();

    return (
        <Modal backdrop="blur" isOpen={visible} onClose={onCloseModal} size="3xl">
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1 items-center">
                            <TitleModal>Agregar clase en vivo</TitleModal>
                        </ModalHeader>
                        <ModalBody>
                            <Input
                                label="Link url"
                                type="text"
                                value={urlLive}
                                onChange={({ target }) => {
                                    setUrlLive(target.value);
                                }}
                                isDisabled={isLoading}
                            />
                            <Button css={{ width: "30px", marginTop: "20px" }}
                                onClick={() => sendLiveEvent({ url: urlLive })} isLoading={isLoading}
                                color="secondary">
                                Agregar
                            </Button>

                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default NewLiveEventModal;
