import React, { useEffect, useState } from "react";
import ContainerCard from "./styled-components/ContainerCard";
import Bullet from "@assets/bullet.svg";
import { Button, Chip } from "@nextui-org/react";
import formatDateHelper from "../../../helpers/formatDateHelper";

function CardCourses({
  course,
  onSendInscription,
  isLoading,
  setCourseSelected,
  showPaymentCourse,
  requestInscriptionLoading,
  mainCourse
}) {
  const [loadingCard, setLoadingCard] = React.useState(
    requestInscriptionLoading,
  );

  const handleSendInscription = (courseId) => {
    onSendInscription({ idCourse: courseId });
    setCourseSelected(course);
    setLoadingCard(true);
  };

  useEffect(() => {
    if (showPaymentCourse) {
      setLoadingCard(false);
    }
  }, [showPaymentCourse]);

  useEffect(() => {
    setLoadingCard(requestInscriptionLoading);
  }, [requestInscriptionLoading]);

  return (
    <ContainerCard secondaryColor={mainCourse}>
      <div className="flex w-full content-between h-full flex-wrap">
        <div>
          <div className="text-xl font-medium p-8 text-zinc-50">
            {course?.name}
          </div>

          {course?.dateStart && (
            <div className="pb-4 text-zinc-50">
              Comienzo: {formatDateHelper(new Date(course?.dateStart))}
            </div>
          )}

          <div className="flex p-4 text-sm">
            Incluye {course?.subjects?.length}
            {course?.subjects?.length === 1 ? " materia" : " materias"}:
          </div>

          <div
            className="flex-col m-4 items-center"
            style={{ minHeight: "70px" }}
          >
            {course?.subjects?.map((c) => (
              <div className="flex m-4 text-left">
                <img
                  src={Bullet}
                  width="5px"
                  alt="bullet"
                  style={{ margin: "0px 10px" }}
                />
                {c?.name}
              </div>
            ))}
          </div>

          {/*<div className="py-8 px-4">*/}
          {/*    Descripcion brevde del curso, detallando en breves palabras de que se trata el*/}
          {/*    contenido del mismo,*/}
          {/*    como es la dinamica, etc*/}
          {/*</div>*/}
        </div>

        <div className="flex flex-col justify-center w-full text-2xl font-semibold m-6">
          <div className="text-zinc-50">Costo: {course?.totalCourse !== 0 ? `$${course?.totalCourse}` : "Gratis"}</div>
          <div className="p-3 text-sm font-normal">
            {course?.totalCourse !== 0 &&
              <Chip
                color="warning"
                size="sm"
                variant="flat"
                style={{ width: "100%" }}
              >
                Pago en {course?.paymentPlan} cuotas
              </Chip>
            }
          </div>


          <Button
            style={{
              background:
                "linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%)",
              color: "white",
            }}
            onClick={() => handleSendInscription(course?.id)}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Inscribirme
          </Button>
        </div>
      </div>
    </ContainerCard>
  );
}

export default CardCourses;
