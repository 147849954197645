import styled from "styled-components";

const OptionsContainer = styled.div`
    background: white;
    padding: 10px;
    border-radius: 15px;
    font-family: Inter;
    margin-top: 10px;
    padding: 20px;
    & span {
        font-size: 0.9em;
    }
    & label {
        color: black !important;
    }
    & input {
        color: black !important;
    }
`;

export default OptionsContainer;
