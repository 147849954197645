import {getMakeEvaluationURL} from "./URLs";
import format from "../format";
import axios from "../axios";

const doGetMakeEvaluation = async (evaluation) => {
    try {
        const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;
        const URL = format(BASE_URL + getMakeEvaluationURL, evaluation);
        const response = await axios().get(URL);
        return response.data || {};
    } catch (error) {
        return error.response;
    }
};

export default doGetMakeEvaluation;
