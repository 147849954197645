import {deleteModuleURL} from "./URLs";
import format from "../format";
import axios from "../axios";

const deleteModule = async ({subject, submodule}) => {
    try {
        const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;
        const URL = format(BASE_URL + deleteModuleURL, subject, submodule);
        const response = await axios().delete(URL);
        return response.data || {};
    } catch {
        return {};
    }
};

export default deleteModule;
