import styled from "styled-components";

const HomeContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: #202830;
`;

export default HomeContainer;
