import React, {useState} from "react";
import Title from "@components/Title/Title";
import {Card, Input, Button} from "@nextui-org/react";
import UserViewContainer from "./styled-component/UserViewContainer";
import ButtonsViewContainer from "./styled-component/ButtonsViewContainer";

function CreateUser({onSaveUser, loading, onBackButton}) {
    const [newUser, setNewUser] = useState();
    return (
        <UserViewContainer>
            <Title>Alta de usuario</Title>
            <Card css={{$$cardColor: "white", padding: "20px"}}>
                <Input
                    label="Nombre"
                    type="text"
                    value={newUser?.name}
                    onChange={({target}) => {
                        setNewUser({
                            ...newUser,
                            name: target.value,
                        });
                    }}
                    css={{maxWidth: "500px"}}
                />
                <Input
                    label="Apellido"
                    type="text"
                    value={newUser?.lastName}
                    onChange={({target}) => {
                        setNewUser({
                            ...newUser,
                            lastName: target.value,
                        });
                    }}
                    css={{maxWidth: "500px"}}
                />
                <Input
                    label="Dirección"
                    type="text"
                    value={newUser?.address}
                    onChange={({target}) => {
                        setNewUser({
                            ...newUser,
                            address: target.value,
                        });
                    }}
                    css={{maxWidth: "500px"}}
                />
                <Input
                    label="Departamento"
                    type="text"
                    value={newUser?.department}
                    onChange={({target}) => {
                        setNewUser({
                            ...newUser,
                            department: target.value,
                        });
                    }}
                    css={{maxWidth: "500px"}}
                />
                <Input
                    label="Ciudad"
                    type="text"
                    value={newUser?.locality}
                    onChange={({target}) => {
                        setNewUser({
                            ...newUser,
                            locality: target.value,
                        });
                    }}
                    css={{maxWidth: "500px"}}
                />
                <Input
                    label="Email"
                    type="text"
                    value={newUser?.email}
                    onChange={({target}) => {
                        setNewUser({
                            ...newUser,
                            email: target.value,
                        });
                    }}
                    css={{maxWidth: "500px"}}
                />
                <Input.Password
                    label="Contraseña"
                    value={newUser?.authProvider}
                    onChange={({target}) => {
                        setNewUser({
                            ...newUser,
                            password: target.value,
                        });
                    }}
                    css={{maxWidth: "500px"}}
                />
                <Input
                    label="Teléfono"
                    type="text"
                    value={newUser?.phone}
                    onChange={({target}) => {
                        setNewUser({
                            ...newUser,
                            phone: target.value,
                        });
                    }}
                    css={{maxWidth: "500px"}}
                />
                <Input
                    label="Iglesia"
                    type="text"
                    value={newUser?.church}
                    onChange={({target}) => {
                        setNewUser({
                            ...newUser,
                            church: target.value,
                        });
                    }}
                    css={{maxWidth: "500px"}}
                />
                {/* <Input
          label="Rol"
          type="text"
          value={newUser?.role}
          onChange={({ target }) => {
            setNewUser({
              ...newUser,
              role: target.value,
            });
          }}
          css={{ maxWidth: "500px" }}
        /> */}

                <ButtonsViewContainer>
                    <Button
                        style={{margin: "0px 5px"}}
                        type="button"
                        isDisabled={loading}
                        onClick={onBackButton}
                    >
                        Volver
                    </Button>
                    <Button
                        style={{margin: "0px 5px"}}
                        type="button"
                        isDisabled={loading}
                        onClick={() => onSaveUser(newUser)}
                        isLoading={loading}
                    >
                        Guardar
                    </Button>
                </ButtonsViewContainer>
            </Card>
        </UserViewContainer>
    );
}

export default CreateUser;
