import axios from "../axios";
import { doRegisterURL } from "./URLs";

const doRegister = async ({
  name,
  lastName,
  church,
  phone,
  email,
  password,
  locality,
  department,
  address,
}) => {
  const response = await axios().post(doRegisterURL, {
    name,
    lastName,
    church,
    phone,
    email,
    password,
    department,
    locality,
    address,
  });
  return response.data || {};
};

export default doRegister;
