import axios from "../axios";
import {createCouponURL} from "./URLs";

const createCoupon = async (dataCoupon) => {
    try {
        const response = await axios().post(createCouponURL, dataCoupon);
        return response.data || {};
    } catch (error) {
        return error.response;
    }
};

export default createCoupon;
