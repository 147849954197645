import React from "react";
import {cn, VisuallyHidden, useRadio} from "@nextui-org/react";
import {Chip} from "@nextui-org/react";

const CustomRadioCalification = (props) => {
    const {
        Component,
        children,
        isSelected,
        description,
        getBaseProps,
        getWrapperProps,
        getInputProps,
        getLabelProps,
        getLabelWrapperProps,
        getControlProps,
    } = useRadio(props);

    const getBorderColor = (isCorrect) => {
        if (isCorrect) return 'border-primary'
        if (!isCorrect) return 'border-danger'
    }

    return (
        <>
            <Component
                {...getBaseProps()}
                className={cn(
                    "group inline-flex items-center hover:opacity-70 active:opacity-50 justify-between flex-row-reverse tap-highlight-transparent",
                    "max-h-[400px] cursor-pointer border-2 border-default rounded-lg gap-4 p-4",
                    `data-[selected=true]:${getBorderColor(props.isCorrect)}`,
                )}
            >
                <VisuallyHidden>
                    <input {...getInputProps()} />
                </VisuallyHidden>
                <span {...getWrapperProps()}>
                <span {...getControlProps()} />
            </span>
                {props.correctIndex &&
                    <Chip color="success" variant="flat"
                          style={{
                              height: "30px",
                          }}
                    >
                        Correcta
                    </Chip>
                }
                <div {...getLabelWrapperProps()}>
                    {children && <span {...getLabelProps()}>{children}</span>}
                    {description && (
                        <span className="text-small text-foreground opacity-70">{description}</span>
                    )}
                </div>
            </Component>
        </>


    );
};

export default CustomRadioCalification;
