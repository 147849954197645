import React, { useMemo, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Input, Button } from "@nextui-org/react";
import ButtonPayment from "./ButtonPayment/ButtonPayment";


const DiscountModal = (
    {
        visible,
        closeHandler,
        preference,
        onSecondaryActionModal,
        isLoadingSecondaryActionModal,
        isTotalDiscount,
        couponApplied
    }
) => {
    const [cuponCode, setCuponCode] = useState();
    const onCloseModal = () => {
        if (closeHandler) closeHandler();
    }

    return (
        <Modal backdrop="blur" isOpen={visible} hideCloseButton onClose={onCloseModal} size="3xl"
            style={{ backgroundColor: "#202830" }}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                        <div className="text-2xl font-bold text-center"> Descuento </div>
                        </ModalHeader>
                        <ModalBody>
                            {!!preference && visible &&
                                <>
                                    <div className="flex flex-col justify-center items-center">
                                        {/*<SubTitleModal>Agregar cupón</SubTitleModal>*/}
                                        <div className="flex justify-center content-center py-2">
                                            <Input
                                                label="Ingresar código"
                                                type="text"
                                                onChange={({ target }) => {
                                                    setCuponCode(target.value);
                                                }}
                                                size="sm"
                                            />
                                            <div className="px-4 py-1">
                                                <Button color="default"
                                                    onClick={() => onSecondaryActionModal(cuponCode)}
                                                    isDisabled={!cuponCode || couponApplied}
                                                    isLoading={isLoadingSecondaryActionModal}>
                                                    Aplicar
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default DiscountModal;
