import React, {useEffect, useState} from "react";
import {Button, Checkbox, CheckboxGroup, Input, Textarea, cn, RadioGroup, Radio} from "@nextui-org/react";
import ScoreTitle from "../../styled-components/ScoreTitle";
import ListQuestionsContainer from "./styled-components/ListQuestionsContainer";
import PointsContent from "./styled-components/PointsContent";
import CardStyledQuestion from "../../styled-components/CardStyledQuestion";
import QuestionTitle from "./styled-components/QuestionTitle";
import {EVALUATION_TYPE} from "@utils/constants";
import {Chip} from "@nextui-org/chip";
import CustomRadio from "./components/CustomRadio";


const ListQuestions = ({id, question, evaluationContent, setEvaluationContent, count}) => {
    const [responseSingle, setResponseSingle] = useState();
    const [trueQuestionsOptions, setTrueQuestionsOptions] = useState();

    const [isSelected, setIsSelected] = React.useState(false);

    // useEffect(() => {
    //     setEvaluationContent
    // }, [responseSingle])

    useEffect(() => {
        const newOptions = evaluationContent?.question.map((option) => {
            if (option?._id === id) {
                option.response = responseSingle?.name;
            }
            return option;
        });
        if (!!newOptions) setEvaluationContent({...evaluationContent, question: newOptions});
    }, [responseSingle])

    useEffect(() => {
        console.log('aa', trueQuestionsOptions)
    }, [trueQuestionsOptions])

    //Actualizo las opciones correctas
    useEffect(() => {
        const correctOptionsResult = [];

        for (let i = 0; i < trueQuestionsOptions?.length; i++) {
            correctOptionsResult.push(question?.description?.options?.indexOf(trueQuestionsOptions[i]))
        }

        const questionCopy = [...evaluationContent?.question];
        const questionToUpdate = questionCopy.find(item => item?._id === id);
        questionToUpdate.description.response = correctOptionsResult;
        setEvaluationContent({...evaluationContent, question: questionCopy})

    }, [trueQuestionsOptions])

    return (
        <ListQuestionsContainer>
            <CardStyledQuestion>
                <div className="flex p-2">
                    <QuestionTitle> {question?.description?.title}</QuestionTitle>
                </div>
                {question?.type === EVALUATION_TYPE.SINGLE_RESPONSE &&
                    <Textarea
                        label="Respuesta"
                        placeholder="Respuesta..."
                        onChange={({target}) => {
                            setResponseSingle({
                                ...responseSingle,
                                name: target.value,
                            });
                        }}
                    />
                }
                {question?.type === EVALUATION_TYPE.MULTIPLE_CHOIS &&
                    <>
                        {evaluationContent?.uniqueResponse &&
                            <RadioGroup
                                label="Seleccione la opción correcta:"
                                onChange={(target) => setTrueQuestionsOptions([target.target.defaultValue])}>
                                {
                                    question?.description?.options?.map((option, index) => (
                                        <CustomRadio value={option}>
                                            {option}
                                        </CustomRadio>
                                    ))
                                }
                            </RadioGroup>
                        }
                        {!evaluationContent?.uniqueResponse &&
                            <CheckboxGroup
                                label="Seleccionar las opciones correctas:"
                                onChange={(target) => setTrueQuestionsOptions(target)}
                                style={{
                                    padding: "10px",
                                    background: "#4e857d",
                                    borderRadius: "12px",
                                    marginTop: "10px",
                                }}
                                size="sm"
                                color="warning"
                            >
                                {
                                    question?.description?.options?.map((option, index) => (
                                        <Checkbox
                                            value={option}
                                            style={{marginTop: `${index === 0 && `10px`}`}}
                                        >
                                            {option}
                                        </Checkbox>
                                    ))
                                }
                            </CheckboxGroup>
                        }
                    </>
                }
                <div className="flex justify-end m-2">
                    <Chip color="success" variant="flat" style={{width: "100%"}}>{question?.score} puntos.</Chip>
                </div>
            </CardStyledQuestion>
        </ListQuestionsContainer>
    );
};

export default ListQuestions;
