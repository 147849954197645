import axios from "../axios";
import {getPaymentPlanURL} from "./URLs";

const getPaymentPlan = async () => {
    try {
        const response = await axios().get(getPaymentPlanURL);
        return response.data || {};
    } catch {
        return {};
    }
};

export default getPaymentPlan;
