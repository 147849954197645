import axios from "axios";
import ROUTES from "../components/Router/routes";

const INVALID_TOKEN = "INVALID_TOKEN";

let instance = null;

const errorResponseHandler = (error) => {
  try {
    const code = error?.response?.data?.code;
    const invalidTokenResponseMessages = error?.response?.data?.messages?.find(
      (x) => typeof x === "string" && x.includes(INVALID_TOKEN)
    );
    const invalidTokenResponseCode = code === INVALID_TOKEN;

    const status = error?.response.status;
    if (
      status === 401 ||
      invalidTokenResponseMessages ||
      invalidTokenResponseCode
    ) {
      if (window.location.pathname !== ROUTES.LOGIN && window.location.pathname !== ROUTES.EXPIRED)
        window.location.replace(ROUTES.LOGIN);
    }
    return Promise.reject(error);
  } catch {
    return Promise.reject(error);
  }
};

const applyInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    errorResponseHandler
  );
};

export const initialize = () => {
  // instance = axios.create();

  // applyInterceptor(instance);
  instance = axios.create();
  instance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  instance.defaults.withCredentials = true;
  applyInterceptor(instance);
  return instance;
};

const getInstance = () => instance || initialize();

export default getInstance;
