import styled, { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
`;

const circleAnimation = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
`;

const Body = styled.body`
  background: #222831;
`;

const Circle = styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  width: 20px;
  height: 20px;

  &::before {
    content: "";
    position: relative;
    display: block;
    width: 250%;
    height: 250%;
    box-sizing: border-box;
    margin-left: -75%;
    margin-top: -75%;
    border-radius: 45px;
    background-color: #ce1818;
    animation: ${pulseAnimation} 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ce1818;
    border-radius: 50px;
    animation: ${circleAnimation} 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
`;

export { Body, Circle }

