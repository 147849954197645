import {Link} from "@nextui-org/react";
import styled from "styled-components";

const DeleteLink = styled(Link)`
  cursor: pointer;
  font-size: 13px;
  padding: 10px;
  background-color: ${({isModeEdit}) => (!isModeEdit ? '#cee4fe' : '#4b4d3db3')}; 
  border-radius: 12px;
  margin: 0px 10px;
`;

export default DeleteLink;
