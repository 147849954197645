import React, {useEffect} from "react";
import {Checkbox, CheckboxGroup, Textarea, RadioGroup, Chip, Input} from "@nextui-org/react";
import ListQuestionsCalificationContainer from "./styled-components/ListQuestionsCalificationContainer";
import QuestionTitle from "./styled-components/QuestionTitle";
import {EVALUATION_TYPE} from "@utils/constants";
import CustomRadioCalification from "./components/CustomRadio";
import CardStyledQuestion from "../../../../styled-components/CardStyledQuestion";
import ChipContainer from "./styled-components/ChipContainer";


const ListQuestionViewEvaluation = (
    {
        question,
        answer,
        evaluationContent,
        calificationContent,
        setCalificationContent
    }
) => {

    const [questionScore, setQuestionScore] = React.useState(0);
    const [commentCalification, setCommentCalification] = React.useState();

    useEffect(() => {
        if (commentCalification?.name || questionScore > 0) {

            const answersCopy = [...calificationContent?.answers];

            if (answersCopy.length > 0) {
                // Find question to update.
                const element = answersCopy?.find(item => item?.question === question?._id);

                // Update property.
                element.answer.comment = commentCalification?.name || '';
                element.answer.answerScore = questionScore;
                element.answer.isCorrect = questionScore > 0;

                // Set the new array in the state
                setCalificationContent({...calificationContent, answers: answersCopy})
            }
        }
    }, [commentCalification, questionScore])

    const getSelectedResponses = (arrayOptions, arrayWithIndexs) => {
        const arrayResponse = [];

        arrayWithIndexs?.map((index) => {
            arrayResponse.push(arrayOptions[index]);
        })

        return arrayResponse;
    }

    console.log(question?.description?.options[answer?.data[0]])

    return (
        <ListQuestionsCalificationContainer>
            <CardStyledQuestion>
                <div className="flex p-2">
                    <QuestionTitle> {question?.description?.title}</QuestionTitle>
                </div>
                {question?.type === EVALUATION_TYPE.SINGLE_RESPONSE &&
                    <Textarea
                        label="Respuesta"
                        placeholder="Respuesta..."
                        isDisabled
                        value={answer?.data}
                    />
                }
                {question?.type === EVALUATION_TYPE.MULTIPLE_CHOIS &&
                    <>
                        {evaluationContent?.evaluation?.uniqueResponse &&
                            <RadioGroup
                                isDisabled
                                label="Seleccione la opción correcta:"
                                value={question?.description?.options[answer?.data[0]]}
                            >
                                {
                                    question?.description?.options?.map((option, index) => (
                                        <CustomRadioCalification
                                            value={option}
                                            isCorrect={!!answer?.isCorrect || evaluationContent?.state === "Pending"}
                                            correctIndex={question?.response[0] === index && evaluationContent?.state === "Completed"}
                                        >
                                            {option}
                                        </CustomRadioCalification>
                                    ))
                                }
                            </RadioGroup>
                        }
                        {!evaluationContent?.evaluation?.uniqueResponse &&
                            <CheckboxGroup
                                label="Seleccionar las opciones correctas:"
                                style={{
                                    padding: "10px",
                                    background: "#4e857d",
                                    borderRadius: "12px",
                                    marginTop: "10px"
                                }}
                                size="sm"
                                color="warning"
                                value={getSelectedResponses(question?.description?.options, answer?.data)}
                            >
                                {
                                    question?.description?.options?.map((option, index) => (
                                        <Checkbox
                                            value={option}
                                            style={{marginTop: `${index === 0 && `10px`}`}}
                                            isDisabled
                                        >
                                            {option}
                                            {evaluationContent?.state === "Completed" &&
                                                (question?.response.find((q) => q === index) || question?.response[0] === index) &&
                                                <Chip color="success" variant="flat"
                                                      style={{
                                                          height: "30px",
                                                      }}
                                                >
                                                    Correcta
                                                </Chip>
                                            }
                                        </Checkbox>
                                    ))
                                }
                            </CheckboxGroup>
                        }
                    </>
                }
                <div className="flex justify-end m-2">
                    <Chip color="success" variant="flat"
                          style={{width: "100%"}}>{answer?.answerScore}/{question?.score} puntos.</Chip>
                </div>
            </CardStyledQuestion>
            {question?.type === EVALUATION_TYPE.SINGLE_RESPONSE &&
                <div className="flex flex-col items-center">
                    {evaluationContent?.state === "Completed" &&
                        <ChipContainer isCorrect={answer?.isCorrect} className="mb-3">
                            <Chip color={answer?.isCorrect ? 'success' : 'danger'}
                                  variant="solid">{answer?.isCorrect ? '¡Correcta!' : 'Incorrecta'} </Chip>
                        </ChipContainer>
                    }
                    <div className="mt-3">
                        {answer?.comment !== "" &&
                            <Textarea
                                label="Comentarios del profesor: "
                                value={answer?.comment}
                                isDisabled
                            />
                        }
                    </div>
                </div>
            }
        </ListQuestionsCalificationContainer>
    );
};

export default ListQuestionViewEvaluation;
