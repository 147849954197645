import { Image, Card, CardHeader, Code } from "@nextui-org/react";
import ImagePerfilTest2 from "@assets/perfil-test2.png";
import ImageStyle from "../../Users/components/CreateUser/styled-component/ProfileImageStyle";
import Text from "@components/Text/Text";
import CardContainer from "./CardContainer";
import Title from "@components/Title/Title";
import CopyIcon from "@assets/copy.svg"
import CheckIcon from "@assets/check.svg"
import SubTitle from "@components/SubTitle/SubTitle";
import { useState } from "react";

export const CardUserProfile = (user) => {
  const [copyClicked, setCopyClicked] = useState(false);
  const copyClipBoard = () => {
    navigator.clipboard.writeText(user?.id);
    setCopyClicked(true);
  }
  return (
    <CardContainer>
      <Image src={ImagePerfilTest2} width={80} height={80} style={ImageStyle} />
      <Text size="16px" weight="600" style={{ paddingTop: "20px" }}></Text>
      <Title> {user?.name + " " + user?.lastName} </Title>
      <Text color="#41c564">{user?.email}</Text>
      <SubTitle style={{ paddingTop: "15px" }}>
        {user?.role === "ADMIN" ? "ADMIN" : "ESTUDIANTE"}
      </SubTitle>
      <div className="my-2">
        <Code
          color="success"
          className="flex cursor-pointer"
          onClick={copyClipBoard}
        >ID <img alt="copy" width={20} className="mx-2" src={copyClicked ? CheckIcon : CopyIcon}></img> </Code>
      </div>
    </CardContainer>
  );
}

