import styled from "styled-components";

const TitleHeader = styled.div`
  font-weight: 600;
  font-size: 2em;
  text-decoration: ${({ isActive }) => (isActive ? "underline" : "none")};
  padding: 10px;
`;

export default TitleHeader;
