import styled from "styled-components";
import {Card} from "@nextui-org/react";

const CardContainer = styled(Card)`
  max-height: ${({detailIsVisible}) => (detailIsVisible ? "" : "100px")};
  background-color: #27282d;
  color: white;
  box-shadow: rgb(37 103 91 / 24%) 0px 7px 29px 0px;
`;

export default CardContainer;
