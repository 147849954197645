import styled from "styled-components";

const HeaderFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 45px 15px 22px 25px;
    transition: 0.3s;
`;

export default HeaderFooter;
