import React from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "@nextui-org/react";

const DeleteModal = ({visible, closeHandler, onDeleteConfirm, loading}) => {
    return (
        <Modal backdrop="blur" isOpen={visible} onClose={closeHandler}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader
                            className="flex flex-col gap-1"
                            style={{color: "black"}}
                        >
                            ¿Estás seguro que querés eliminar?
                        </ModalHeader>
                        <ModalBody></ModalBody>
                        <ModalFooter>
                            <Button auto flat onClick={closeHandler}>
                                Volver
                            </Button>
                            <Button
                                isDisabled={loading}
                                color="danger"
                                style={{color: "white"}}
                                auto
                                onClick={onDeleteConfirm}
                                isLoading={loading}
                            >
                                Eliminar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default DeleteModal;
