import axios from "../axios";
import { getCouponsURL } from "./URLs";

const getCoupons = async () => {
  try {
    const response = await axios().get(getCouponsURL);
    return response.data || {};
  } catch (error) {
    return error.response;
  }
};

export default getCoupons;
