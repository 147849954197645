import React from "react";
import {Popover, PopoverContent, PopoverTrigger} from "@nextui-org/popover";
import {Button, Spinner} from "@nextui-org/react";

const ButtonConfirmation = ({textButton, actionLoading, contentPopover, colorButton}) => (
    <Popover
        showArrow
        backdrop="opaque"
        placement="top"
        classNames={{
            base: "py-3 px-4 border border-default-200 bg-gradient-to-br from-white to-default-300 dark:from-default-100 dark:to-default-50",
            arrow: "bg-default-200",
        }}
    >
        <PopoverTrigger>
            {actionLoading ? (
                <Spinner type="points-opacity" color="currentColor" size="sm"/>
            ) : (
                <Button color={colorButton || "default"}>
                    {textButton}
                </Button>
            )}
        </PopoverTrigger>
        <PopoverContent>
            <div className="px-1 py-2">
                <div className="text-red-600 text-center">
                    ¿{textButton}?
                </div>
                <div className="flex justify-center">
                    {contentPopover}
                </div>
            </div>
        </PopoverContent>
    </Popover>
);

export default ButtonConfirmation;
