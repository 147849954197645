import { Button, Card, Input } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import ImageComplete from "@assets/icons/form-complete-icon.png"
import ConfirmProfileContainer from "./styled-components/ConfirmProfileContainer";
import updateProfile from "@services/users/updateProfile";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useUser } from "@context/UserProvider";

function ConfirmProfile({ userLogged }) {
    const [updatedUser, setUpdatedUser] = useState(userLogged);
    const { fetchUserLogged, setUser } = useUser();

    const { mutate: doCompleteProfile, isLoading: isLoadingCompleteProfile } =
    useMutation(updateProfile, {
        onSuccess: (data) => {
            if (data.status !== 400) {
                enqueueSnackbar("Perfil completado", {
                    variant: "success",
                    persist: false,
                });
                setUser(data.data);
                fetchUserLogged();
            } else {
                enqueueSnackbar(data.data.message, {
                    variant: "error",
                    persist: false,
                });
            }
        },
        onError: (error) => {
            console.log(error.request.response);
            enqueueSnackbar(error.response.data.message, {
                variant: "error",
                persist: false,
            });
        },
    });

    const onUpdateProfile = () =>{
        doCompleteProfile(updatedUser);
    }

    const isInvalidForm =
        !updatedUser?.address ||
        !updatedUser?.department ||
        !updatedUser?.locality ||
        !updatedUser?.phone ||
        !updatedUser?.church;

    useEffect(() => {

    }, [updatedUser])


    return (
        <ConfirmProfileContainer className="flex items-center justify-center text-center w-full">
            <Card
                isFooterBlurred
                radius="lg"
                className="border-none w-full md:w-3/4 bg-gradient-to-r"
            >
                <div className="flex justify-center items-center w-full text-3xl font-bold  pt-10">
                    <div>
                        <img src={ImageComplete} alt="profile" />
                    </div>
                    <div className="">
                        ¡Bienvenido!
                    </div>
                </div>
                <div className="flex justify-center items-center w-full text-md pt-4">
                    Completa los siguientes datos para finalizar el registro.
                </div>
                <div className="p-2">
                    <div className="py-2 w-full">
                        <Input
                            label="Dirección"
                            type="text"
                            value={updatedUser.address}
                            onChange={({ target }) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    address: target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="py-2 w-full">
                        <Input
                            label="Departamento"
                            type="text"
                            value={updatedUser.department}
                            onChange={({ target }) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    department: target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="py-2 w-full">
                        <Input
                            label="Ciudad"
                            type="text"
                            value={updatedUser.locality}
                            onChange={({ target }) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    locality: target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="py-2 w-full">
                        <Input
                            label="Teléfono"
                            type="text"
                            value={updatedUser.phone}
                            onChange={({ target }) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    phone: target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="py-2 w-full">
                        <Input
                            label="Iglesia"
                            type="text"
                            value={updatedUser.church}
                            onChange={({ target }) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    church: target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="py-2 w-full">
                        <Button
                            isDisabled={isInvalidForm}
                            color="primary"
                            auto
                            onClick={onUpdateProfile}
                            isLoading={isLoadingCompleteProfile}
                        >
                            Finalizar
                        </Button>
                    </div>
                </div>
            </Card>

        </ConfirmProfileContainer>
    );
}

export default ConfirmProfile;
