import axios from "../axios";
import {createOrderPaymentURL} from "./URLs";

const createPaymentOrder = async ({paymentId, dues}) => {
    try {
        const response = await axios().post(createOrderPaymentURL, {
            payment: paymentId,
            dues: dues
        });
        return response || {};
    } catch {
        return {};
    }
};

export default createPaymentOrder;
