import axios from "../axios";
import {newVideoURL} from "./URLs";

const doCreateVideo = async ({idSubjectModule, name, url}) => {
    try {
        const response = await axios().post(newVideoURL, {
            subjectModule: idSubjectModule,
            urls: [
                {
                    name: name,
                    url: url
                }
            ]
        });
        return response.data || {};
    } catch {
        return {};
    }
};

export default doCreateVideo;
