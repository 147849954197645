import React, {useEffect, useState} from "react";
import {useMutation} from "react-query";
import PdfContainer from "./styled-components/pdfContainer";
import getProgress from "@services/progress/getProgress";
import {enqueueSnackbar} from "notistack";
import {Spinner} from "@nextui-org/react";
import ScholarshipGenerator from "./components/ScholarshipGenerator";
import {SiteLoader} from "../../components/SiteLoader/SiteLoader";


const Scholarship = ({userLogged}) => {
    const [progress, setProgress] = useState([]);

    const {mutate: fetchProgress, isLoading: isLoadingProgress} = useMutation(
        getProgress,
        {
            onSuccess: (data) => {
                if (data) {
                    setProgress(data.data);
                }
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        }
    );

    useEffect(() => {
        fetchProgress();
    }, []);

    if (isLoadingProgress)
        return (
            <SiteLoader/>
        );

    return (
        <PdfContainer>
            <ScholarshipGenerator userLogged={userLogged} progressData={progress}/>
        </PdfContainer>
    );
};

export default Scholarship;
