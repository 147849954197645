import axios from "../axios";
import {createModuleURL} from "./URLs";
import formatDateHelper from "../../helpers/formatDateHelper";

const createModule = async ({name, subject}) => {
    const dateNow = new Date(Date.now());
    try {
        const response = await axios().post(createModuleURL, {
            name,
            dateStart: formatDateHelper(dateNow),
            dateFinish: formatDateHelper(dateNow, 10),
            subject,
        });
        return response.data || {};
    } catch {
        return {};
    }
};

export default createModule;
