import React, { useState } from "react";
import PropTypes from "prop-types";
import ItemNavContainer from "./styled-components/ItemNavContainer";
import ImagePerfilTest from "@assets/test-icon.svg";
import Icon from "@components/Icon/Icon";
import ItemName from "./styled-components/ItemName";

const ItemNav = ({ item, navIsOpen, onClickItem, tabActive }) => {
  return (
    <ItemNavContainer
      onClick={() => onClickItem(item?.path)}
      isActive={tabActive}
    >
      <Icon
        src={item.image || ImagePerfilTest}
        width="20px"
        height="20px"
      ></Icon>
      {navIsOpen && <ItemName>{item?.label}</ItemName>}
    </ItemNavContainer>
  );
};

ItemNav.propTypes = {
  onClickItem: PropTypes.func,
  item: PropTypes.object,
  navIsOpen: PropTypes.bool,
};

export default ItemNav;
