import React from "react";
import { Body, Circle } from "./styled-components/LiveIcon";
import { Link } from "@nextui-org/react";

const LiveLink = ({ link }) => {

    return (
        <div className="flex flex-row items-center">
            <Body>
                <Circle></Circle>
            </Body>
            <div className="flex flex-col pl-4 cursor-pointer">
                <Link onClick={() => window.open(link, "_blank")} color="danger">Ingresar clase en vivo</Link>
            </div>
        </div>
    );
};

export default LiveLink;
