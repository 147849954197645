import styled from "styled-components";

const UserEmail = styled.div`
  font-size: 12px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default UserEmail;
