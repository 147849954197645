import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "@nextui-org/react";
import ButtonLoginContainer from "../../styled-components/ButtonLoginContainer";
import doFetchRequestRecovery from "@services/auth/doFetchRequestRecovery";
import Text from "@components/Text/Text";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";

function ViewRenewPassword({ setStateUser, loading }) {
  const [emailRecovery, setEmailRecovery] = useState();
  const [mailSend, setMailSend] = useState(false);

  const {
    mutate: doFetchRecoveryPassword,
    isLoading: isLoadingRequestRecovery,
  } = useMutation(doFetchRequestRecovery, {
    onSuccess: (data) => {
      if (data.status === 201) {
        enqueueSnackbar("Solicitud realizada", {
          variant: "success",
          persist: false,
        });
        setMailSend(true);
      }
      if (data.status === 404) {
        enqueueSnackbar("Email no se encuentra registrado.", {
          variant: "error",
          persist: false,
        });
        setMailSend(false);
      }
      if(data.status !== 201 && data.status !== 404){
        enqueueSnackbar("Problemas en la solicitud", {
          variant: "error",
          persist: false,
        });
      }
    },
    onError: () => {
      enqueueSnackbar("Problemas en la solicitud", {
        variant: "error",
        persist: false,
      });
    },
  });

  const onSendEmail = () => {
    doFetchRecoveryPassword({
      emailRecovery,
    });
  };

  return (
    <>
      <Input
        label="Email"
        type="text"
        onChange={({ target }) => {
          setEmailRecovery(target.value);
        }}
        value={emailRecovery}
      />
      <div className="p-4">
        <Text
          style={{
            textAlign: "center",
          }}
          color="black"
        >
          {!mailSend
            ? "Ingresa tu correo electrónico y te enviaremos instrucciones para restablecerla."
            : "Te enviamos un mail a tu correo con instrucciones para restablecer tu contraseña. "}
        </Text>
      </div>

      <ButtonLoginContainer>
        <Button
          onClick={onSendEmail}
          isDisabled={loading}
          isLoading={isLoadingRequestRecovery}
        >
          Enviar
        </Button>
      </ButtonLoginContainer>
    </>
  );
}

ViewRenewPassword.propTypes = {
  email: PropTypes.string,
  setStateUser: PropTypes.func,
  setModeView: PropTypes.func,
  loading: PropTypes.func,
};

export default ViewRenewPassword;
