import styled from "styled-components";

const LogoIcon = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 6px;
  border-radius: 50%;
  cursor: pointer;
`;

export default LogoIcon;
