import axios from "../axios";
import { newPresentationURL } from "./URLs";

const doCreatePresentation = async ({
  idSubjectModule,
  name,
  presentationUrl,
}) => {
  try {
    const response = await axios().post(newPresentationURL, {
      subjectModule: idSubjectModule,
      presentationData: [
        {
          name: name,
          presentationUrl: presentationUrl,
        },
      ],
    });
    return response.data || {};
  } catch {
    return {};
  }
};

export default doCreatePresentation;
