import React, {useState} from "react";
import {Spinner, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter} from "@nextui-org/react";
import LoaderModal from "./styled-components/LoaderModal";
import ReactPlayer from "react-player";
import updateProgress from "@services/progress/updateProgress";
import {useUser} from "@context/UserProvider";
import TitleModal from "./styled-components/TitleModal";


const VideoModal = ({video, visible, closeHandler, enrolmentId}) => {
    const {user} = useUser();
    const [loadingVideo, setLoadingVideo] = useState(true)

    const onLoadVideo = (seconds) => {
        console.log('cargovideo ', seconds)
        setLoadingVideo(false)
    }

    const onCloseModal = () => {
        setTimeout(() => {
            setLoadingVideo(true)
        }, 500);
        if (closeHandler) closeHandler();
    }

    const handleUpdateProgress = () => {
        updateProgress({userId: user.id, idVideo: video?.id, enrolmentId: enrolmentId});
    }

    if (!video) return;

    return (
        <Modal backdrop="blur" isOpen={visible} onClose={onCloseModal} size="3xl">
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <TitleModal>{video?.name}</TitleModal>
                        </ModalHeader>
                        <ModalBody>
                            <div className="flex justify-center">
                                <LoaderModal>
                                    {loadingVideo && <Spinner color="success"></Spinner>}
                                </LoaderModal>
                                <ReactPlayer
                                    url={video?.url}
                                    controls={true}
                                    onBuffer={() => handleUpdateProgress()}
                                    onDuration={onLoadVideo}
                                    width="750px"
                                    // onProgress={(state) => console.log('manda estado cada un segundo', state)}
                                    // onSeek={(seconds) => console.log('se movio a los segundos: ', seconds)}
                                />
                            </div>

                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default VideoModal;
