import { deleteFileURL } from "./URLs";
import format from "../format";
import axios from "../axios";

const doDeleteFile = async ({ item, subjectModule, key }) => {
  try {
    const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;
    const URL = format(BASE_URL + deleteFileURL, item, subjectModule, key);
    const response = await axios().delete(URL);
    console.log("SERVICEEE", response);
    return response.data || {};
  } catch {
    return {};
  }
};

export default doDeleteFile;
