import styled from "styled-components";

const ItemNavContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 25px 28px;
  height: 40px;
  &:hover {
    background: rgba(255, 255, 255, 0.22);
    border-radius: 24.1454px;
  }
  background: ${({isActive}) => (isActive && 'rgb(82 151 57 / 22%)')};  
  border-radius:  ${({isActive}) => (isActive && '24px')};
  transition: 0.3s;
`;

export default ItemNavContainer;
