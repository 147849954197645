import React from "react";
import { Checkbox, Link, User, Chip, cn } from "@nextui-org/react";

export const CustomCheckbox = ({ plan, statusColor, value, isPaymentCompleted }) => {

    const getItemTitle = (index) => {
        if (index === 0) return 'Cuota 1 + Matricula'
        return `Cuota ${index + 1}`
    }

    return (
        <Checkbox
            aria-label={plan.price}
            classNames={{
                base: cn(
                    "inline-flex max-w-full w-full bg-content1 m-0",
                    "hover:bg-content2 items-center justify-start",
                    "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                    "data-[selected=true]:border-success",
                ),
                label: "w-full text-white",
            }}
            style={{ backgroundColor: "rgb(41 61 57 / 65%)", boxShadow: "rgb(37 103 91 / 7%) 0px 7px 29px 0px" }}
            value={value}
            isDisabled={isPaymentCompleted}
        >
            <div className="w-full flex justify-between items-center gap-2 p-1 m-2">
                <div className="w-full flex justify-between">
                    <div>{getItemTitle(plan.index)}</div>
                    {plan.status === "Pago" && <div className="font-semibold line-through">${plan.price}</div>}
                    {plan.status !== "Pago" && <div className="font-semibold">${plan.price}</div>}
                </div>
                <div className="text-center">
                    <span className="text-tiny text-default">{!!plan?.date && `Fecha pago: ${plan?.date}`}</span>
                    <span className="text-tiny text-default">{!plan?.date && `Vencimiento: ${plan?.expirationDate}`}</span>
                </div>
                <div className="flex flex-col items-end gap-1">
                    <Chip color={statusColor} size="sm" variant="flat" style={{ width: "80px" }}>
                        {plan.status}
                    </Chip>
                </div>
            </div>
        </Checkbox>
    );
};
