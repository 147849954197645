import styled from "styled-components";

const HeaderStartEvaluation = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({isMobile}) => (isMobile ? "column" : "row")};
  padding: 0px 20px;
  align-items: center;
`;

export default HeaderStartEvaluation;