import styled from "styled-components";

const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
`;

export default BackButton;
