import styled from "styled-components";

const CouponsGestionContainer = styled.div`
  margin: 20px;
  height: 95vh;
  overflow-y: scroll;
  width: 100%;
  padding: 0px 50px;
`;

export default CouponsGestionContainer;
