import React, {useEffect, useState} from "react";
import {Button} from "@nextui-org/react";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import EvaluationIcon from '@assets/evaluation-icon.png';
import ViewEvaluationContainer from "./styled-components/ViewEvaluationContainer";
import HeaderViewEvaluation from "./styled-components/HeaderViewEvaluation";
import ViewEvaluationMainTitle from "./styled-components/ViewEvaluationMainTitle";
import Title from "@components/Title/Title";
import {useMutation} from "react-query";
import {enqueueSnackbar} from "notistack";
import queryString from "query-string";
import ScrollContent from "../../styled-components/ScrollContent";
import Icon from "@components/Icon/Icon";
import {Chip} from "@nextui-org/chip";
import SubTitle from "@components/SubTitle/SubTitle";
import useDevice from "@utils/useDevice";
import ListQuestionViewEvaluation from "./components/ListQuestionViewEvaluation";
import getResultsByResult from "@services/evaluations/getResultsByResult";
import {SiteLoader} from "@components/SiteLoader/SiteLoader";
import {EVALUATION_TYPE} from "@utils/constants";
import ROUTES from "../../../../components/Router/routes";
import BackButtonIcon from '@assets/back-button.png'
import BackButtonEvaluation from "./styled-components/BackButtonEvaluation";

const ViewEvaluation = () => {
    const {isMobile} = useDevice();
    const navigate = useNavigate();
    const location = useLocation();
    const evaluationData = queryString.parse(location.search)
    const {result, e} = evaluationData;

    const [evaluationViewContent, setEvaluationViewContent] = useState();

    const [calificationContent, setCalificationContent] = useState(
        {
            result: result,
            score: 0,
            enrolment: e,
            answers: []
        }
    );


    const {mutate: getEvaluationByResult, isLoading: isLoadingGetEvaluation} =
        useMutation(getResultsByResult, {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    setEvaluationViewContent(data.data[0]);
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
            },
            onError: (error) => {
                console.log('error')
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });

    useEffect(() => {
        getEvaluationByResult({resultId: result});
    }, [])

    const getTotalScoreByAnswers = (arr) => {
        let totalScoreResult = 0

        arr?.map((item) => {
            totalScoreResult += item.answer.answerScore || 0;
        })
        return totalScoreResult;
    }

    useEffect(() => {
        setCalificationContent({
            ...calificationContent,
            score: getTotalScoreByAnswers(calificationContent?.answers)
        })

        console.log('calification', calificationContent);

    }, [calificationContent?.answers])


    const mockResponseWithMultipleChoisResponses = (answersMultipleChois) => {
        const mockResponse = [];
        answersMultipleChois?.map((answer) => {
            mockResponse.push({
                question: answer?.question?._id,
                title: answer?.question?.description?.title,
                answer: {
                    comment: '',
                    isCorrect: answer?.isCorrect,
                    score: answer?.isCorrect ? answer?.question?.score : 0
                }
            })
        })
        return mockResponse;
    }

    const mockResponseWithSimpleResponses = (simpleAnswers) => {
        const mockResponse = [];
        simpleAnswers?.map((answer) => {
            mockResponse.push({
                question: answer?.question?._id,
                title: answer?.question?.description?.title,
                answer: {
                    comment: '',
                    isCorrect: null,
                    score: 0
                }
            })
        })
        return mockResponse;
    }

    useEffect(() => {
        //Set data from service in the state calification
        const responsesMultipleChois = mockResponseWithMultipleChoisResponses(evaluationViewContent?.answers.filter((answer) => answer?.question?.type === EVALUATION_TYPE.MULTIPLE_CHOIS))
        const simpleResponses = mockResponseWithSimpleResponses(evaluationViewContent?.answers.filter((answer) => answer?.question?.type === EVALUATION_TYPE.SINGLE_RESPONSE))

        setCalificationContent({...calificationContent, answers: [...responsesMultipleChois, ...simpleResponses]})

        console.log('eva', evaluationViewContent)
    }, [evaluationViewContent])


    if (isLoadingGetEvaluation) return <SiteLoader/>;

    return (
        <ViewEvaluationContainer>
            <HeaderViewEvaluation isMobile={isMobile}>

                <ViewEvaluationMainTitle>
                    <div className="flex items-center p-2">
                        <div className="flex items-center">
                            <BackButtonEvaluation>
                                <Button color="default" startContent={<img width="20px" src={BackButtonIcon}/>}
                                        onClick={() => navigate({
                                            pathname: ROUTES.CONTENT,
                                            search: `?e=${e}`,
                                        })}
                                >
                                    Volver
                                </Button>
                            </BackButtonEvaluation>
                            <Icon src={EvaluationIcon} alt='evaluation' width="40px" height="40px"/>
                            <Title> {evaluationViewContent?.evaluation?.title} </Title>
                        </div>
                        <div className="w-full p-4">
                            {evaluationViewContent?.state === 'Pending' &&
                                <Chip color="warning" variant="flat"
                                      style={{
                                          width: "100%",
                                          height: "60px",
                                          margin: "0px 30px",
                                          fontSize: "18px",
                                          fontWeight: "600"
                                      }}>
                                    Pendiente de calificar
                                </Chip>
                            }
                            {evaluationViewContent?.state === 'Completed' &&
                                <Chip color="success" variant="flat"
                                      style={{
                                          width: "100%",
                                          height: "60px",
                                          margin: "0px 30px",
                                          fontSize: "18px",
                                          fontWeight: "600"
                                      }}>
                                    Puntaje: {evaluationViewContent?.score}/{evaluationViewContent?.evaluation?.maxScore}
                                </Chip>
                            }
                        </div>
                        <div className="px-5">
                            <Title> <SubTitle>Alumno:</SubTitle> {evaluationViewContent?.studentName} </Title>
                        </div>
                    </div>
                    <div className="p-2">
                        <SubTitle> {evaluationViewContent?.description} </SubTitle>
                    </div>
                </ViewEvaluationMainTitle>
            </HeaderViewEvaluation>
            <ScrollContent>
                {
                    evaluationViewContent?.answers?.map((answer, index) => (
                        <ListQuestionViewEvaluation
                            id={answer?.question?._id}
                            question={answer?.question}
                            answer={answer}
                            evaluationContent={evaluationViewContent}
                            calificationContent={calificationContent}
                            setCalificationContent={setCalificationContent}
                            count={index + 1}
                        >
                        </ListQuestionViewEvaluation>
                    ))
                }
            </ScrollContent>
        </ViewEvaluationContainer>
    );
};

export default ViewEvaluation;
