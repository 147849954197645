import CouponsGestionContainer from "./styled-components/CouponsGestionContainer";
import TabCoupons from "./styled-components/TabCoupons";
import TableCoupons from "./components/TableCoupons";
import getCoupons from "@services/coupon/getCoupons";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import deleteCoupon from "@services/coupon/deleteCoupon";
import createCoupon from "@services/coupon/createCoupon";
import editCoupon from "@services/coupon/editCoupon";
import DeleteModal from "@components/DeleteModal";
import AddModal from "./components/AddModal";
import EditModal from "./components/EditModal";
import { SiteLoader } from "@components/SiteLoader/SiteLoader";

function CouponsGestion() {
    const [coupons, setCoupons] = useState([]);
    const [loaded, setLoaded] = useState();
    const [couponDelete, setCouponDelete] = useState();
    const [couponToEdit, setCouponToEdit] = useState();
    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [showModalAdd, setShowModalAdd] = React.useState(false);
    const [showModalEdit, setShowModalEdit] = React.useState(false);

    const { mutate: fetchCoupons, isLoading: isLoadingCoupons } = useMutation(
        getCoupons,
        {
            onSuccess: ({ data }) => {
                if (data) {
                    setCoupons(data);
                    setLoaded(true);
                }
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        }
    );

    const { mutate: doDeleteCoupon, isLoading: isLoadingDelete } = useMutation(
        deleteCoupon,
        {
            onSuccess: ({ data }) => {
                if (data)
                    enqueueSnackbar("Cupon eliminado", {
                        variant: "success",
                        persist: false,
                    });
                setShowModalDelete(false);
                fetchCoupons();
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        }
    );

    const { mutate: doAddCoupon, isLoading: isLoadingAdd } = useMutation(
        createCoupon,
        {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    enqueueSnackbar("Cupon agregado", {
                        variant: "success",
                        persist: false,
                    });
                    setShowModalAdd(false);
                    fetchCoupons();
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                    setShowModalEdit(false);
                }
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        }
    );

    const { mutate: doEditCoupon, isLoading: isLoadingEdit } = useMutation(
        editCoupon,
        {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    enqueueSnackbar("Cupon guardado", {
                        variant: "success",
                        persist: false,
                    });
                    setShowModalEdit(false);
                    fetchCoupons();
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                    setShowModalEdit(false);
                }
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        }
    );

    const onDeleteCoupon = (couponId) => {
        setCouponDelete(couponId);
        setShowModalDelete(true);
    };

    const onDeleteConfirm = () => {
        doDeleteCoupon(couponDelete);
        setCouponDelete();
    };

    const onCloseModal = () => {
        setShowModalDelete(false);
        setShowModalAdd(false);
        setShowModalEdit(false);
    };

    const onAddCoupon = () => {
        setShowModalAdd(true);
    };

    const onAddConfirm = (newCoupon) => {
        doAddCoupon(newCoupon);
    };

    const onEditCoupon = (updatedCoupon) => {
        setCouponToEdit(updatedCoupon);
        setShowModalEdit(true);
    };

    const onUpdateConfirm = (updatedCoupon) => {
        doEditCoupon(updatedCoupon);
        setCouponToEdit(updatedCoupon);
    };

    useEffect(() => {
        fetchCoupons();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!loaded)
        return (
            <SiteLoader />
        );

    return (
        <CouponsGestionContainer>
            {showModalDelete && (
                <DeleteModal
                    visible={showModalDelete}
                    closeHandler={onCloseModal}
                    onDeleteConfirm={onDeleteConfirm}
                    loading={isLoadingDelete}
                />
            )}
            {showModalAdd && (
                <AddModal
                    visible={showModalAdd}
                    closeHandler={onCloseModal}
                    onAddConfirm={onAddConfirm}
                    loading={isLoadingAdd}
                />
            )}
            {showModalEdit && (
                <EditModal
                    visible={showModalEdit}
                    closeHandler={onCloseModal}
                    onUpdateConfirm={onUpdateConfirm}
                    loading={isLoadingEdit}
                    couponToEdit={couponToEdit}
                />
            )}
            <TabCoupons>Cupones generados: </TabCoupons>
            <div className="p-4 bg-white/95">
                <TableCoupons
                    coupons={coupons}
                    onDeleteCoupon={onDeleteCoupon}
                    onAddCoupon={onAddCoupon}
                    onEditCoupon={onEditCoupon}
                />
            </div>
        </CouponsGestionContainer>
    );
}

export default CouponsGestion;
