import React from "react";


const SkeletonSubjects = ({ backButtton }) => {
    return (
        <div className="w-full flex flex-col items-start justify-start content-center pt-4 pl-4 md:w-3/4 ">

            <div class="w-full h-unit-48 mx-auto">
                <div class="animate-pulse flex space-x-4">
                    <div class="flex-1">
                        <div class="h-36 bg-slate-500 rounded"></div>
                    </div>
                </div>
            </div>

            <div class="w-full h-unit-48 mx-auto">
                <div class="animate-pulse flex space-x-4">
                    <div class="flex-1">
                        <div class="h-36 bg-slate-500 rounded"></div>
                    </div>
                </div>
            </div>
            <div class="w-full h-unit-48 mx-auto">
                <div class="animate-pulse flex space-x-4">
                    <div class="flex-1">
                        <div class="h-36 bg-slate-500 rounded"></div>
                    </div>
                </div>
            </div>
            <div class="w-full h-unit-48 mx-auto">
                <div class="animate-pulse flex space-x-4">
                    <div class="flex-1">
                        <div class="h-36 bg-slate-500 rounded"></div>
                    </div>
                </div>
            </div>
            <div class="w-full h-unit-48 mx-auto">
                <div class="animate-pulse flex space-x-4">
                    <div class="flex-1">
                        <div class="h-36 bg-slate-500 rounded"></div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SkeletonSubjects;
