import React, { useEffect } from "react";
import PaymentPlanContainer from "./styled-components/PaymentPlanContainer";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { SiteLoader } from "@components/SiteLoader/SiteLoader";
import getPaymentPlan from "@services/payment/getPaymentPlan";
import Installment from "./components/Installment";
import { Button } from "@nextui-org/react";
import SubTitle from "@components/SubTitle/SubTitle";
import createPaymentOrder from "../../services/payment/createPaymentOrder";
import NoResults from "@components/NoResults";
import noContent from "@assets/no-content.png";
import MpIcon from "@assets/icons/mp.svg";
import TitleHeader from "@components/TitleHeader/TitleHeader";
import HeaderPaymentPlan from "./styled-components/HeaderPaymentPlan";
import PaymentInformation from "./components/PaymentInformation/PaymentInformation";
import SelectCourseModal from "@pages/Global/components/SelectCourseModal";
import { useNavigate } from "react-router-dom";
import ROUTES from "@components/Router/routes";

function PaymentPlan() {
  const navigate = useNavigate();
  const [paymentPlan, setPaymentPlan] = React.useState([]);
  const [duesSelected, setDuesSelected] = React.useState([]);
  const [showModalSelectCourse, setShowModalSelectCourse] = React.useState(false);
  const [planActiveCourses, setPlanActiveCourses] = React.useState([]);

  const paymentPlanNull = paymentPlan.length === 0;

  const { mutate: fetchPaymentPlan, isLoading: isLoadingPayment } = useMutation(
    getPaymentPlan,
    {
      onSuccess: ({ data }) => {

        if (!data || data?.length === 0) {
          setPaymentPlan([]);
        }
        else {
          if (data.length === 1) {
            setPaymentPlan(data[0]);
          }
          else {
            setShowModalSelectCourse(true);
            setPaymentPlan(data);
            setPlanActiveCourses(getActiveCourses(data))
          }
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );

  const { mutate: fetchLinkMercadoPago, isLoading: isLoadingMercadoPago } =
    useMutation(createPaymentOrder, {
      onSuccess: (data) => {
        const url = data?.data?.data?.url;
        if (data.status === 200 && !!url) {
          window.location.href = url;
        } else {
          enqueueSnackbar("Hubo un error con el proveedor de pago", {
            variant: "error",
            persist: false,
          });
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    });

  const mockDues = (dues) => {
    return dues?.map((element) => ({
      id: element,
    }));
  };

  useEffect(() => {
    fetchPaymentPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActiveCourses = (paymentData) => {
    return paymentData.map((payment) => { return payment.course });
  };

  const getTotalPayment = () => {
    let total = 0;
    paymentPlan?.installment?.map((plan) => {
      const exist = duesSelected.find((due) => due === plan?.id);
      if (exist) total += plan?.cost;
    });
    return total;
  };

  const onRenderMercadoPago = () => {
    if (duesSelected?.length > 0) {
      fetchLinkMercadoPago({
        paymentId: paymentPlan?.payment,
        dues: mockDues(duesSelected),
      });
    }
  };

  const onFilterPaymentCourseSelected = ({ courseId }) => {
    const paymentPlanFiltered = paymentPlan.filter(({ course }) => course.id === courseId)
    setPaymentPlan(paymentPlanFiltered[0]);
    setShowModalSelectCourse(false);
  }

  if (isLoadingPayment) return <SiteLoader />;

  return (
    <PaymentPlanContainer>
      <SelectCourseModal
        visible={showModalSelectCourse}
        activeCourses={planActiveCourses}
        fetchCourse={onFilterPaymentCourseSelected}
        onCloseModal={() => navigate(ROUTES.HOME)}
        titleVariant
      />
      <HeaderPaymentPlan>
        <TitleHeader> Plan de pagos </TitleHeader>
        <div>
          <Button
            className="md:mr-8 m-0"
            onClick={onRenderMercadoPago}
            isDisabled={duesSelected?.length === 0}
            radius="none"
            size="md"
            startContent={<img src={MpIcon} alt="mp" width={40} />}
            isLoading={isLoadingMercadoPago}
          >
            Ir a Pagar
          </Button>
        </div>
      </HeaderPaymentPlan>
      {paymentPlanNull && (
        <div className="flex">
          <NoResults
            icon={noContent}
            message="No hay plan de pagos disponible"
            description="El plan de pagos se encuentra disponbile cuando estas inscripto a un curso activo y no es gratis."
          />
        </div>
      )}
      {paymentPlan?.installment?.length > 0 && (
        <div className="p-4 w-full">
          <div className="w-full max-w-3xl flex justify-between items-center">
            <div className="p-3">
              <SubTitle> Seleccionar cuotas a pagar: </SubTitle>
            </div>
          </div>
          <Installment
            paymentPlan={paymentPlan?.installment}
            paymentId={paymentPlan?.payment}
            duesSelected={duesSelected}
            setDuesSelected={setDuesSelected}
          />
          <PaymentInformation />
        </div>
      )}
    </PaymentPlanContainer>
  );
}

export default PaymentPlan;
