import styled from "styled-components";

const ViewEvaluationMainTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  width: 100%;
  padding: 10px 0px;
`;

export default ViewEvaluationMainTitle;