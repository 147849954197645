import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "@nextui-org/react";
import ButtonLoginContainer from "../../styled-components/ButtonLoginContainer";
import ButtonStepRecoveryContainer from "./styled-components/ButtonStepRecoveryContainer";
import { EyeSlashFilledIcon } from "@assets/EyeSlashFilledIcon/EyeSlashFilledIcon";
import { EyeFilledIcon } from "@assets/EyeFilledIcon/EyeFilledIcon";

function ViewRecoveryPassword({
  stateNewPassword,
  setStateNewPassword,
  loading,
}) {
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <>
      <div className="flex flex-col">
        <div className="p-2">
          <Input
            label="Nueva contraseña"
            type={isVisible ? "text" : "password"}
            onChange={({ target }) => {
              setStateNewPassword({
                ...stateNewPassword,
                newPassword: target.value,
              });
            }}
            // css={{ width: "250px" }}
            endContent={
              <button
                className="focus:outline-none"
                type="button"
                onClick={toggleVisibility}
              >
                {isVisible ? (
                  <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                ) : (
                  <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                )}
              </button>
            }
          />
        </div>
      </div>

      <ButtonLoginContainer>
        <ButtonStepRecoveryContainer>
          <Button
            style={{ margin: "0px 5px" }}
            size="xs"
            type="submit"
            isDisabled={loading}
            isLoading={loading}
          >
            Cambiar contraseña
          </Button>
        </ButtonStepRecoveryContainer>
      </ButtonLoginContainer>
    </>
  );
}

ViewRecoveryPassword.propTypes = {
  stateNewPassword: PropTypes.string,
  setStateNewPassword: PropTypes.func,
  loading: PropTypes.bool,
};

export default ViewRecoveryPassword;
