import styled from "styled-components";

const WebProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #202830;
  align-items: center;
  text-align: center;
  padding: 20px 10px 10px 10px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export default WebProfileContainer;
