import React from "react";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { Spinner } from "@nextui-org/react";
import doDeleteVideo from "@services/uploads/doDeleteVideo";
import DeleteLink from "../../styled-components/DeleteLink";

export const DeleteVideo = ({ video, module, onUpdateModule }) => {
  const onDeleteVideo = (video) => {
    deleteVideo({ item: video.id, subjectModule: module.id });
  };

  const { mutate: deleteVideo, isLoading: isDeletingVideo } = useMutation(
    doDeleteVideo,
    {
      onSuccess: ({ data }) => {
        if (data) {
          const moduleUpdated = {
            ...module,
            videos: module.videos.filter((item) => item.id !== data.item),
          };
          onUpdateModule(moduleUpdated);
          enqueueSnackbar("Video borrado", {
            variant: "success",
            persist: false,
          });
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    }
  );

  return (
    <>
      <DeleteLink color="error" onClick={() => onDeleteVideo(video)}>
        {isDeletingVideo ? (
          <Spinner type="points-opacity" color="currentColor" size="sm" />
        ) : (
          "Eliminar"
        )}
      </DeleteLink>
    </>
  );
};
