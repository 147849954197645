import React from "react";

const columns = [
    {name: "ID", uid: "_id", sortable: true},
    {name: "NOMBRE", uid: "studentName", sortable: true},
    // {name: "EMAIL", uid: "email"},
    {name: "EVALUATION", uid: "evaluation.title", sortable: true},
    {name: "CALIFICACIÓN", uid: "score", sortable: true},
    {name: "ESTADO", uid: "state", sortable: true},
    {name: "FECHA", uid: "dateCreated", sortable: true},
    {name: "ACCIONES", uid: "actions"},
];

const statusOptions = [
    {name: "Pending", uid: "Pending"},
    {name: "Completed", uid: "Completed"},
];


export {columns, statusOptions};
