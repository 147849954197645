import styled from "styled-components";
import { devices } from "@utils/devices";

const FormSection = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  @media only screen and ${devices.md} {
    width: 40%;
  }
  padding: 16px;
  overflow: scroll;
`;

export default FormSection;
