import styled from "styled-components";
import {devices} from "../../../../../utils/devices";

const CorrectEvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
  @media only screen and ${devices.md} {
    max-width: 1200px;
  }
`;

export default CorrectEvaluationContainer;
