import axios from "../axios";
import { sendCalificationURL } from "./URLs";

const doSendCalification = async (calificationStructure) => {
  try {
    const response = await axios().put(sendCalificationURL, {
      ...calificationStructure,
    });
    return response.data || {};
  } catch (error) {
    return error.response;
  }
};

export default doSendCalification;
