import React from "react";
import {Input, Modal, Button, ModalContent, ModalHeader, ModalBody, Spinner, ModalFooter} from "@nextui-org/react";
import TitleModal from "./styled-components/TitleModal";
import {useMutation} from "react-query";
import {enqueueSnackbar} from "notistack";
import createModule from "@services/subjects/createModule";
import ContainerModalContent from "./styled-components/ContainerModalContent";
import LoaderModal from "../VideoModal/styled-components/LoaderModal";
import ReactPlayer from "react-player";


const NewModuleModal = ({subject, visible, onCloseModal, updateModules}) => {
    const [titleModule, setTitleModule] = React.useState();

    const {mutate: doCreateModule, isLoading: isCreatingModule} = useMutation(
        createModule,
        {
            onSuccess: ({data}) => {
                if (data) {
                    enqueueSnackbar("Ingresado", {
                        variant: "success",
                        persist: false,
                    });
                    updateModules(data.modules);
                    onCloseModal();
                }
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        }
    );

    return (
        <Modal backdrop="blur" isOpen={visible} onClose={onCloseModal} size="3xl">
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <TitleModal>Agregar nuevo módulo</TitleModal>
                        </ModalHeader>
                        <ModalBody>
                            <ContainerModalContent>
                                <Input
                                    label="Titulo"
                                    type="text"
                                    value={titleModule}
                                    onChange={({target}) => {
                                        setTitleModule(target.value);
                                    }}
                                    css={{width: "300px", paddingTop: "0px"}}
                                    isDisabled={isCreatingModule}
                                />
                                <Button css={{width: "30px", marginTop: "20px"}}
                                        onClick={() => doCreateModule({name: titleModule, subject})}
                                        isLoading={isCreatingModule}>
                                    Agregar
                                </Button>
                            </ContainerModalContent>

                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default NewModuleModal;
