import axios from "../axios";
import {getProgressURL} from "./URLs";

const getProgress = async () => {
    try {
        const response = await axios().get(getProgressURL);
        return response.data || {};
    } catch {
        return {};
    }
};

export default getProgress;