import styled from "styled-components";
import {devices} from "@utils/devices";

const ContentHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only screen and ${devices.md} {
    flex-direction: row;
  }
  padding: 10px;  
`;

export default ContentHeader;
