import styled from "styled-components";

const Text = styled.div`
  font-weight: ${({weight}) => (weight ? weight : "500")};
  font-size: ${({size}) => (size ? size : "1em")};
  color: ${({color}) => (color ? color : "white")};
  padding: ${({padding}) => (padding ? padding : "0px")};
  width: 100%;
`;

export default Text;
