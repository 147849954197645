import React from "react";
import SubTitle from "@components/SubTitle/SubTitle";
import MailIcon from "@assets/mail-forward.svg"

function ViewNotificacionEmail() {

    return (
        <div className="flex flex-col items-center text-center w-full p-2">
            <img src={MailIcon} width={30}/>

            <SubTitle secondaryColor>
                Te enviamos un mail a tu correo
            </SubTitle>
            <SubTitle secondaryColor>
                para finalizar el registro.
            </SubTitle>
        </div>
    );
}

export default ViewNotificacionEmail;
