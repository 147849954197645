import axios from "../axios";
import { updateSubjectsURL } from "./URLs";

const updateSubject = async ({ subjectId, newStatus }) => {
    try {
        const response = await axios().put(updateSubjectsURL, {
            id: subjectId,
            visible: newStatus,
        });
        return response || {};
    } catch (error) {
        return error.response;
    }
};

export default updateSubject;
