import axios from "axios";
import {createFileURL} from "./URLs";
import format from "../format";

const doCreateFile = async ({subjectModule, titleFile, formData}) => {
    try {
        let responseData = {};
        const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;
        const URL = format(BASE_URL + createFileURL, subjectModule.subjectModule, subjectModule.titleFile);
        await axios
            .post(URL, subjectModule.formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                // handle the response
                responseData = response || {};
            })
            .catch((error) => {
                // handle errors
                responseData = error.response;
            });
        return responseData;
    } catch {
        return {};
    }
};

export default doCreateFile;
