import styled from "styled-components";

const LogoIconSite = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 50%;
  cursor: pointer;
`;

export default LogoIconSite;
