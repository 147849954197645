import React from "react";
import CertificateContainer from "./styled-components/CertificateContainer";
import {exportComponentAsPNG} from "react-component-export-image";
import BereaTitleLogo from "./styled-components/BereaTitleLogo";
import LogoIconSite from "./styled-components/LogoIconSite";
import Logo from "@assets/logo-berea.png";
import NameCertificate from "./styled-components/NameCertificate";
import DescriptionCertificate from "./styled-components/DescriptionCertificate";
import SubjectDetail from "./styled-components/SubjectDetail";
import {Button} from "@nextui-org/react";
import formatDateHelper from "@helpers/formatDateHelper";

const divRef = React.createRef();


const ScholarshipGenerator = ({progressData, userLogged}) => {
    const subjectsDone = progressData?.filter(data => data?.status !== 'inProgress' && data !== null)
    const subjectData = subjectsDone?.length > 0;
    return (
        <>
            <CertificateContainer ref={divRef}>
                <div className="flex items-center">
                    <LogoIconSite src={Logo}/>
                    <BereaTitleLogo>BEREA</BereaTitleLogo>
                </div>
                <div className="py-8">
                    <NameCertificate>{userLogged?.name} {userLogged?.lastName}</NameCertificate>
                </div>

                <div className="flex-col">
                    {subjectsDone?.map(data => (
                        !!data && <SubjectDetail>- {data?.name}: {data?.result} puntos.</SubjectDetail>
                    ))}
                </div>

                <div>
                    <DescriptionCertificate>
                        {!subjectData &&
                            <div>
                                No hay materias finalizadas
                            </div>
                        }
                        {subjectData &&
                            <>
                                <div>
                                    Ha realizado y completado las materias detalladas
                                </div>
                                <div>
                                    anteriormente en el Instituto Bíblico BEREA.
                                </div>
                            </>
                        }
                    </DescriptionCertificate>
                    <DescriptionCertificate>
                        <div>
                            Fecha: {formatDateHelper(new Date())}
                        </div>
                    </DescriptionCertificate>
                </div>
            </CertificateContainer>
            <Button
                style={{
                    background: "linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%)",
                    color: "white"
                }}
                onClick={() => exportComponentAsPNG(divRef)}
            >
                Descargar certificado
            </Button>
        </>
    );
};

export default ScholarshipGenerator;
