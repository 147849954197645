import {getResultsByEnrolmentURL} from "./URLs";
import format from "../format";
import axios from "../axios";

const getResultsByEnrolment = async ({enrolment}) => {
    try {
        const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;
        const URL = format(BASE_URL + getResultsByEnrolmentURL, enrolment);
        const response = await axios().get(URL);
        return response.data || {};
    } catch (error) {
        return error.response;
    }
};

export default getResultsByEnrolment;
