import React from "react";
import doDeleteFile from "@services/uploads/doDeleteFile";
import {useMutation} from "react-query";
import {enqueueSnackbar} from "notistack";
import {Spinner} from "@nextui-org/react";
import DeleteLink from "../../styled-components/DeleteLink";
import doDeleteEvaluation from "@services/evaluations/doDeleteEvaluation";

export const DeleteEvaluation = ({evaluation, module, onUpdateModule}) => {
    const onDeleteEvaluation = () => {
        deleteFile({evaluation: evaluation.id});
    }

    const {mutate: deleteFile, isLoading: isDeletingFile} =
        useMutation(doDeleteEvaluation, {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    const moduleUpdated = {
                        ...module,
                        evaluations: module.evaluations.filter((item) => item.id !== data.data.id)
                    }
                    onUpdateModule(moduleUpdated);
                    enqueueSnackbar("Evaluacion eliminada", {
                        variant: "success",
                        persist: false,
                    });
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });

    return (
        <>
            <DeleteLink color="error" onClick={() => onDeleteEvaluation(evaluation)}>
                {isDeletingFile ? (
                    <Spinner type="points-opacity" color="currentColor" size="sm"/>
                ) : (
                    "Eliminar"
                )}
            </DeleteLink>
        </>
    );
};
