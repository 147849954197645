export const VIEWS_LOGIN = {
    LOGIN: "login",
    REGISTER: "register",
    RENEW_PASSWORD: "renew-password",
    NOTIFICATION_EMAIL: "notification-email",
};

export const ROLES = {
    STUDENT: "STUDENT",
    ADMIN: "ADMIN",
    PROFESSOR: "PROFESSOR",
};

export const CONTENT_TYPES = {
    PDF: 'pdf',
    PNG: 'png',
    JPG: 'jpg',
    JPEG: 'jpeg',
}

export const EVALUATION_TYPE = {
    MULTIPLE_CHOIS: "MULTIPLE_CHOIS",
    SINGLE_RESPONSE: "SINGLE_RESPONSE",
    ATTACH: "ATTACH"
}
