import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate, useNavigate } from "react-router-dom";
import ROUTES from "../routes";
import { useUser } from "@context/UserProvider";
const PrivateRoute = ({ children: Children, defaultRoute }) => {
  const { isUserLogged, isUserFetched, isLinkExpired } = useUser();
  const navigateTo = useNavigate();

  useEffect(() => {
    if (isUserLogged && defaultRoute) {
      navigateTo(ROUTES.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRoute]);

  return (
    <>
      {isUserLogged && Children}
      {isUserFetched && !isUserLogged && !isLinkExpired && <Navigate to={ROUTES.LOGIN} />}
    </>
  );
};

PrivateRoute.propTypes = {
  defaultRoute: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
