import React, {useEffect, useState} from "react";
import EvaluationContainer from "./styled-components/EvaluationContainer";
import CardStyledEvaluation from "./styled-components/CardStyledEvaluation";
import {Button, Chip, Input, Textarea} from "@nextui-org/react";
import SingleQuestion from "./components/SingleQuestion";
import NewQuestionsContainer from "./styled-components/NewQuestionsContainer";
import ButtonSaveContainer from "./styled-components/ButtonSaveContainer";
import TitleAddEvaluation from "./styled-components/TitleAddEvaluation";
import MultipleChoice from "./components/MultipleChoice";
import uuidv4 from "@helpers/uuidv4";
import {useMutation} from "react-query";
import {enqueueSnackbar} from "notistack";
import doCreateEvaluation from "@services/evaluations/doCreateEvaluation";
import queryString from "query-string";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import HeaderEvaluation from "./styled-components/HeaderEvaluation";
import TitleContent from "./styled-components/TitleContent";
import ROUTES from "../../components/Router/routes";
import {EVALUATION_TYPE} from "@utils/constants";

const Evaluation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const evaluationData = queryString.parse(location.search)
    const {subject, module, e} = evaluationData;

    const [evaluationTitle, setEvaluationTitle] = useState();
    const [evaluationDescription, setEvaluationDescription] = useState();
    const [evaluationScore, setEvaluationScore] = useState();
    const [questions, setQuestions] = useState([])
    const [evaluationStructure, setEvaluationStructure] = useState({
        subject: subject,
        subjectModule: module,
        title: evaluationTitle,
        description: evaluationDescription,
        score: evaluationScore,
        questions: questions
    });

    const fetchQuestion = (type) => {
        if (type === EVALUATION_TYPE.SINGLE_RESPONSE) {
            setQuestions([...questions, {
                description: {title: ""},
                score: 0,
                response: "",
                type: EVALUATION_TYPE.SINGLE_RESPONSE,
                mock_id: uuidv4()
            }]);
        }
        if (type === EVALUATION_TYPE.MULTIPLE_CHOIS) {
            setQuestions([...questions, {
                description: {title: ""},
                score: 0,
                type: EVALUATION_TYPE.MULTIPLE_CHOIS,
                response: [],
                mock_id: uuidv4()
            }]);
        }
    }

    useEffect(() => {
        console.log('questions', questions)
        setEvaluationStructure({
            ...evaluationStructure,
            questions: questions,
            title: evaluationTitle?.name,
            description: evaluationDescription?.name,
            score: evaluationScore || 0
        })

    }, [questions, evaluationTitle, evaluationDescription, evaluationScore]);

    useEffect(() => {
        console.log('evaluationStructure', evaluationStructure)
    }, [evaluationStructure]);


    const deleteQuestion = (id) => {
        const result = questions.filter(question => question.id !== id);
        setQuestions(result);
    }

    const renderQuestion = (question, index) => {
        if (question?.type === EVALUATION_TYPE.SINGLE_RESPONSE) {
            return <SingleQuestion
                id={question.mock_id}
                question={question}
                questions={questions}
                setQuestions={setQuestions}
                onDeleteQuestion={deleteQuestion}
                count={index + 1}
            >
            </SingleQuestion>
        }
        if (question?.type === EVALUATION_TYPE.MULTIPLE_CHOIS) {
            return <MultipleChoice
                id={question.mock_id}
                question={question}
                onDeleteQuestion={deleteQuestion}
                questions={questions}
                setQuestions={setQuestions}
                count={index + 1}
            ></MultipleChoice>
        }
        if (question?.type === EVALUATION_TYPE.ATTACH) {
            <div></div>
        }
    }

    const {mutate: createEvaluation, isLoading: isLoadingCreateEvaluation} =
        useMutation(doCreateEvaluation, {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    enqueueSnackbar("Evaluacion generada", {
                        variant: "success",
                        persist: false,
                    });
                    navigate({
                        pathname: ROUTES.CONTENT,
                        search: `?e=${e}`,
                    });
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
            },
            onError: (error) => {
                console.log('error')
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });

    const onCreateEvaluation = () => {
        createEvaluation({dataEvaluation: evaluationStructure})
    }


    return (
        <EvaluationContainer>
            <HeaderEvaluation>
                <TitleContent>
                    <TitleAddEvaluation>Titulo evaluación: </TitleAddEvaluation>
                    <Input
                        type="text"
                        defaultValue={evaluationTitle}
                        onChange={({target}) => {
                            setEvaluationTitle({
                                name: target.value,
                            });
                        }}
                        // css={{width: "400px"}}
                    />
                    <TitleAddEvaluation>Descripción: </TitleAddEvaluation>
                    <Textarea
                        placeholder="Ingrese una breve descripción..."
                        onChange={({target}) => {
                            setEvaluationDescription({
                                name: target.value,
                            });
                        }}
                    />
                    <TitleAddEvaluation>Total:</TitleAddEvaluation>
                    <Input
                        label="Puntaje"
                        type="number"
                        onChange={({target}) => {
                            setEvaluationScore(Number(target.value));
                        }}
                        css={{width: "400px", paddingTop: "0px"}}
                    />
                </TitleContent>
                <ButtonSaveContainer>
                    <Button style={{
                        background: "linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%)",
                        color: "white"
                    }}
                            onClick={onCreateEvaluation}>
                        Guardar
                    </Button>
                </ButtonSaveContainer>
            </HeaderEvaluation>
            <CardStyledEvaluation>
                <div>
                    {
                        questions?.map((question, index) => (
                            renderQuestion(question, index)
                        ))
                    }
                </div>
                <NewQuestionsContainer>
                    <Button style={{minHeight: "40px", width: "50%", margin: "0px 20px"}}
                            onClick={() => fetchQuestion(EVALUATION_TYPE.SINGLE_RESPONSE)}
                            bordered
                            color="warning"
                            auto>
                        Pregunta simple
                    </Button>
                    <Button style={{minHeight: "40px", width: "50%", margin: "0px 20px"}}
                            onClick={() => fetchQuestion(EVALUATION_TYPE.MULTIPLE_CHOIS)}
                            bordered
                            color="warning"
                            auto>
                        Multiple Opción
                    </Button>
                    <div className="flex flex-col items-center w-1/2">
                        <Button style={{minHeight: "40px", width: "50%", margin: "5px 20px"}}
                                onClick={() => fetchQuestion(EVALUATION_TYPE.ATTACH)}
                                bordered
                                color="warning"
                                isDisabled
                                auto>
                            Adjunto
                        </Button>
                        <Chip
                            color="warning"
                            size="sm"
                            variant="flat"
                        >
                            Próximamente
                        </Chip>
                    </div>
                </NewQuestionsContainer>
            </CardStyledEvaluation>
        </EvaluationContainer>
    );
};

export default Evaluation;
