import axios from "../axios";
import { getEnrolmentsContentURL } from "./URLs";

const format = require("../format");
const getEnrolmentsContent = async (id) => {
  try {
    const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;
    const URL = format(BASE_URL + getEnrolmentsContentURL, id);
    const response = await axios().get(URL);
    return response || {};
  } catch {
    return {};
  }
};

export default getEnrolmentsContent;
