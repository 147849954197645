import React from "react";
import {Modal, Button, ModalContent, ModalHeader, ModalBody, ModalFooter} from "@nextui-org/react";
import SubTitle from "@components/SubTitle/SubTitle";

const DeleteModalUser = ({visible, closeHandler, onDeleteConfirm, loading}) => {
    return (
        <Modal backdrop="blur" isOpen={visible} onClose={closeHandler}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <SubTitle secondaryColor>¿Estás seguro que querés eliminar?</SubTitle>
                        </ModalHeader>
                        <ModalBody>
                        </ModalBody>
                        <ModalFooter>
                            <div className="flex">
                                <Button auto flat onClick={closeHandler} style={{margin: "10px"}}>
                                    Volver
                                </Button>
                                <Button isDisabled={loading} color="danger" auto onClick={onDeleteConfirm}
                                        isLoading={loading} style={{margin: "10px"}}
                                >
                                    Eliminar
                                </Button>
                            </div>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

export default DeleteModalUser;
