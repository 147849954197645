import styled from "styled-components";

const HeaderPaymentPlan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 2px;
  border-color: #394156;
  padding: 20px;
  background: #1d2e30;
`;

export default HeaderPaymentPlan;
