import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "@nextui-org/react";
import ButtonLoginContainer from "../../styled-components/ButtonLoginContainer";
import ButtonStepContainer from "./styled-components/ButtonStepContainer";
import { EyeSlashFilledIcon } from "@assets/EyeSlashFilledIcon/EyeSlashFilledIcon";
import { EyeFilledIcon } from "@assets/EyeFilledIcon/EyeFilledIcon";

function ViewRegister({ stateRegisterUser, setStateRegisterUser, loading }) {
  const [wizzardStep, setWizzardStep] = useState(1);
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <>
      {wizzardStep === 1 && (
        <div className="flex flex-col">
          <div className="p-2">
            <Input
              label="Nombre"
              type="text"
              defaultValue={stateRegisterUser?.name}
              onChange={({ target }) => {
                setStateRegisterUser({
                  ...stateRegisterUser,
                  name: target.value,
                });
              }}
            />
          </div>

          <div className="p-2">
            <Input
              label="Apellido"
              type="text"
              defaultValue={stateRegisterUser?.lastName}
              onChange={({ target }) => {
                setStateRegisterUser({
                  ...stateRegisterUser,
                  lastName: target.value,
                });
              }}
              // style={{ width: "300px" }}
            />
          </div>
          <div className="p-2">
            <Input
              label="Teléfono"
              type="text"
              defaultValue={stateRegisterUser?.phone}
              onChange={({ target }) => {
                setStateRegisterUser({
                  ...stateRegisterUser,
                  phone: target.value,
                });
              }}
              // style={{ width: "300px" }}
            />
          </div>
          <div className="p-2">
            <Input
              label="Email"
              type="email"
              defaultValue={stateRegisterUser?.emailRegister}
              onChange={({ target }) => {
                setStateRegisterUser({
                  ...stateRegisterUser,
                  emailRegister: target.value,
                });
              }}
              // style={{ width: "300px" }}
            />
          </div>

          <div className="p-2">
            <Input
              label="Contraseña"
              type={isVisible ? "text" : "password"}
              onChange={({ target }) => {
                setStateRegisterUser({
                  ...stateRegisterUser,
                  passwordRegister: target.value,
                });
              }}
              // css={{ width: "250px" }}
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  ) : (
                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  )}
                </button>
              }
            />
          </div>
        </div>
      )}
      {wizzardStep === 2 && (
        <div className="flex flex-col">
          <div className="p-2">
            <Input
              label="Iglesia"
              type="text"
              defaultValue={stateRegisterUser?.church}
              onChange={({ target }) => {
                setStateRegisterUser({
                  ...stateRegisterUser,
                  church: target.value,
                });
              }}
            />
          </div>
          <div className="p-2">
            <Input
              label="Dirección"
              type="text"
              defaultValue={stateRegisterUser?.address}
              onChange={({ target }) => {
                setStateRegisterUser({
                  ...stateRegisterUser,
                  address: target.value,
                });
              }}
            />
          </div>
          <div className="p-2">
            <Input
              label="Departamento"
              type="text"
              defaultValue={stateRegisterUser?.department}
              onChange={({ target }) => {
                setStateRegisterUser({
                  ...stateRegisterUser,
                  department: target.value,
                });
              }}
            />
          </div>
          <div className="p-2">
            <Input
              label="Barrio"
              type="text"
              defaultValue={stateRegisterUser?.city}
              onChange={({ target }) => {
                setStateRegisterUser({
                  ...stateRegisterUser,
                  city: target.value,
                });
              }}
            />
          </div>
        </div>
      )}

      <ButtonLoginContainer>
        {wizzardStep === 1 && (
          <>
            <Button
              style={{ margin: "10px" }}
              type="button"
              isDisabled={loading}
              onClick={() => setWizzardStep(2)}
              size="xs"
            >
              Siguiente
            </Button>
          </>
        )}
        {wizzardStep === 2 && (
          <ButtonStepContainer>
            <Button
              size="xs"
              style={{ margin: "0px 5px" }}
              type="button"
              isDisabled={loading}
              onClick={() => setWizzardStep(1)}
            >
              Atras
            </Button>
            <Button
              style={{ margin: "0px 5px" }}
              size="xs"
              type="submit"
              isDisabled={loading}
              isLoading={loading}
            >
              Registrarme
            </Button>
          </ButtonStepContainer>
        )}
      </ButtonLoginContainer>
    </>
  );
}

ViewRegister.propTypes = {
  stateRegisterUser: PropTypes.object,
  setStateRegisterUser: PropTypes.func,
  loading: PropTypes.bool,
};

export default ViewRegister;
