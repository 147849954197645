import React, {useEffect, useState} from "react";
import UsersContainer from "./styled-components/UsersContainer";
import getUsers from "@services/users/getUsers";
import deleteUser from "@services/users/deleteUser";
import updateUser from "@services/users/updateUser";
import doRegister from "@services/auth/doRegister";
import {useMutation} from "react-query";
import {enqueueSnackbar} from "notistack";
import CreateUser from "./components/CreateUser";
import TableAbmUsers from "./components/TableAbmUsers";
import Title from "@components/Title/Title";
import {SiteLoader} from "../../components/SiteLoader/SiteLoader";
import ViewUserProfile from "./components/ViewUserProfile";
import DeleteModalUser from "./components/DeleteModal";

const MODE_VIEW_USERS = {
    TABLE: "TABLE",
    VIEW_USER: "VIEW_USER",
    EDIT_USER: "EDIT_USER",
    CREATE_USER: "CREATE_USER",
};

function Users() {
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();
    const [userDelete, setUserDelete] = useState();
    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [modeViewUsers, setModeViewUsers] = useState(MODE_VIEW_USERS.TABLE);
    const {mutate: fetchUsers, isFetching: isLoadingUsers} = useMutation(
        getUsers,
        {
            onSuccess: ({data}) => {
                if (data) {
                    console.log("data", data);
                    setUsers(data);
                }
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        }
    );

    const {mutate: doDeleteUser, isLoading: isLoadingDelete} = useMutation(
        deleteUser,
        {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    enqueueSnackbar("Usuario eliminado", {
                        variant: "success",
                        persist: false,
                    });
                    setShowModalDelete(false);
                    getUsers();
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        }
    );
    const {mutate: doUpdateUser, isLoading: isLoadingUpdate} = useMutation(
        updateUser,
        {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    enqueueSnackbar("Usuario actualizado", {
                        variant: "success",
                        persist: false,
                    });
                    setShowModalDelete(false);
                    getUsers();
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        }
    );
    const {mutate: doCreateUser, isLoading: isLoadingCreateUser} = useMutation(
        doRegister,
        {
            onSuccess: ({data}) => {
                if (data)
                    enqueueSnackbar("Usuario creado, verificar mail", {
                        variant: "success",
                        persist: false,
                    });
                getUsers();
            },
            onError: (error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        }
    );

    const onViewUser = (user) => {
        setUser(user);
        setModeViewUsers(MODE_VIEW_USERS.VIEW_USER);
    };

    const onEditUser = (user) => {
        setUser(user);
        setModeViewUsers(MODE_VIEW_USERS.EDIT_USER);
    };

    const onDefaultView = () => {
        setModeViewUsers(MODE_VIEW_USERS.TABLE);
    };
    const onSetViewCreateUser = () => {
        setModeViewUsers(MODE_VIEW_USERS.CREATE_USER);
    };

    const onSaveUser = (user) => {
        doUpdateUser(user);
    };

    const onCreateUser = (user) => {
        doCreateUser(user);
    };

    const onDeleteUser = (userId) => {
        setUserDelete(userId);
        setShowModalDelete(true);
    };
    const onCloseModal = () => {
        setShowModalDelete(false);
    };
    const onDeleteConfirm = () => {
        doDeleteUser(userDelete);
        setUserDelete();
    };

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoadingUsers) return <SiteLoader/>;
    return (
        <UsersContainer>
            {showModalDelete && (
                <DeleteModalUser
                    visible={showModalDelete}
                    closeHandler={onCloseModal}
                    onDeleteConfirm={onDeleteConfirm}
                    loading={isLoadingDelete}
                />
            )}
            {modeViewUsers === MODE_VIEW_USERS.TABLE && (
                <div className="p-8 w-full">
                    <Title> Usuarios del sistema </Title>
                    <div className="p-4 my-3 bg-white/95">
                        <TableAbmUsers
                            users={users}
                            onViewUser={onViewUser}
                            onEditUser={onEditUser}
                            onDeleteUser={onDeleteUser}
                            onCreateUser={onSetViewCreateUser}
                        />
                    </div>
                </div>
            )}
            {modeViewUsers === MODE_VIEW_USERS.VIEW_USER && (
                <ViewUserProfile
                    user={user}
                    onBackButton={onDefaultView}
                    onSaveUser={onSaveUser}
                    modeEdit={false}
                />
            )}
            {modeViewUsers === MODE_VIEW_USERS.EDIT_USER && (
                <ViewUserProfile
                    user={user}
                    onBackButton={onDefaultView}
                    onSaveUser={onSaveUser}
                    modeEdit
                />
            )}
            {modeViewUsers === MODE_VIEW_USERS.CREATE_USER && (
                <CreateUser
                    onSaveUser={onCreateUser}
                    loading={isLoadingCreateUser}
                    onBackButton={onDefaultView}
                />
            )}
        </UsersContainer>
    );
}

export default Users;
