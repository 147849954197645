const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  EXPIRED: "/link/expired",
  RECOVERY: "/reset-password",
  CONFIRM_PROFILE: "/confirm/profile",
  COURSES: "/courses",
  SCHOLARSHIP: "/scholarship",
  PROFILE: "/profile",
  USERS: "/users",
  CONTENT: "/content",
  ADD_EVALUATION: "/evaluation/add",
  TEST: "/test",
  GESTION_PROFESSOR: "/gestion/professor",
  GESTION_COUPONS: "/gestion/coupons",
  CALIFICATION: "/calification",
  VIEW_EVALUATION: "/evaluation/view",
  PAYMENT_PLAN: "/payment",
  CHECKOUT: "/checkout",
};

export default ROUTES;
