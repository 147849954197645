import React from "react";
import {Input, Modal, Button, ModalContent, ModalHeader, ModalBody, Spinner, ModalFooter} from "@nextui-org/react";
import TitleModal from "./styled-components/TitleModal";


const NewVideoModal = ({visible, onCloseModal, sendVideo, isLoading}) => {
    const [titleVideo, setTitleVideo] = React.useState();
    const [urlVideo, setUrlVideo] = React.useState();

    return (
        <Modal backdrop="blur" isOpen={visible} onClose={onCloseModal} size="3xl">
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <TitleModal>Agregar clase</TitleModal>
                        </ModalHeader>
                        <ModalBody>
                            <Input
                                label="Titulo"
                                type="text"
                                value={titleVideo}
                                onChange={({target}) => {
                                    setTitleVideo(target.value);
                                }}
                                isDisabled={isLoading}
                            />
                            <Input
                                label="Url"
                                type="text"
                                value={urlVideo}
                                onChange={({target}) => {
                                    setUrlVideo(target.value);
                                }}
                                isDisabled={isLoading}
                            />
                            <Button css={{width: "30px", marginTop: "20px"}}
                                    onClick={() => sendVideo({name: titleVideo, url: urlVideo})} isLoading={isLoading}
                                    color="secondary">
                                Agregar
                            </Button>

                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default NewVideoModal;
