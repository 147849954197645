import styled from "styled-components";

const CloseIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    border-radius: 18px;
    background: rgba(255, 255, 255, 0.22);
    border-radius: 24.1454px;
  }
`;

export default CloseIcon;
