import axios from "../axios";
import { getUserURL } from "./URLs";

const getUser = async () => {
  try {
    const response = await axios().get(getUserURL);
    return response.data || {};
  } catch {
    return {};
  }
};

export default getUser;
