import React, {useState} from "react";
import {Button, Input} from "@nextui-org/react";
import WebProfileContainer from "./styled-components/WebProfileContainer";
import Title from "@components/Title/Title";
import {CardUserProfile} from "@pages/Profile/styled-components/CardUserProfile";
import ButtonsViewContainer from "@pages/Profile/styled-components/ButtonViewContainer";

function ViewUserProfile({user, onBackButton, modeEdit = false, onSaveUser}) {
    const [updatedUser, setUpdatedUser] = useState(user);

    const handleOnClick = () => {
        if (onSaveUser) onSaveUser(updatedUser)
    }

    return (
        <WebProfileContainer>
            <div className="flex w-full">
                <div className="w-1/3 pt-32">
                    <CardUserProfile
                        name={user?.name}
                        lastName={user?.lastName}
                        email={user?.email}
                        role={user?.role}
                        id={user?.id}
                    ></CardUserProfile>
                </div>


                <div className="w-full p-32 h-screen" style={{backgroundColor: "#252d33"}}>
                    <Title style={{width: "50%", minWidth: "100px"}}> Datos del perfil: </Title>

                    <div className="pt-10 pb-2 w-1/2">
                        <Input
                            label="Nombre"
                            type="text"
                            value={updatedUser?.name}
                            onChange={({target}) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    name: target.value,
                                });
                            }}
                            isDisabled={!modeEdit}
                        />
                    </div>
                    <div className="py-2 w-1/2">
                        <Input
                            label="Apellido"
                            type="text"
                            value={updatedUser?.lastName}
                            onChange={({target}) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    lastName: target.value,
                                });
                            }}
                            isDisabled={!modeEdit}
                        />
                    </div>
                    <div className="py-2 w-1/2">
                        <Input
                            label="Dirección"
                            type="text"
                            value={updatedUser?.address}
                            onChange={({target}) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    address: target.value,
                                });
                            }}
                            isDisabled={!modeEdit}
                        />
                    </div>
                    <div className="py-2 w-1/2">
                        <Input
                            label="Departamento"
                            type="text"
                            value={updatedUser?.department}
                            onChange={({target}) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    department: target.value,
                                });
                            }}
                            isDisabled={!modeEdit}
                        />
                    </div>
                    <div className="py-2 w-1/2">
                        <Input
                            label="Ciudad"
                            type="text"
                            value={updatedUser?.locality}
                            onChange={({target}) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    locality: target.value,
                                });
                            }}
                            isDisabled={!modeEdit}
                        />
                    </div>
                    <div className="py-2 w-1/2">
                        <Input
                            label="Teléfono"
                            type="text"
                            value={updatedUser?.phone}
                            onChange={({target}) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    phone: target.value,
                                });
                            }}
                            isDisabled={!modeEdit}
                        />
                    </div>
                    <div className="py-2 w-1/2">
                        <Input
                            label="Iglesia"
                            type="text"
                            value={updatedUser?.church}
                            onChange={({target}) => {
                                setUpdatedUser({
                                    ...updatedUser,
                                    church: target.value,
                                });
                            }}
                            isDisabled={!modeEdit}
                        />
                    </div>

                    <ButtonsViewContainer>
                        <Button
                            onClick={onBackButton}
                            style={{
                                background: "linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%)",
                                color: "white",
                                width: "200px",
                                margin: "10px"
                            }}
                        >
                            Volver
                        </Button>
                        {modeEdit &&
                            <Button
                                onClick={() => handleOnClick()}
                                style={{
                                    background: "linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%)",
                                    color: "white",
                                    width: "200px",
                                    margin: "10px"
                                }}
                            >
                                Guardar
                            </Button>}

                    </ButtonsViewContainer>
                </div>
            </div>

        </WebProfileContainer>
    );
}

export default ViewUserProfile;
